import { parsePhoneNumber } from "libphonenumber-js";
import { DIALPAD_ALPHABETS } from "../constants/dialer";

/**
 *
 * safeParseNumber(num="", country=")
 *
 * Safely parses a number using libphonenumber-js
 *
 * @param number
 * @param country
 * @return {*} PhoneNumber:Object
 */
export const safeParseNumber = (num = "", country = "US") => {
  try {
    return parsePhoneNumber(num, country);
  } catch (e) {
    return null;
  }
};

export const getDialPadAlphabets = (num) => {
  const alphabet = DIALPAD_ALPHABETS[num];

  return alphabet || " ";
};
