export function isValidPassword(password) {
  return /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{12,}$/.test(password);
}

export function getSipSeverUrls(sipDomain) {
  if (!sipDomain) {
    return { server1: "", server2: "" };
  }
  const server1 = sipDomain.replace(".twilio.com", ".ashburn.twilio.com");
  const server2 = sipDomain.replace(".twilio.com", ".umatilla.twilio.com");
  return { server1, server2 };
}
