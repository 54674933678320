import Engage from "../services/engage";
import { Model } from "./base";

class SipDomain extends Model {
  static async getDomains() {
    try {
      return await Engage.getDomains();
    } catch (e) {
      console.error(e);
      return { response: [] };
    }
  }

  static async createDomain() {
    try {
      return await Engage.createDomain();
    } catch {
      return { response: {} };
    }
  }
}

export default SipDomain;
