import Row from "../../layouts/Row";
import CopyButton from "../Buttons/CopyButton/index.web";
import { ICONS_LIBRARY } from "../../constants/icons";
import commonStyles from "../../styles/common";

export function Copyable({
  text = "",
  icon = ICONS_LIBRARY.COPY,
  children,
  style,
  ...props
}) {
  return (
    <Row leftCenter style={{ ...commonStyles.gap, ...style }}>
      {children}
      <CopyButton text={text} icon={icon} {...props} />
    </Row>
  );
}
