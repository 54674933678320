import { StyleSheet, View } from "react-native";
import { Strings } from "../../constants/strings";
import Modal from "../Modal";
import { Field } from "../../components/Customer/CustomerForm";
import StyledInput from "../../components/StyledInput";
import SolidButton from "../../components/Buttons/SolidButton";
import palette from "../../styles/palette";
import SipDomainsContext from "../../contexts/SipDomainsContext";
import { useCallback, useContext, useState } from "react";
import NumbersContext from "../../contexts/NumbersContext";
import PhoneNumbers from "../../models/PhoneNumbers";
import CredentialList from "../../models/CredentialList";
import StyledSelect from "../../components/StyledSelect";
import Credential from "../../models/Credential";
import { CREDENTIAL_KEYS } from "../../constants/sipDomain";
import { Text } from "react-native";
import { useHistory } from "react-router-dom";
import IconButton from "../../components/Buttons/IconButton";
import { ICONS_LIBRARY } from "../../constants/icons";
import useToggle from "../../hooks/useToggle";
import Row from "../../layouts/Row";

function AddSipDomainCredentialModal() {
  const {
    credentials,
    createCredential,
    savingCredential,
    primaryDomain,
    primaryCredentialList,
  } = useContext(SipDomainsContext);
  const { numbers } = useContext(NumbersContext);
  const history = useHistory();
  const { state } = history.location;
  const phoneNumbers = new PhoneNumbers(numbers);
  const credentialsList = new CredentialList(credentials);
  const credentialMap = credentialsList.getCredentialsPhoneLineMap();
  const phoneLinesWithoutCredentials =
    phoneNumbers.getPhoneLinesWithoutCredentials(credentialMap);

  const [credential, setCredential] = useState(
    new Credential({
      username: state?.phoneLine || "",
    }),
  );
  const credentialModel = new Credential(credential);

  const onValueChange = (key) => (value) => {
    setCredential((credential) => ({ ...credential, [key]: value, error: "" }));
  };

  const onSave = useCallback(async () => {
    if (!credentialModel.isValid()) {
      if (!credentialModel.username) {
        setCredential((credential) => ({
          ...credential,
          error: Strings.SIP_DOMAIN_CREDENTIAL_USERNAME_REQUIRED,
        }));
        return;
      } else {
        setCredential((credential) => ({
          ...credential,
          isPasswordError: true,
        }));
        return;
      }
    }
    const res = await createCredential(credentialModel);
    if (res && !res.error) {
      const { pathname, state } = history.location;
      history.replace(pathname, state);
    } else {
      setCredential((credential) => ({
        ...credential,
        error: "Something went wrong, please try again.",
      }));
    }
  }, [credentialModel, createCredential, primaryDomain, primaryCredentialList]);

  return (
    <Modal title={Strings.SIP_DOMAIN_ADD_NEW_CREDENTIAL_HEADING}>
      <View>
        <PasswordRequired isPasswordError={credentialModel.isPasswordError} />
        <Error error={credentialModel.error} />
        <Username
          phoneLines={phoneLinesWithoutCredentials}
          value={credentialModel.username}
          onChange={onValueChange(CREDENTIAL_KEYS.USERNAME)}
        />
        <Password
          value={credentialModel.password}
          onChange={onValueChange(CREDENTIAL_KEYS.PASSWORD)}
        />
        <Submit onPress={onSave} loading={savingCredential} />
      </View>
    </Modal>
  );
}

const Username = ({ value, onChange, phoneLines }) => {
  return (
    <Field label={Strings.SIP_DOMAIN_CREDENTIAL_USERNAME_LABEL}>
      <StyledSelect
        options={phoneLines}
        selectedValue={value}
        onValueChange={onChange}
        border
        placeholder={Strings.SIP_DOMAIN_CREDENTIAL_USERNAME_PLACEHOLDER}
      />
    </Field>
  );
};

const PasswordRequired = ({ isPasswordError }) => {
  return (
    <Field>
      <View
        style={[
          styles.password_required_container,
          isPasswordError && styles.error_container,
        ]}
      >
        <Text
          style={[
            styles.password_required_text,
            isPasswordError && styles.error_text,
          ]}
        >
          {Strings.SIP_DOMAIN_CREDENTIAL_PASSWORD_REQUIRED}
        </Text>
      </View>
    </Field>
  );
};

const Password = ({ value, onChange }) => {
  const [showPassword, setShowPassword] = useToggle(false);
  return (
    <Field label={Strings.SIP_DOMAIN_CREDENTIAL_PASSWORD_LABEL}>
      <Row flex>
        <StyledInput
          value={value}
          onChangeText={onChange}
          secureTextEntry={!showPassword}
          border
          placeholder={Strings.SIP_DOMAIN_CREDENTIAL_PASSWORD_PLACEHOLDER}
        />
        <IconButton
          faPro
          faProIcon={showPassword ? ICONS_LIBRARY.EYE_SLASH : ICONS_LIBRARY.EYE}
          onPress={setShowPassword}
          style={styles.password_show_icon}
          iconColor={palette.grey}
        />
      </Row>
    </Field>
  );
};

const Error = ({ error }) => {
  if (!error) return null;
  return (
    <Field>
      <View style={styles.error_container}>
        <Text style={styles.error_text}>{error}</Text>
      </View>
    </Field>
  );
};

const Submit = ({ onPress, loading }) => {
  return (
    <Field>
      <SolidButton
        color={palette.success}
        label={Strings.SIP_DOMAIN_ADD_CREDENTIAL_LABEL}
        onPress={onPress}
        loading={loading}
        style={{
          minWidth: 120,
        }}
      />
    </Field>
  );
};

const styles = StyleSheet.create({
  error_container: {
    backgroundColor: palette.light_red,
    padding: 8,
    borderRadius: 4,
    marginBottom: 8,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: palette.red,
  },
  error_text: {
    fontSize: 14,
    color: palette.red,
  },
  password_required_text: {
    fontSize: 14,
    color: palette.label_grey,
  },
  password_required_container: {
    backgroundColor: palette.bg_light_grey,
    padding: 8,
    borderRadius: 4,
    marginBottom: 6,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: palette.label_grey,
  },
  password_show_icon: { position: "absolute", right: 0 },
});

export default AddSipDomainCredentialModal;
