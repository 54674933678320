import React, {
  createContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
  useRef,
} from "react";
import Engage from "~/services/engage";
import { sortByCreatedAt } from "~/helpers/sort";
import useTwilio from "~/hooks/useTwilio";
import ThreadsContext from "./ThreadsContext";
import UserContext from "./UserContext";
import NumbersContext from "./NumbersContext";
import dayjs from "dayjs";
import { parsePhoneNumber } from "libphonenumber-js";
import cloneDeep from "lodash.clonedeep";
import useNumbersSubscription from "../hooks/useNumbersSubscription";
import {
  API_MATCHING_KEYS,
  AUTHOR_KEYS,
  BOOLEAN_KEYS,
  CALL_UPDATE_CHANNEL,
  DEFAULT_CALL_UPDATE_PAYLOAD,
} from "../constants/calls";
import { getUrlParams } from "../helpers/text";

export const CallsContext = createContext({});

export const CallsProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [calls, setCalls] = useState([]);
  const { activeCall, activeCallData, duration } = useTwilio();
  const [selected, setSelected] = useState(null);
  const [participant, setParticipant] = useState({});
  const [customerFormVisible, setCustomerFormVisible] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const { filter, selectedLines, isLineFilterCacheReady, lineSelectVisible } =
    useContext(ThreadsContext);
  const {
    numbers,
    managedNumbers,
    loading: loadingNumbers,
  } = useContext(NumbersContext);
  const { user } = useContext(UserContext);
  const callsPageRef = useRef(false);
  const [activeCallId, setActiveCallId] = useState();
  const [activeCallNote, setActiveCallNote] = useState("");
  const [reportedCalls, setReportedCalls] = useState([]);

  const getCalls = useCallback(
    async (options = {}) => {
      if (loading || loadingNumbers) return;

      const { read, filter: filterFromOptions } = options;

      setLoading(true);
      let currentFilter = filter;
      if (filterFromOptions) currentFilter = filterFromOptions;

      let params = {
        filter: "managed",
      };

      if (currentFilter === "all") {
        params.managed_lines = managedNumbers.map((num) => num?.id);
      } else if (currentFilter === "assigned") {
        params.managed_lines = [user?.assigned_number?.id];
      } else {
        params.managed_lines = selectedLines;
      }

      if (!params?.managed_lines || !params?.managed_lines?.length) {
        setCalls([]);
      } else {
        const res = await Engage.getCalls({ page, read, ...params });
        if (!res.error) {
          const data = sortByCreatedAt(res.response);
          setCalls(page === 1 ? data : [...calls, ...data]);
        }
      }
      setLoading(false);
    },
    [
      setLoading,
      setCalls,
      page,
      filter,
      selectedLines,
      user?.assigned_number?.id,
      managedNumbers?.map((num) => num.id),
      loadingNumbers,
    ],
  );

  const prevPage = useCallback(() => {
    if (page === 1 || loading) return;
    setPage((page) => page - 1);
  }, [loading]);

  const nextPage = useCallback(() => {
    if (loading || calls?.length % 30 !== 0) return;
    setPage((page) => page + 1);
  }, [loading]);

  const refreshCallLogs = async () => {
    if (loading) return;
    setLoading(true);
    setSelected(null);
    setInitialLoading(true);
    const res = await Engage.getCalls({ page: 1 });
    if (!res.error) {
      const data = sortByCreatedAt(res.response);
      setCalls(data);
    }
    setInitialLoading(false);
    setLoading(false);
    setPage(1);
  };

  const fetchCalls = async (read = true) => {
    if (page === 1) setInitialLoading(true);
    await getCalls({ read });
    if (page === 1) setInitialLoading(false);
  };

  const assignCallId = useCallback(
    (call) => {
      try {
        let activeCallTo;
        let activeCallFrom;
        let activeCallDirection;
        if (activeCallData?.data?.direction === "OUTGOING") {
          activeCallTo = activeCall?.customParameters?.get("to");
          activeCallFrom = activeCall?.customParameters?.get("from");
          activeCallDirection = "outbound-dial";
          activeCallTo = parsePhoneNumber(
            activeCallTo,
            user?.account?.country || "US",
          ).formatInternational();
          activeCallFrom = parsePhoneNumber(
            activeCallFrom,
            user?.account?.country || "US",
          ).formatInternational();
        } else {
          activeCallDirection = "inbound";
          if (activeCall?.customParameters?.has("numberId")) {
            activeCallTo = activeCall?.customParameters?.get("numberId");
          }
          activeCallFrom = activeCall?.parameters?.From;
          activeCallFrom = parsePhoneNumber(
            activeCallFrom,
            user?.account?.country || "US",
          ).formatInternational();
        }
        let parsedTo = parsePhoneNumber(
          call?.to,
          user?.account?.country || "US",
        ).formatInternational();
        let parsedFrom = parsePhoneNumber(
          call?.from,
          user?.account?.country || "US",
        ).formatInternational();

        if (
          activeCallDirection === call.direction &&
          ((activeCallTo === parsedTo && activeCallFrom === parsedFrom) ||
            (activeCallFrom === parsedFrom &&
              call?.twilio_number?.id === activeCallTo))
        ) {
          const diff = dayjs(call?.created_at).diff(
            dayjs().subtract(duration, "second"),
          );
          if (
            diff < 2550 &&
            diff > -2550 &&
            activeCallDirection === "outbound-dial"
          )
            setActiveCallId(call?.id);

          if (activeCallDirection === "inbound") {
            setActiveCallId(call?.id);
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    [activeCall, activeCallData, duration, user?.account?.country],
  );

  const shouldAddCallToList = useCallback(
    (twilio_number_id) => {
      let managed_lines = [];
      if (!loadingNumbers) {
        if (filter === "all") {
          managed_lines = managedNumbers?.map((num) => num?.id);
        } else if (filter === "assigned") {
          managed_lines = [user?.assigned_number?.id];
        } else {
          managed_lines = selectedLines;
        }
        if (managed_lines.includes(twilio_number_id)) {
          return true;
        }
      }
      return false;
    },
    [
      filter,
      selectedLines,
      user?.assigned_number?.id,
      managedNumbers?.map((num) => num.id),
      loadingNumbers,
    ],
  );

  const updateCall = (call) => {
    setCalls((calls) => {
      const existingCall = calls.find((c) => c.id === call.id);

      if (existingCall) {
        let newCalls = Array.from(calls);
        let oldCall = newCalls.find((c) => c.id === call.id);
        oldCall = { ...oldCall, ...call };

        setSelected((selectedCall) => {
          if (selectedCall?.id === call.id) {
            return { ...selectedCall, ...call };
          } else return selectedCall;
        });
        return newCalls;
      } else {
        const shouldAppendCall = shouldAddCallToList(call?.twilio_number?.id);
        if (calls?.length) {
          const lastCall = calls[calls.length - 1];
          if (dayjs(call?.created_at).isBefore(dayjs(lastCall?.created_at)))
            return calls;
          else {
            const newCalls = sortByCreatedAt([call, ...calls]);
            if (activeCall) {
              assignCallId(newCalls[0]);
            }
            if (shouldAppendCall) return newCalls;
            return calls;
          }
        } else {
          if (activeCall) {
            assignCallId(call);
          }
          return [call];
        }
      }
    });
  };

  const pusherCallUpdateHandler = (payload) => {
    try {
      const parsedData = getUrlParams(`?${payload}`, true);
      let params = DEFAULT_CALL_UPDATE_PAYLOAD;

      API_MATCHING_KEYS.forEach((key) => {
        if (parsedData[key]) {
          params[key] = parsedData?.[key];
        }
      });

      BOOLEAN_KEYS.forEach((key) => {
        if (parsedData[key]) {
          params[key] = parsedData?.[key] === "true";
        }
      });

      AUTHOR_KEYS.forEach((key) => {
        if (parsedData[key]) {
          params.author[key] = parsedData?.[key];
        }
      });

      if (parsedData.twilio_number_id) {
        params.twilio_number = numbers?.find(
          (num) => num.id === parsedData?.twilio_number_id,
        );
      }

      if (parsedData.voicemail) {
        params.meta.voicemail = { url: parsedData?.voicemail };
      }

      if (parsedData.recording) {
        params.meta.call_recording = { url: parsedData?.recording };
      }

      params.meta.duration = parseInt(parsedData?.duration || "0");

      updateCall(params);
    } catch (e) {
      console.error(e);
    }
  };

  const addReportedCall = (call) => {
    setReportedCalls((calls) => {
      const newCalls = [call, ...calls];
      return newCalls;
    });
  };

  useEffect(() => {
    if (
      isLineFilterCacheReady &&
      filter &&
      !loadingNumbers &&
      callsPageRef?.current &&
      !lineSelectVisible
    ) {
      fetchCalls();
    }
  }, [
    page,
    filter,
    selectedLines,
    isLineFilterCacheReady,
    loadingNumbers,
    callsPageRef?.current,
    lineSelectVisible,
    user?.assigned_number?.id,
  ]);

  const setPageActive = (active) => {
    callsPageRef.current = active;
  };

  useEffect(() => {
    if (!activeCall) {
      setActiveCallId();
      setActiveCallNote("");
    } else {
      if (calls[0]) assignCallId(calls[0]);
    }
  }, [activeCall, calls[0]]);

  useNumbersSubscription(CALL_UPDATE_CHANNEL, pusherCallUpdateHandler);

  return (
    <CallsContext.Provider
      value={{
        page,
        error,
        loading,
        calls,
        getCalls,
        nextPage,
        prevPage,
        initialLoading,
        refreshCallLogs,
        selected,
        setSelected,
        participant,
        setParticipant,
        customerFormVisible,
        setCustomerFormVisible,
        setPageActive,
        activeCallId,
        activeCallNote,
        setActiveCallNote,
        updateCall,
        addReportedCall,
        reportedCalls,
      }}
    >
      {children}
    </CallsContext.Provider>
  );
};
