export const OPTIONS = [
  {
    label: "Lead",
    value: "lead",
  },
  {
    label: "Opportunity",
    value: "opportunity",
  },
  {
    label: "Current Customer",
    value: "current customer",
  },
  {
    label: "Lost",
    value: "lost",
  },
  {
    label: "Other",
    value: "other",
  },
  {
    label: "Inactive",
    value: "inactive",
  },
];

export const CUSTOMER_WEIGHTS_FOR_SEARCH = {
  SAME_FIRST_NAME: 2,
  SAME_LAST_NAME: 1,
  SAME_COMPANY_NAME: 2,
  SAME_FULL_NAME: 4,
  FULL_SAME_FULL_NAME: 5,
  FULL_SAME_COMPANY_NAME: 3,
  FULL_SAME_FIRST_NAME: 3,
};
