import React, { useContext, useEffect, useState } from "react";
import { Dimensions, StyleSheet, Text } from "react-native";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import HomeCompactScreen from "./src/screens/HomeCompactScreen/index.web";
import LoginScreen from "./src/screens/LoginScreen/index.web";
import HomeScreen from "./src/screens/HomeScreen/index.web";
import SplashScreen from "./src/screens/SplashScreen/index.web";
import LogoutScreen from "./src/screens/LogoutScreen/index.web";

import AppContext, { AppProvider } from "./src/contexts/AppContext";
import { BrowserProvider } from "./src/contexts/BrowserContext";
import UserContext, { UserProvider } from "./src/contexts/UserContext";
import AdminScreen from "./src/screens/AdminScreen/index.web";
import {
  OpenSans_400Regular,
  OpenSans_600SemiBold,
  OpenSans_700Bold,
  OpenSans_800ExtraBold,
} from "@expo-google-fonts/open-sans";
import { useFonts } from "expo-font";
import { setCustomText } from "./src/helpers/text";
import { EventProvider } from "react-native-outside-press";
import { Loader } from "@googlemaps/js-api-loader";
import { APP_ENV, GOOGLE_MAPS_API_KEY } from "./src/constants/app/index.web";
import UniversalModal from "./src/modals/UniversalModal/index.web";
import { useLocalStorageChange } from "./src/hooks/useLocalStorageChange";
import MFASetupScreen from "./src/screens/MFA/MFASetupScreen/index.web";
import MFAVerifyScreen from "./src/screens/MFA/MFAVerifyScreen/index.web";
import UseMainAppAuthShare from "./src/hooks/useMainAppAuthShare";
import useFontAwesomeLibrary from "./src/hooks/useFontAwesomeLibrary";

// if (APP_ENV === "production") {
//   console.log = () => {};
//   // console.error = () => {};
//   console.debug = () => {};
// }

const setGlobalFont = () => {
  const oldTextRender = Text.render;

  Text.render = function (...args) {
    const origin = oldTextRender.call(this, ...args);
    const children = origin.props.children;
    let myStyle = {};

    if (typeof children === "object" && children?.props) {
      if (
        !(
          children?.props?.className?.includes("7stjaf") ||
          children?.props?.className?.includes("1p3oiti") ||
          children?.props?.className?.includes("1wj997a")
        ) &&
        !children?.props?.style?.fontFamily
      ) {
        myStyle = {
          fontFamily: "OpenSans_400Regular",
        };
      }
      return React.cloneElement(origin, {
        children: React.cloneElement(children, {
          style: {
            ...(children?.props?.style || {}),
            ...myStyle,
          },
        }),
      });
    }

    if (
      !(
        origin?.props?.className?.includes("7stjaf") ||
        origin?.props?.className?.includes("1p3oiti") ||
        origin?.props?.className?.includes("1wj997a")
      ) &&
      !origin?.props?.style?.fontFamily
    ) {
      myStyle = {
        fontFamily: "OpenSans_400Regular",
      };
    }

    return React.cloneElement(origin, {
      style: {
        ...(origin?.props?.style || {}),
        ...myStyle,
      },
    });
  };
};

setGlobalFont();
useFontAwesomeLibrary();

export default function App() {
  const compactMode = Dimensions.get("window").width < 960;

  return (
    <EventProvider style={{ flex: 1 }}>
      <UniversalModal />
      <AppProvider value={{ compactMode }}>
        <UserProvider>
          <AppRoutes />
        </UserProvider>
      </AppProvider>
    </EventProvider>
  );
}

const AppRoutes = () => {
  const { ready, cacheLoading } = useContext(UserContext);
  const { setGoogleLibraryLoaded } = useContext(AppContext);

  UseMainAppAuthShare();
  let [fontsLoaded] = useFonts({
    OpenSans_400Regular,
    OpenSans_600SemiBold,
    OpenSans_700Bold,
    OpenSans_800ExtraBold,
  });

  useEffect(() => {
    const loader = new Loader({
      apiKey: GOOGLE_MAPS_API_KEY,
      libraries: ["places"],
    });
    try {
      loader.importLibrary("places").then(() => {
        setGoogleLibraryLoaded(true);
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (APP_ENV === "production") {
      try {
        const head = document.querySelector("head");
        const script = document.createElement("script");
        script.src =
          "https://js-cdn.dynatrace.com/jstag/178598305ee/bf98362cys/83aa0307bb3d0400_complete.js";
        script.crossOrigin = "anonymous";
        script.type = "text/javascript";
        head.appendChild(script);
      } catch {}
    }
  }, [APP_ENV]);

  if (!ready || cacheLoading) return <SplashScreen visible />;

  return (
    <BrowserProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={LoginScreen} />
          <Route path="/mfa-setup" component={MFASetupScreen} />
          <Route path="/mfa-verify" component={MFAVerifyScreen} />
          {/* <Route path="/admin" component={AdminScreen} /> */}
          <Route path="/logout" component={LogoutScreen} />
          <Route path="/compact" component={HomeCompactScreen} />
          <Route component={HomeScreen} />
        </Switch>
      </BrowserRouter>
    </BrowserProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  leftPanel: {
    maxWidth: 380,
    borderRightWidth: 1,
    borderRightColor: "lightgrey",
  },
  centerPanel: {
    minWidth: 380,
  },
  rightPanel: {
    maxWidth: 500,
    borderLeftWidth: 1,
    borderLeftColor: "lightgrey",
  },
  inner: {
    height: "100%",
  },
});
