export const ESTIMATES_STATUS_ESTIMATE = 0;
export const ESTIMATES_STATUS_DRAFT = 5;
export const ESTIMATES_STATUS_SENT = 1;
export const ESTIMATES_STATUS_ACCEPTED = 2;
export const ESTIMATES_STATUS_REJECTED = 3;
export const ESTIMATES_STATUS_SCHEDULED = 6;
export const ESTIMATES_STATUS_READY = 7;
export const ESTIMATES_STATUS_LOST = 4;
export const ESTIMATES_STATUS_COMPLETED = 8;

export const ESTIMATES_STATUS = [
  {
    value: ESTIMATES_STATUS_ESTIMATE,
    label: "Estimate",
    color: "#90A0B7",
  },
  {
    value: ESTIMATES_STATUS_DRAFT,
    label: "Draft",
    color: "#F2BD22",
  },
  {
    value: ESTIMATES_STATUS_SENT,
    label: "Sent",
    color: "#58D0FF",
  },
  {
    value: ESTIMATES_STATUS_ACCEPTED,
    label: "Accepted",
    color: "#72C501",
  },
  {
    value: ESTIMATES_STATUS_REJECTED,
    label: "Rejected",
    color: "#EF5555",
  },
  {
    value: ESTIMATES_STATUS_SCHEDULED,
    label: "Scheduled",
    color: "#3C4579",
  },
  {
    value: ESTIMATES_STATUS_READY,
    label: "Ready",
    color: "#7501C5",
  },
  {
    value: ESTIMATES_STATUS_COMPLETED,
    label: "Completed",
    color: "#9b9b9b",
  },
  {
    value: ESTIMATES_STATUS_LOST,
    label: "Lost",
    color: "#323C47",
  },
];
