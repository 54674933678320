import { List } from "./base";
import { PhoneNumber } from "./PhoneNumber";

export default class PhoneNumbers extends List {
  getPhoneNumbersForCallRouting(
    currentPhoneNumberId = "",
    serializeForList = true,
  ) {
    const phoneNumbersWithoutCallRouting = this.models.filter(
      (phoneNumber) =>
        !phoneNumber.hasCallRouting && phoneNumber.id !== currentPhoneNumberId,
    );

    if (serializeForList) {
      return phoneNumbersWithoutCallRouting.map((phoneNumber) =>
        phoneNumber.serializeForList(),
      );
    }

    return phoneNumbersWithoutCallRouting;
  }

  getPhoneLinesWithoutCredentials(credentialsMap) {
    return this.models
      .filter((phoneNumber) => !credentialsMap[phoneNumber.number])
      ?.map((phoneNumber) => phoneNumber.serializeForList());
  }

  getPhoneLinesWithCredentials(credentialsMap) {
    const map = {};
    this.models.forEach((phoneNumber) => {
      const credential = credentialsMap[phoneNumber.number];
      if (credential) {
        map[phoneNumber.number] = {
          ...credential,
          phoneLine: phoneNumber,
        };
      }
    });
    return map;
  }

  orderAlphabetically(numbers) {
    const models = numbers || this.models;
    return models.sort((a, b) => a.friendlyName.localeCompare(b.friendlyName));
  }

  search(searchTerm = "") {
    if (!searchTerm) return this.models;
    const lowerSearchTerm = searchTerm.toLowerCase();
    return this.models.filter(
      (phoneNumber) =>
        phoneNumber?.friendlyName?.toLowerCase()?.includes(lowerSearchTerm) ||
        phoneNumber?.phoneNumber?.includes(lowerSearchTerm),
    );
  }

  get phoneNumbers() {
    return this.models;
  }

  get model() {
    return PhoneNumber;
  }
}
