import { StyleSheet, Text, View } from "react-native";
import palette from "../../../styles/palette";
import Col from "../../../layouts/Col";
import Row from "../../../layouts/Row";
import Heading from "../../../components/Heading/index.web";
import InfoIconButton from "../../../components/InfoIconButton/index.web";
import { Strings } from "../../../constants/strings";
import commonStyles from "../../../styles/common";
import StyledSelect from "../../../components/StyledSelect";
import {
  CALL_ROUTING_RING_DURATION_KEY,
  RING_DURATION_KEY,
  RING_DURATION_OPTIONS,
} from "../../../constants/ringDuration";
import { useContext } from "react";
import UserContext from "../../../contexts/UserContext";
import { RingDuration } from "../../../models/RingDuration";
import useDraft from "../../../hooks/useDraft";
import SolidButton from "../../../components/Buttons/SolidButton";
import { useState } from "react";
import Engage from "../../../services/engage";

const styles = StyleSheet.create({
  ring_duration_screen_heading_container: {
    height: 65,
    borderBottomWidth: 1,
    borderBottomColor: palette.light_grey,
  },
});

function RingDurationScreen() {
  const { user, loading, getUser } = useContext(UserContext);
  const ringDuration = RingDuration.createFromUser(user);
  const { draft, updateDraft, changed, dirty, resetDraft } =
    useDraft(ringDuration);
  const ringDurationDraft = new RingDuration(draft);
  const [saving, setSaving] = useState(false);

  const onChange = (key) => (value) => {
    updateDraft({ [key]: value });
  };

  const onSave = async () => {
    setSaving(true);
    try {
      const changedKeys = Object.keys(changed);

      if (changedKeys.includes(RING_DURATION_KEY)) {
        await Engage.updateGeneralRingDuration(ringDurationDraft.ring_duration);
      }
      if (changedKeys.includes(CALL_ROUTING_RING_DURATION_KEY)) {
        await Engage.updateCallRoutingRingDuration(
          ringDurationDraft.sequential_ring_duration,
        );
      }
      await getUser();
    } finally {
      resetDraft();
      setSaving(false);
    }
  };

  return (
    <Col>
      <Row
        pl={16}
        pr={16}
        style={styles.ring_duration_screen_heading_container}
      >
        <Col leftCenter>
          <View style={commonStyles.row_center}>
            <Heading size={5} pb={8} mb={0}>
              {Strings.RING_DURATION_HEADING}
            </Heading>
            <InfoIconButton link={Strings.RING_DURATION_DOCS_URL} />
          </View>
        </Col>
      </Row>
      <GeneralRingDuration
        value={ringDurationDraft.ring_duration}
        onChange={onChange(RING_DURATION_KEY)}
        disabled={saving || loading}
      />
      <CallRoutingRingDuration
        value={ringDurationDraft.sequential_ring_duration}
        onChange={onChange(CALL_ROUTING_RING_DURATION_KEY)}
        disabled={saving || loading}
      />
      <SaveRingDuration
        onSave={onSave}
        disabled={!dirty}
        loading={saving || loading}
      />
    </Col>
  );
}

function GeneralRingDuration({ value, onChange, disabled }) {
  return (
    <Row pl={16} pr={16} pt={16}>
      <Col>
        <Heading
          size={5}
        >{`${Strings.RING_DURATION_HEADING} - ${Strings.RING_DURATION_GENERAL_LABEL}`}</Heading>
        <Text style={commonStyles.mb4}>
          {Strings.RING_DURATION_GENERAL_MESSAGE}
        </Text>
        <StyledSelect
          selectedValue={value}
          onValueChange={onChange}
          options={RING_DURATION_OPTIONS}
          border
          style={{ maxWidth: 200 }}
          disabled={disabled}
        />
      </Col>
    </Row>
  );
}

function CallRoutingRingDuration({ value, onChange, disabled }) {
  return (
    <Row pl={16} pr={16} pt={16}>
      <Col>
        <Heading
          size={5}
        >{`${Strings.RING_DURATION_HEADING} - ${Strings.RING_DURATION_CALL_ROUTING_LABEL}`}</Heading>
        <Text style={commonStyles.mb4}>
          {Strings.RING_DURATION_CALL_ROUTING_MESSAGE}
        </Text>
        <StyledSelect
          selectedValue={value}
          onValueChange={onChange}
          options={RING_DURATION_OPTIONS}
          border
          style={{ maxWidth: 200 }}
          disabled={disabled}
        />
      </Col>
    </Row>
  );
}

function SaveRingDuration({ disabled, onSave, loading }) {
  return (
    <Row pl={16} pr={16} pt={16}>
      <View>
        <SolidButton
          label={Strings.GENERAL_SAVE_LABEL}
          disabled={disabled}
          onPress={onSave}
          loading={loading}
        />
      </View>
    </Row>
  );
}

export default RingDurationScreen;
