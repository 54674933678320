import PropTypes from "prop-types";
import React from "react";
import {
  Linking,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
// @ts-ignore
import ParsedText from "react-native-parsed-text";
import Communications from "react-native-communications";
import { StylePropType } from "../helpers/utils";
import palette from "../../../styles/palette";
import dayjs from "dayjs";
import { Strings } from "../../../constants/strings";
import { WebAppPostMessage } from "../../../models/WebAppPostMessage";
import { IS_EMBEDDED } from "../../../constants/app/index.web";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import {
  NOT_DELIVERED_DUE_TO_NETWORK_STATUS,
  NOT_DELIVERED_STATUS,
} from "../../../constants/conversations";
import Clipboard from "@react-native-clipboard/clipboard";
const WWW_URL_PATTERN = /^www\./i;
const textStyle = {
  fontSize: 16,
  lineHeight: 20,
};
const containerStyle = {
  flexDirection: "row",
  flexWrap: "wrap",
};
const styles = {
  undelivered_message: StyleSheet.create({
    text: {
      color: palette.blocked,
      fontFamily: "OpenSans_600SemiBold",
      fontSize: 12,
    },
    container: {
      flexDirection: "row",
      alignItems: "center",
      gap: 4,
    },
  }),
  left: StyleSheet.create({
    container: containerStyle,
    text: {
      color: "black",
      ...textStyle,
    },
    link: {
      color: "black",
      textDecorationLine: "underline",
    },
  }),
  right: StyleSheet.create({
    container: containerStyle,
    text: {
      color: "black",
      ...textStyle,
    },
    link: {
      color: "black",
      textDecorationLine: "underline",
    },
  }),
};

const timeStampTextStyle = {
  fontSize: 10,
  color: palette.label_grey,
  fontFamily: "OpenSans_600SemiBold",
};

const timestampStyles = {
  left: StyleSheet.create({
    container: {
      justifyContent: "flex-end",
      flex: 1,
      alignItems: "flex-start",
    },
    text: timeStampTextStyle,
  }),
  right: StyleSheet.create({
    container: {
      justifyContent: "flex-end",
      flex: 1,
      alignItems: "flex-end",
    },
    text: timeStampTextStyle,
  }),
};

const DEFAULT_OPTION_TITLES = [
  Strings.GENERAL_CALL_LABEL,
  Strings.GENERAL_MESSAGE_LABEL,
  Strings.GENERAL_COPY_PHONE_NUMBER_LABEL,
  Strings.GENERAL_SAVE_AS_CONTACT_LABEL,
  Strings.GENERAL_CANCEL_LABEL,
];
export default class MessageText extends React.Component {
  constructor() {
    super(...arguments);
    this.onUrlPress = (url) => {
      // When someone sends a message that includes a website address beginning with "www." (omitting the scheme),
      // react-native-parsed-text recognizes it as a valid url, but Linking fails to open due to the missing scheme.
      if (WWW_URL_PATTERN.test(url)) {
        this.onUrlPress(`http://${url}`);
      } else {
        if (IS_EMBEDDED) {
          const openUrlEvent = WebAppPostMessage.createOpenURLEvent(url);
          openUrlEvent.emitEvent();
        } else {
          Linking.canOpenURL(url).then((supported) => {
            if (!supported) {
              console.error("No handler for URL:", url);
            } else {
              Linking.openURL(url);
            }
          });
        }
      }
    };
    this.onPhonePress = (phone) => {
      const { optionTitles } = this.props;

      const cancelButtonIndex = optionTitles.length - 1;
      this.context.actionSheet().showActionSheetWithOptions(
        {
          options: optionTitles,
          cancelButtonIndex,
        },
        (buttonIndex) => {
          switch (buttonIndex) {
            case 0:
              this.props.onPhonePress(phone, Strings.COMMUNICATIONS.CALL);
              break;
            case 1:
              this.props.onPhonePress(phone, Strings.COMMUNICATIONS.MESSAGE);
              break;
            case 2:
              Clipboard.setString(phone);
              break;
            case 3:
              this.props.onPhonePress(phone, Strings.COMMUNICATIONS.SAVE);
              break;
            default:
              break;
          }
        },
      );
    };
    this.onEmailPress = (email) =>
      Communications.email([email], null, null, null, null);

    this.shortenLink = function (link, maxLength) {
      if (link.length <= maxLength) {
        return link;
      }

      const half = Math.floor(maxLength / 2);
      const start = link.slice(0, half);
      const end = link.slice(-half);

      return `${start}...${end}`;
    };

    this.onUndeliveredMessagePress = () => {
      const options = [
        Strings.MESSAGE_DELETE_LABEL,
        Strings.GENERAL_CANCEL_LABEL,
      ];

      if (this.props?.canRetry) {
        options.unshift(Strings.MESSAGE_TRY_AGAIN_LABEL);
      }

      const cancelButtonIndex = options.length - 1;
      this.context.actionSheet().showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex,
        },
        (buttonIndex) => {
          switch (buttonIndex) {
            case 0:
              if (this.props?.canRetry) {
                return this.props.onRetryMessage(
                  this.props.currentMessage?._id,
                  this.props.currentMessage,
                );
              } else {
                return this.props.onDeleteMessage(
                  this.props.currentMessage?.id,
                  this.props.currentMessage?._id,
                  this.props.currentMessage,
                );
              }
            case 1:
              if (this.props?.canRetry) {
                return this.props.onDeleteMessage(
                  this.props.currentMessage?.id,
                  this.props.currentMessage?._id,
                  this.props.currentMessage,
                );
              }
              break;
            default:
              break;
          }
        },
      );
    };
  }
  shouldComponentUpdate(nextProps) {
    return (
      !!this.props.currentMessage &&
      !!nextProps.currentMessage &&
      (this.props.currentMessage.text !== nextProps.currentMessage.text ||
        this.props.currentMessage.status !== nextProps.currentMessage.status)
    );
  }
  render() {
    const linkStyle = [
      styles[this.props.position].link,
      this.props.linkStyle && this.props.linkStyle[this.props.position],
    ];

    const isUnDelivered =
      this.props.currentMessage &&
      (this.props.currentMessage.status === NOT_DELIVERED_STATUS ||
        this.props.currentMessage.status ===
          NOT_DELIVERED_DUE_TO_NETWORK_STATUS);

    return (
      <View>
        <View
          style={[
            styles[this.props.position].container,
            this.props.containerStyle &&
              this.props.containerStyle[this.props.position],
          ]}
        >
          <ParsedText
            style={[
              styles[this.props.position].text,
              this.props.textStyle && this.props.textStyle[this.props.position],
              this.props.customTextStyle,
            ]}
            parse={[
              ...this.props.parsePatterns(linkStyle),
              {
                type: "url",
                style: linkStyle,
                onPress: this.onUrlPress,
                renderText: (url) => {
                  return <Text>{this.shortenLink(url, 80)}</Text>;
                },
              },
              { type: "phone", style: linkStyle, onPress: this.onPhonePress },
              { type: "email", style: linkStyle, onPress: this.onEmailPress },
            ]}
            childrenProps={{ ...this.props.textProps }}
          >
            {this.props.currentMessage.text}
          </ParsedText>
        </View>
        {!!this.props?.currentMessage?.created_at && (
          <View style={timestampStyles[this.props.position].container}>
            <Text
              style={timestampStyles[this.props.position].text}
              selectable={false}
            >
              {dayjs(this.props.currentMessage.created_at).format(
                this.props.timeFormat,
              )}
            </Text>
          </View>
        )}
        {isUnDelivered && (
          <TouchableOpacity onPress={this.onUndeliveredMessagePress}>
            <View style={styles.undelivered_message.container}>
              <FontAwesomeIcon
                icon={faInfoCircle}
                color={palette.blocked}
                size={12}
              />
              <Text style={styles.undelivered_message.text}>
                {Strings.MESSAGE_NOT_DELIVERED_LABEL}
              </Text>
            </View>
          </TouchableOpacity>
        )}
      </View>
    );
  }
}
MessageText.contextTypes = {
  actionSheet: PropTypes.func,
};
MessageText.defaultProps = {
  position: "left",
  optionTitles: DEFAULT_OPTION_TITLES,
  currentMessage: {
    text: "",
  },
  containerStyle: {},
  textStyle: {},
  linkStyle: {},
  customTextStyle: {},
  textProps: {},
  parsePatterns: () => [],
};
MessageText.propTypes = {
  position: PropTypes.oneOf(["left", "right"]),
  optionTitles: PropTypes.arrayOf(PropTypes.string),
  currentMessage: PropTypes.object,
  containerStyle: PropTypes.shape({
    left: StylePropType,
    right: StylePropType,
  }),
  textStyle: PropTypes.shape({
    left: StylePropType,
    right: StylePropType,
  }),
  linkStyle: PropTypes.shape({
    left: StylePropType,
    right: StylePropType,
  }),
  parsePatterns: PropTypes.func,
  textProps: PropTypes.object,
  customTextStyle: StylePropType,
  onPhonePress: PropTypes.func,
};
