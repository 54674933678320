import {
  appendInteractions,
  prependInteractions,
  findPendingInteraction,
  removePendingInteraction,
  removeInteraction,
  findInteraction,
} from "../helpers/conversations";

export const initialState = {
  id: null,
  participant: null,
  customer: null,
  acount_id: null,
  interactions: [],
  pendingInteractions: [],
  preview: "",
  unread_count: 0,
  timestamp: null,
  page: 1,
};

export const initConversation = ({ id, twilio_number }) => {
  return {
    ...initialState,
    twilio_number,
    id,
  };
};

export const conversationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if (action?.type) {
    console.log("[conversationReducer]", type);
    // console.log(JSON.stringify(payload, null, 2));
  }

  switch (type) {
    case "SET_CONVERSATION": {
      if (state.customer?.id === payload?.participant?.remote_id) {
        return {
          ...initialState,
          ...payload,
          customer: state.customer,
        };
      }

      return {
        ...initialState,
        ...payload,
      };
    }

    case "UPDATE_CONVERSATION": {
      return {
        ...state,
        ...payload,
      };
    }

    case "CLEAR_CONVERSATION": {
      const { twilio_number, ...resetState } = initialState;
      return {
        ...state,
        ...resetState,
      };
    }

    case "PREPEND_INTERACTIONS": {
      return {
        ...state,
        interactions: prependInteractions(state.interactions, payload),
      };
    }

    case "APPEND_INTERACTIONS": {
      return {
        ...state,
        id: payload.conversation_id || state.id,
        interactions: appendInteractions(state.interactions, payload),
      };
    }

    case "APPEND_PENDING_INTERACTION": {
      return {
        ...state,
        // pendingInteractions: [payload, ...state.pendingInteractions],
        pendingInteractions: appendInteractions(
          state.pendingInteractions,
          payload,
        ),
      };
    }

    case "UPDATE_PENDING_INTERACTION": {
      const { pendingInteractions } = state;

      if (!payload?._id) {
        console.error(
          "conversationReducer:Error:UPDATE_PENDING_INTERACTION",
          `Interaction is missing an _id property`,
          payload,
        );
        return state;
      }

      const [pendingInteraction, index] = findPendingInteraction(
        pendingInteractions,
        payload._id,
      );

      if (!pendingInteraction) {
        console.error(
          "conversationReducer:Error:UPDATE_PENDING_INTERACTION",
          `Could not find pending interaction with _id=${payload._id}`,
          [pendingInteractions, payload],
        );

        return state;
      }

      // If this is a new conversation.. set the conversation ID from the interaction
      let conversation_id = state.id;
      if (!conversation_id) {
        conversation_id = payload.conversation_id;
      }

      const _id = payload?.interaction_id || payload._id;

      // If status is changing from pending to another state.. remove it from
      // the pendingInteractions array and add it to the interactions array
      if (!payload.pending && pendingInteraction) {
        const [newPendingInteractions, removedInteraction] =
          removePendingInteraction(pendingInteractions, payload._id);

        return {
          ...state,
          id: conversation_id,
          interactions: appendInteractions(state.interactions, {
            ...(removedInteraction || {}),
            ...payload,
            _id,
          }),
          pendingInteractions: newPendingInteractions,
        };
      }
      // If status is still pending.. just update the respective pending interaction object.
      else {
        let pendingInteractionsCopy = pendingInteractions.concat();

        if (pendingInteraction) {
          pendingInteractionsCopy[index] = {
            ...pendingInteraction,
            ...payload,
            _id,
          };
          return {
            ...state,
            id: conversation_id,
            pendingInteractions: pendingInteractionsCopy,
          };
        }
      }

      return state;
    }

    case "REMOVE_PENDING_INTERACTION": {
      const { pendingInteractions } = state;

      if (!payload?._id) {
        console.error(
          "conversationReducer:Error:REMOVE_PENDING_INTERACTION",
          `Interaction is missing an _id property`,
          payload,
        );
        return state;
      }

      const [updatedPendingInteractions, removedInteraction] =
        removePendingInteraction(pendingInteractions, payload._id);

      if (!removedInteraction) {
        console.error(
          "conversationReducer:Error:UPDATE_PENDING_INTERACTION",
          `Could not find pending interaction with _id=${payload._id}`,
          [pendingInteractions, payload],
        );
        return state;
      }

      return {
        ...state,
        pendingInteractions: updatedPendingInteractions,
      };
    }

    case "SET_PARTICIPANT": {
      const { participant, customer = {}, twilio_number } = payload;
      let valid = true;

      if (!participant) {
        console.error(
          "conversationReducer:Error:SET_PARTICIPANT",
          `participant is a required parameter`,
          participant,
        );
        return state;
      }

      if (!participant?.phone_e164) {
        valid = false;
      }

      const remote_id = customer?.id || participant?.remote_id;
      const name = customer?.name || participant?.name || "";
      const first_name = customer?.first_name || participant?.first_name || "";
      const last_name = customer?.last_name || participant?.last_name || "";
      const email = customer?.email || participant?.email;
      const phone_e164 = customer?.phone_e164 || participant?.phone_e164;
      const phone_number = customer?.phone || participant?.phone_number;
      const national_number = participant?.national_number;

      const displayName =
        name ||
        `${first_name || ""} ${last_name || ""}`.trim() ||
        national_number ||
        phone_e164 ||
        phone_number ||
        "";

      return {
        ...initialState,
        ...payload,
        twilio_number: twilio_number || state.twilio_number,
        customer: remote_id
          ? {
              id: remote_id,
              name,
              first_name,
              last_name,
              email,
              phone: phone_number,
              phone_e164,
              ...customer,
            }
          : null,
        participant: {
          ...participant,
          first_name: first_name,
          last_name: last_name,
          email: email,
          name: displayName,
          phone_number: phone_e164 || national_number || phone_number,
          valid,
        },
      };
    }

    case "CLEAR_PARTICIPANT": {
      const { twilio_number, ...resetState } = initialState;
      return {
        ...state,
        ...resetState,
      };
    }

    case "SET_CUSTOMER": {
      const { customer, twilio_number } = payload;
      let valid = true;

      if (!customer?.id) {
        console.error(
          "conversationReducer:Error:SET_CUSTOMER",
          `customer is missing an id property`,
          customer,
        );
        return state;
      }

      if (!customer?.phone_e164) {
        valid = false;
      }

      const customer_id = customer?.id;
      const name = customer?.name;
      const first_name = customer?.first_name || "";
      const last_name = customer?.last_name || "";
      const email = customer?.email;
      const phone_e164 = customer?.phone_e164;
      const phone = customer?.phone;

      const displayName =
        name ||
        `${first_name || ""} ${last_name || ""}`.trim() ||
        phone_e164 ||
        phone ||
        email ||
        "";

      return {
        ...initialState,
        ...payload,
        twilio_number: twilio_number || state.twilio_number,
        participant: {
          id: null,
          remote_id: customer_id,
          name: displayName,
          phone_number: phone,
          phone_e164,
          first_name,
          last_name,
          email,
          valid,
        },
      };
    }

    case "DELETE_INTERACTION": {
      const { interactions } = state;

      if (!payload?._id) {
        console.error(
          "conversationReducer:Error:DELETE_INTERACTION",
          `Interaction is missing an _id property`,
          payload,
        );
        return state;
      }

      const [updatedInteractions, removedInteraction] = removeInteraction(
        interactions,
        payload._id,
      );

      if (!removedInteraction) {
        console.error(
          "conversationReducer:Error:DELETE_INTERACTION",
          `Could not find pending interaction with _id=${payload._id}`,
          [interactions, payload],
        );
        return state;
      }

      console.log(
        "conversationReducer:DELETE_INTERACTION",
        updatedInteractions,
        removedInteraction,
      );

      return {
        ...state,
        interactions: updatedInteractions,
      };
    }

    case "UPDATE_INTERACTION": {
      const { interactions } = state;

      if (!payload || !payload._id) {
        console.error(
          "conversationReducer:Error:UPDATE_INTERACTION",
          `Interaction is missing an _id property`,
          payload,
        );
        return state;
      }

      const [interaction, index] = findInteraction(interactions, payload._id);

      if (!interaction) {
        console.error(
          "conversationReducer:Error:UPDATE_INTERACTION",
          `Could not find pending interaction with _id=${payload._id}`,
          [interactions, payload],
        );

        return state;
      }

      try {
        const _id = payload?.interaction_id || payload._id;
        const interactionsCopy = [...interactions];

        if (interaction) {
          interactionsCopy[index] = {
            ...interaction,
            ...payload,
            _id,
          };
          return {
            ...state,
            interactions: interactionsCopy,
          };
        }

        return state;
      } catch (err) {
        console.error(
          "conversationReducer:Error:UPDATE_INTERACTION",
          "Error occurred while updating interaction state",
          err,
        );
        return state;
      }
    }

    default: {
      return state;
    }
  }
};
