import { createContext, useEffect, useMemo, useState } from "react";
import SipDomain from "../models/SipDomain";
import CredentialList from "../models/CredentialList";
import Credential from "../models/Credential";
import { PhoneNumber } from "../models/PhoneNumber";

const SipDomainsContext = createContext({});

export function SipDomainsProvider({ children }) {
  const [loading, setLoading] = useState({
    domains: false,
    credentialLists: false,
  });
  const [saving, setSaving] = useState({
    domain: false,
    credentialList: false,
    credential: false,
  });
  const [domains, setDomains] = useState([]);
  const [credentialLists, setCredentialLists] = useState([]);
  const [credentials, setCredentials] = useState([]);

  const primaryDomain = useMemo(() => {
    if (domains == null || domains.length === 0) {
      return null;
    }
    return domains[0];
  }, [domains]);

  const primaryCredentialList = useMemo(() => {
    if (credentialLists == null || credentialLists.length === 0) {
      return null;
    }
    return credentialLists[0];
  }, [credentialLists]);

  async function getDomains() {
    setLoading(() => ({
      domains: true,
    }));
    const domainsRes = await SipDomain.getDomains();
    if (domainsRes && !domainsRes.error) {
      setDomains(domainsRes.data || []);
      if (domainsRes.data?.length > 0) {
        await getCredentialLists(domainsRes?.data[0]?.id);
      }
    }
    setLoading(() => ({
      domains: false,
    }));
  }

  async function createDomain() {
    setSaving(() => ({
      domain: true,
    }));
    const res = await SipDomain.createDomain();
    if (res && !res.error && res.data) {
      setDomains([...domains, res.data]);
    }
    setSaving(() => ({
      domain: false,
    }));
    return res;
  }

  async function getCredentialLists(domainId = primaryDomain?.id) {
    if (!domainId) {
      return;
    }
    setLoading((data) => ({
      ...data,
      credentialLists: true,
    }));
    const credentialListsRes = await CredentialList.getCredentialLists(
      domainId,
    );
    if (credentialListsRes && !credentialListsRes.error) {
      setCredentialLists(credentialListsRes.data || []);
      if (credentialListsRes.data?.length > 0) {
        await getCredentials(domainId, credentialListsRes?.data[0]?.id);
      }
    }
    setLoading((data) => ({
      ...data,
      credentialLists: false,
    }));
  }

  async function createCredentialList(friendlyName) {
    if (!primaryDomain?.id) {
      return;
    }
    setSaving((data) => ({
      ...data,
      credentialList: true,
    }));
    const credentialListRes = await CredentialList.createCredentialList(
      primaryDomain.id,
      friendlyName,
    );

    if (
      credentialListRes &&
      !credentialListRes.error &&
      credentialListRes.data
    ) {
      setCredentialLists((credentialLists) => [
        ...credentialLists,
        credentialListRes.data,
      ]);
    }

    setSaving((data) => ({
      ...data,
      credentialList: false,
    }));
    return credentialListRes;
  }

  async function getCredentials(
    domainId = primaryDomain?.id,
    credentialListId = primaryCredentialList?.id,
  ) {
    if (!domainId || !credentialListId) {
      return;
    }
    setLoading((data) => ({
      ...data,
      credentials: true,
    }));
    const credentialsRes = await CredentialList.getCredentials(
      domainId,
      credentialListId,
    );
    if (credentialsRes && !credentialsRes.error && credentialsRes.data) {
      setCredentials(credentialsRes.data);
    }
    setLoading((data) => ({
      ...data,
      credentials: false,
    }));
  }

  async function createCredential(data) {
    setSaving((data) => ({
      ...data,
      credential: true,
    }));

    const credential = new Credential(data);
    if (!primaryDomain?.id) {
      const domainRes = await createDomain();
      if (domainRes.error) {
        console.error("Error creating domain", domainRes.error);
        return;
      }
      credential.domainId = domainRes?.data?.id;
    }
    if (!primaryCredentialList?.id) {
      const credentialListRes = await createCredentialList(data.username);
      if (credentialListRes.error) {
        console.error(
          "Error creating credential list",
          credentialListRes.error,
        );
        return;
      }
      credential.credentialListId = credentialListRes?.data?.id;
    }

    if (!credential.credentialListId || !credential.domainId) {
      credential.setDomainData({
        domainId: primaryDomain?.id || credential.domainId,
        credentialListId:
          primaryCredentialList?.id || credential.credentialListId,
      });
    }
    const res = await credential.save();
    if (res && !res.error && res.data) {
      setCredentials((credentials) => [...credentials, res.data]);
    }

    setSaving((data) => ({
      ...data,
      credential: false,
    }));
    return res;
  }

  async function deleteCredential(credential) {
    setSaving((data) => ({
      ...data,
      credential: true,
    }));
    const res = await Credential.delete(
      primaryDomain?.id,
      primaryCredentialList?.id,
      credential?.id,
    );
    if (res && !res.error) {
      await PhoneNumber.setCallRoutingNone(credential?.phoneLine);
      setCredentials((credentials) =>
        credentials.filter((c) => c.id !== credential?.id),
      );
    }
    setSaving((data) => ({
      ...data,
      credential: false,
    }));
    return res;
  }

  useEffect(() => {
    getDomains();
  }, []);

  return (
    <SipDomainsContext.Provider
      value={{
        domains,
        hasDomains: domains.length > 0,
        primaryDomain,
        loading,
        loadingDomains: loading.domains,
        loadingCredentialLists: loading.credentialLists,
        loadingCredentials: loading.credentials,
        savingDomain: saving.domain,
        savingCredentialList: saving.credentialList,
        savingCredential:
          saving.credential || saving.credentialList || saving.domain,
        saving,
        getDomains,
        createDomain,
        primaryCredentialList,
        createCredentialList,
        createCredential,
        getCredentialLists,
        credentials,
        getCredentials,
        deleteCredential,
      }}
    >
      {children}
    </SipDomainsContext.Provider>
  );
}

export default SipDomainsContext;
