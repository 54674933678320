import { usePusher, useChannel, useEvent } from "@harelpls/use-pusher";
import { isDevelopment } from "../constants/app";
import { useCallback, useMemo } from "react";

const INTERACTION_CREATED_EVENT = "interaction-created";
const INTERACTION_DELETED_EVENT = "interaction-deleted";
const INTERACTION_UPDATED_EVENT = "interaction-updated";

const CALL_INCOMING_EVENT = "call-incoming";
const CALL_OUTGOING_EVENT = "call-outgoing";
const CALL_ANSWERED_EVENT = "call-answered";
const CALL_REJECTED_EVENT = "call-rejected";
const CALL_ENDED_EVENT = "call-ended";

const MESSAGE_STATUS_UPDATED_EVENT = "message_status_updated";

export default function useConversationSubscription({
  conversation,
  onNewInteractionHandler,
  onDeleteInterationHandler,
  onUpdateInteractionHandler,
  onCallOutgoingHandler,
  onCallIncomingHandler,
  onCallAnsweredHandler,
  onCallRejectedHandler,
  onCallEndedHandler,
  onMessageStatusUpdatedHandler,
}) {
  const { client } = usePusher();
  const { twilio_number } = conversation || {};

  // Retriete necessary properties to build channel anme
  const twilio_number_id = twilio_number?.id;
  const conversation_id = conversation?.id;

  // Build conversation channel name and memoize it
  const channel_name = useMemo(() => {
    if (conversation_id && twilio_number_id) {
      return `private-conversation-${twilio_number_id}-${conversation_id}`;
    }
    return "channel-name";
  }, [twilio_number_id, conversation_id]);

  const channel = useChannel(channel_name);

  onNewInteractionHandler &&
    useEvent(channel, INTERACTION_CREATED_EVENT, onNewInteractionHandler);

  onDeleteInterationHandler &&
    useEvent(channel, INTERACTION_DELETED_EVENT, onDeleteInterationHandler);

  onUpdateInteractionHandler &&
    useEvent(channel, INTERACTION_UPDATED_EVENT, onUpdateInteractionHandler);

  onCallOutgoingHandler &&
    useEvent(channel, CALL_OUTGOING_EVENT, onCallOutgoingHandler);

  onCallIncomingHandler &&
    useEvent(channel, CALL_INCOMING_EVENT, onCallIncomingHandler);

  onCallAnsweredHandler &&
    useEvent(channel, CALL_ANSWERED_EVENT, onCallAnsweredHandler);

  onCallRejectedHandler &&
    useEvent(channel, CALL_REJECTED_EVENT, onCallRejectedHandler);

  onCallEndedHandler && useEvent(channel, CALL_ENDED_EVENT, onCallEndedHandler);

  onMessageStatusUpdatedHandler &&
    useEvent(
      channel,
      MESSAGE_STATUS_UPDATED_EVENT,
      onMessageStatusUpdatedHandler,
    );

  // Subscribe to private conversation channel
  // useEffect(() => {
  //   if (client && channel_name) {
  //     if (isDevelopment) {
  //       console.log("Subscribing to conversation", channel_name);
  //     }
  //     channel = client.subscribe(channel_name);
  //   }
  //   // Unsubscribe from private conversation channel when channel_name changes
  //   return () => {
  //     if (client && channel_name) {
  //       client.unsubscribe(channel_name);
  //       channel = null;
  //       if (isDevelopment) {
  //         console.log("Unsubscribed from conversation", channel_name);
  //       }
  //     }
  //   };
  // }, [channel_name, client]);

  // Listen for events on channel
  // useEffect(() => {
  //   if (client) {
  //     if (isDevelopment) {
  //       console.log(`Started listening for events on ${channel_name} channel`);
  //     }
  //     events.forEach((evt) => channel.bind(evt, handler));
  //   }
  //   // Stop listening for events on channel
  //   return () => {
  //     if (channel) {
  //       channel.unbind();
  //       if (isDevelopment) {
  //         console.log(
  //           `Stopped listening for events on ${channel_name} channel`
  //         );
  //       }
  //     }
  //   };
  // }, [client, events, handler, channel_name, conversation_id]);

  return { socket_id: client?.connection?.socket_id };
}
