const SOLID_STYLE = "s";
const LIGHT_STYLE = "l";
const DUOTONE_STYLE = "d";
const REGULAR_STYLE = "r";

const getIconName = (name, style) => {
  if (!name) return;
  let iconName = [`fa${style}`];
  iconName.push(`fa-${name}`);
  return iconName;
};

export const ICONS_LIBRARY = {
  COPY: getIconName("copy", LIGHT_STYLE),
  EDIT: getIconName("edit", REGULAR_STYLE),
  PLAY: "play",
  PAUSE: "pause",
  PLUS: "plus",
  PLUS_LIGHT: getIconName("plus", LIGHT_STYLE),
  GRID: "grid",
  CHEVRON_RIGHT: getIconName("chevron-right", REGULAR_STYLE),
  CHEVRON_LEFT: getIconName("chevron-left", REGULAR_STYLE),
  CHEVRON_UP: getIconName("chevron-up", REGULAR_STYLE),
  CHEVRON_DOWN: getIconName("chevron-down", REGULAR_STYLE),
  DOWNLOAD: getIconName("arrow-down-to-line", REGULAR_STYLE),
  EYE: getIconName("eye", REGULAR_STYLE),
  EYE_SLASH: getIconName("eye-slash", REGULAR_STYLE),
  FILE: getIconName("file", REGULAR_STYLE),
  STAR: "star",
  CANCEL: getIconName("xmark", REGULAR_STYLE),
  INFO: getIconName("circle-info", REGULAR_STYLE),
  HASHTAG: getIconName("hashtag", REGULAR_STYLE),
  ARROW_LEFT: getIconName("arrow-left", REGULAR_STYLE),
  ARROW_RIGHT: getIconName("arrow-right", REGULAR_STYLE),
  LOADER: getIconName("loader", REGULAR_STYLE),
  COMMENTS: getIconName("comments", REGULAR_STYLE),
  CHEVRON_CIRCLE_LEFT: getIconName("chevron-circle-left", REGULAR_STYLE),
  CHEVRON_CIRCLE_RIGHT: getIconName("chevron-circle-right", REGULAR_STYLE),
  SQUARE: getIconName("square", REGULAR_STYLE),
  SQUARE_LIGHT: getIconName("square", LIGHT_STYLE),
  SQUARE_CHECK: getIconName("square-check", REGULAR_STYLE),
  SQUARE_CHECK_LIGHT: getIconName("square-check", LIGHT_STYLE),
  BUILDING: getIconName("building", REGULAR_STYLE),
  BUILDING_LIGHT: getIconName("building", LIGHT_STYLE),
  USER: getIconName("user", REGULAR_STYLE),
  SEARCH: getIconName("search", REGULAR_STYLE),
  PHONE: getIconName("phone", REGULAR_STYLE),
  TRASH: getIconName("trash", REGULAR_STYLE),
  ERROR_ALERT: getIconName("exclamation-triangle", SOLID_STYLE),
  VOICEMAIL: getIconName("voicemail", REGULAR_STYLE),
  LINK: getIconName("link", REGULAR_STYLE),
  MICROPHONE: getIconName("microphone", REGULAR_STYLE),
  MESSAGES: getIconName("messages", REGULAR_STYLE),
  CLOCK: getIconName("clock", REGULAR_STYLE),
  CUSTOMERS: getIconName("address-book", REGULAR_STYLE),
  SETTINGS: getIconName("gear", SOLID_STYLE),
  SETTINGS_REGULAR: getIconName("gear", REGULAR_STYLE),
  PLAY_REGULAR: getIconName("play", REGULAR_STYLE),
  IMAGE: getIconName("image", REGULAR_STYLE),
  SEND: getIconName("paper-plane", REGULAR_STYLE),
  ASTERISK: getIconName("asterisk", REGULAR_STYLE),
  COMMENT: getIconName("comment", REGULAR_STYLE),
  PASTE: getIconName("paste", REGULAR_STYLE),
  MUTE: getIconName("microphone-slash", REGULAR_STYLE),
  SPEAKER: getIconName("volume", SOLID_STYLE),
  SPEAKER_REGULAR: getIconName("volume", REGULAR_STYLE),
  MINIMIZE: getIconName("right-to-bracket", REGULAR_STYLE),
  MAIL: getIconName("envelope", REGULAR_STYLE),
  MAIL_LIGHT: getIconName("envelope", LIGHT_STYLE),
  CIRCLE: getIconName("circle", SOLID_STYLE),
  PEN: getIconName("pen", REGULAR_STYLE),
  ADDRESS_CARD_LIGHT: getIconName("address-card", LIGHT_STYLE),
  FILTER_LIST_LIGHT: getIconName("filter-list", LIGHT_STYLE),
  IMAGE_LIGHT: getIconName("image", LIGHT_STYLE),
  NOTE_STICKY_LIGHT: getIconName("note-sticky", LIGHT_STYLE),
  PHONE_LIGHT: getIconName("phone", LIGHT_STYLE),
  TEXT_SIZE_LIGHT: getIconName("text-size", LIGHT_STYLE),
  PAPER_PLANE_DUOTONE: getIconName("paper-plane", DUOTONE_STYLE),
  MISSED_PHONE: getIconName("phone-missed", REGULAR_STYLE),
  PHONE_INCOMING: getIconName("phone-incoming", REGULAR_STYLE),
  PHONE_OUTGOING: getIconName("phone-outgoing", REGULAR_STYLE),
  EXTERNAL_LINK: getIconName("arrow-up-right-from-square", REGULAR_STYLE),
  SHARE: getIconName("share", SOLID_STYLE),
  FLAG: getIconName("flag", REGULAR_STYLE),
  FLAG_SOLID: getIconName("flag", SOLID_STYLE),
  BAN: getIconName("ban", REGULAR_STYLE),
  PAUSE_REGULAR: getIconName("pause", REGULAR_STYLE),
  TIMER: getIconName("timer", REGULAR_STYLE),
  ID_CARD_LIGHT: getIconName("id-card", LIGHT_STYLE),
  ID_CARD: getIconName("id-card", REGULAR_STYLE),
  LOADER_LIGHT: getIconName("loader", LIGHT_STYLE),
  USER_PLUS_LIGHT: getIconName("user-plus", LIGHT_STYLE),
  LAYER_GROUP_LIGHT: getIconName("layer-group", LIGHT_STYLE),
  LOCATION_DOT_LIGHT: getIconName("location-dot", LIGHT_STYLE),
};
