import {
  CALL_ROUTING_RING_DURATION_KEY,
  RING_DURATION_DEFAULT,
  RING_DURATION_KEY,
} from "../constants/ringDuration";
import { Model } from "./base";

export class RingDuration extends Model {
  ring_duration = RING_DURATION_DEFAULT;
  sequential_ring_duration = RING_DURATION_DEFAULT;

  constructor(data) {
    super(data);
    this.ring_duration = data?.ring_duration || this.ring_duration;
    this.sequential_ring_duration =
      data?.sequential_ring_duration || this.sequential_ring_duration;
  }

  static createFromUser(user) {
    return new RingDuration({
      ring_duration:
        user?.account?.[RING_DURATION_KEY] || RING_DURATION_DEFAULT,
      sequential_ring_duration:
        user?.account?.[CALL_ROUTING_RING_DURATION_KEY] ||
        RING_DURATION_DEFAULT,
    });
  }

  get default() {
    return {
      ring_duration: RING_DURATION_DEFAULT,
      sequential_ring_duration: RING_DURATION_DEFAULT,
    };
  }
}
