import { FlatList, ScrollView, StyleSheet, Text, View } from "react-native";
import useTwilio from "../../../hooks/useTwilio";
import Col from "../../../layouts/Col";
import Row from "../../../layouts/Row";
import palette from "../../../styles/palette";
import Heading from "../../../components/Heading/index.web";
import commonStyles from "../../../styles/common";
import SolidButton from "../../../components/Buttons/SolidButton";
import { toTitleCase } from "../../../helpers/text";
import InfoIconButton from "../../../components/InfoIconButton/index.web";
import { useState } from "react";
import {
  NETWORK_TEST_COL_1_FIELDS,
  NETWORK_TEST_COL_2_FIELDS,
  NETWORK_TEST_INFO,
  NETWORK_TEST_UNIT_TYPES,
} from "../../../constants/networkTest";
import { Strings } from "../../../constants/strings";
import NetworkTest from "../../../models/networkTest";
import { ProgressBar } from "react-native-web";

const styles = StyleSheet.create({
  network_diagnosis__header: {
    height: 65,
    borderBottomWidth: 1,
    borderBottomColor: palette.light_grey,
  },
  network_diagnosis__run_test__container: {
    margin: 16,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: palette.bg_light_grey,
    borderRadius: 8,
    padding: 32,
  },
  network_diagnosis__run_test__btn: {
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  network_diagnosis__test_report__outer_container: {
    margin: 16,
    backgroundColor: palette.bg_light_grey,
    borderRadius: 8,
    padding: 32,
  },
  network_diagnosis__test_report__container: {
    gap: 16,
    padding: 16,
    backgroundColor: palette.white,
    borderRadius: 8,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: palette.light_grey,
    flexGrow: 1,
  },
  network_diagnosis__test_report__header: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  network_diagnosis__test_report__help_text: {
    zIndex: 100,
    position: "absolute",
    backgroundColor: palette.lightest_grey,
    left: 0,
    top: -50,
    borderRadius: 8,
    padding: 12,
    boxShadow: palette.box_shadow,
  },
  network_diagnosis__report_fields: {
    borderBottomColor: palette.light_grey,
    borderBottomWidth: 1,
    borderBottomStyle: "dashed",
  },
  network_test_report_field_label: {
    color: palette.label_grey,
    fontSize: 16,
    fontFamily: "OpenSans_600SemiBold",
  },
  network_test_report_field_value: {
    fontFamily: "OpenSans_600SemiBold",
  },
  network_test_report_field_status: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 16,
    borderWidth: 1,
    marginLeft: 8,
  },
  network_test_report_field_status_text: {
    fontSize: 14,
    fontFamily: "OpenSans_600SemiBold",
  },
  network_test_report_field_value_container: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
});

export default function NetworkDiagnosisScreen() {
  const {
    runPreFlightTest,
    stopPreflightTest,
    preflightTestProgress,
    preflightTestReport,
    isPreflightRunning,
  } = useTwilio();

  return (
    <Col>
      <PreflightHeading />
      <RunPreflightTest
        onPress={runPreFlightTest}
        isPreflightRunning={isPreflightRunning}
        preflightTestReport={preflightTestReport}
        onCancel={stopPreflightTest}
        preflightTestFraction={preflightTestProgress}
      />
      <PreflightTestReport
        report={preflightTestReport}
        onPress={runPreFlightTest}
      />
    </Col>
  );
}

const PreflightHeading = () => {
  return (
    <Row pl={16} pr={16} style={styles.network_diagnosis__header}>
      <Col leftCenter>
        <View style={commonStyles.row_center}>
          <Heading size={5} pb={8} mb={0}>
            {Strings.NETWORK_TEST_GENERAL_HEADING}
          </Heading>
          <InfoIconButton link={Strings.NETWORK_TEST_DOCS_URL} />
        </View>
      </Col>
    </Row>
  );
};

const RunPreflightTest = ({
  onPress,
  isPreflightRunning,
  preflightTestReport,
  preflightTestFraction,
  onCancel,
}) => {
  if (preflightTestReport) return null;
  return (
    <View style={styles.network_diagnosis__run_test__container}>
      <Row center mb={16}>
        <Heading size={2} color={palette.primary} textStyle={{ fontSize: 30 }}>
          {Strings.NETWORK_TEST_GENERAL_HEADING}
        </Heading>
      </Row>
      {isPreflightRunning && (
        <Row mt={16} mb={16} center style={{ justifyContent: "center" }}>
          <ProgressBar
            progress={preflightTestFraction}
            trackColor={palette.light_grey}
            color={palette.info}
            style={{
              width: 250,
              borderRadius: 8,
              height: 8,
            }}
          />
          <Heading size={3} ml={16}>{`${parseInt(
            preflightTestFraction * 100,
          )}%`}</Heading>
        </Row>
      )}
      <Heading
        color={palette.label_grey}
        size={4}
        numberOfLines={null}
        textStyle={{ textWrap: "wrap" }}
        center
        ml={64}
        mr={64}
        mb={16}
      >
        {isPreflightRunning
          ? Strings.NETWORK_TEST_RUNNING_MESSAGE
          : Strings.NETWORK_TEST_START_TEST_MESSAGE}
      </Heading>
      <View>
        <SolidButton
          onPress={isPreflightRunning ? onCancel : onPress}
          label={
            isPreflightRunning
              ? Strings.NETWORK_TEST_CANCEL_BUTTON_LABEL
              : Strings.NETWORK_TEST_START_BUTTON_LABEL
          }
          color={isPreflightRunning ? palette.white : palette.success}
          style={styles.network_diagnosis__run_test__btn}
        />
      </View>
    </View>
  );
};

const PreflightTestReport = ({ report, onPress }) => {
  if (!report) return null;
  const reportData = new NetworkTest(report);
  const valuableData = reportData.extractValuableData();
  const warnings = reportData.getWarnings();
  const hasWarnings = warnings?.length > 0;

  const renderData = (field) => {
    const fieldInfo = NETWORK_TEST_INFO[field];
    const value = valuableData?.[field];
    const status = NetworkTest.getNetworkTestStatus(field, value);

    return (
      <Data
        key={field}
        label={fieldInfo?.label}
        value={value}
        type={fieldInfo?.type}
        helpText={fieldInfo?.helpText}
        status={status}
      />
    );
  };

  return (
    <ScrollView
      contentContainerStyle={
        styles.network_diagnosis__test_report__outer_container
      }
      showsVerticalScrollIndicator={false}
    >
      <View style={styles.network_diagnosis__test_report__container}>
        <Row
          style={styles.network_diagnosis__test_report__header}
          pl={8}
          pr={8}
        >
          <Heading size={4}>{Strings.NETWORK_TEST_GENERAL_HEADING}</Heading>
          <SolidButton
            onPress={onPress}
            label={Strings.NETWORK_TEST_RE_RUN_BUTTON_LABEL}
            color={palette.success_dark}
            style={styles.network_diagnosis__run_test__btn}
          />
        </Row>
        <Heading size={5} pl={16}>
          Call Quality
        </Heading>
        <Row
          pl={16}
          pr={16}
          gap={16}
          style={[hasWarnings && styles.network_diagnosis__report_fields]}
        >
          <Col>{NETWORK_TEST_COL_1_FIELDS.map(renderData)}</Col>
          <Col>{NETWORK_TEST_COL_2_FIELDS.map(renderData)}</Col>
        </Row>
        {hasWarnings && (
          <Heading size={4} color={palette.blocked}>
            {Strings.NETWORK_TEST_WARNINGS_GENERAL_HEADING}
          </Heading>
        )}
        <Row pl={16}>
          <Col>
            {warnings.map((warning, i) => (
              <Heading
                size={5}
                key={`${warning?.name}-${i}`}
                color={palette.blocked}
              >
                {warning?.name}
              </Heading>
            ))}
          </Col>
        </Row>
      </View>
    </ScrollView>
  );
};

const getFormattedValue = (value, type) => {
  if (value === undefined) return "-";
  if (type === NETWORK_TEST_UNIT_TYPES.NUMBER) return value.toFixed(2);
  if (type === NETWORK_TEST_UNIT_TYPES.BOOLEAN) return value ? "YES" : "NO";
  if (type === NETWORK_TEST_UNIT_TYPES.TITLE_CASE_STRING)
    return toTitleCase(value);
  if (type === NETWORK_TEST_UNIT_TYPES.UPPER_CASE_STRING)
    return value?.toUpperCase();
  if (type === NETWORK_TEST_UNIT_TYPES.DURATION)
    return `${value.toFixed(2)?.endsWith("00") ? value : value.toFixed(2)} ms`;
  if (type === NETWORK_TEST_UNIT_TYPES.PERCENTAGE)
    return `${parseInt(value.toFixed(2) * 100)}%`;

  return value;
};

const Data = ({ label, value, type, helpText = "", status }) => {
  const formattedValue = getFormattedValue(value, type);
  const [helpTextVisible, setHelpTextVisible] = useState(false);

  const handleTextVisibleChange = (value) => () => {
    if (!helpText) return;
    setHelpTextVisible(() => value);
  };

  return (
    <View style={helpTextVisible && { zIndex: 100 }}>
      <View style={[styles.network_diagnosis__report_field_container]}>
        <Col style={[commonStyles.row_center]}>
          <Text style={styles.network_test_report_field_label}>{label}</Text>
          {!!helpText && (
            <View
              onMouseEnter={handleTextVisibleChange(true)}
              onMouseLeave={handleTextVisibleChange(false)}
            >
              <InfoIconButton light onPress={null} />
            </View>
          )}
        </Col>
        <View style={[styles.network_test_report_field_value_container]}>
          <Text style={styles.network_test_report_field_value}>
            {formattedValue}
          </Text>
          {status && (
            <View
              style={[
                styles.network_test_report_field_status,
                {
                  backgroundColor: status.bgColor,
                  borderColor: status.color,
                },
              ]}
            >
              <Text
                style={[
                  styles.network_test_report_field_status_text,
                  {
                    color: status.color,
                  },
                ]}
              >
                {status.label}
              </Text>
            </View>
          )}
        </View>
        {helpTextVisible && !!helpText && (
          <View
            style={styles.network_diagnosis__test_report__help_text}
            onMouseEnter={handleTextVisibleChange(true)}
            onMouseLeave={handleTextVisibleChange(false)}
          >
            <Text>{helpText}</Text>
          </View>
        )}
      </View>
    </View>
  );
};
