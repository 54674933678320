import { isValidPassword } from "../helpers/sipDomain";
import Engage from "../services/engage";
import { Model } from "./base";

class Credential extends Model {
  id;
  username;
  password;
  domainId;
  credentialListId;

  setDomainData(domainData) {
    this.domainId = domainData.domainId;
    this.credentialListId = domainData.credentialListId;
  }

  isValid() {
    return (
      this.username.length > 0 &&
      this.password.length > 0 &&
      isValidPassword(this.password)
    );
  }

  async save() {
    try {
      return await Engage.createCredential(
        this.domainId,
        this.credentialListId,
        {
          username: this.username,
          password: this.password,
        },
      );
    } catch {
      return { response: {} };
    }
  }

  static async delete(domainId, credentialListId, id) {
    try {
      return await Engage.deleteCredential(domainId, credentialListId, id);
    } catch {
      return { response: {} };
    }
  }

  get defaults() {
    return {
      username: "",
      password: "",
      domainId: "",
      credentialListId: "",
      id: "",
    };
  }
}

export default Credential;
