import { useContext } from "react";
import SipDomainsContext from "../../../contexts/SipDomainsContext";
import SolidButton from "../../../components/Buttons/SolidButton";
import { FlatList, StyleSheet, Text, View } from "react-native";
import Heading from "../../../components/Heading/index.web";
import { Strings } from "../../../constants/strings";
import palette from "../../../styles/palette";
import Row from "../../../layouts/Row";
import InfoIconButton from "../../../components/InfoIconButton/index.web";
import { useHistory, Switch as RouterSwitch } from "react-router-dom";
import Col from "../../../layouts/Col";
import { ICONS_LIBRARY } from "../../../constants/icons";
import NumbersContext from "../../../contexts/NumbersContext";
import PhoneNumbers from "../../../models/PhoneNumbers";
import CredentialList from "../../../models/CredentialList";
import IconButton from "../../../components/Buttons/IconButton";
import HashRoute from "../../../routers/HashRoute";
import DeleteCredentialModal from "../../../modals/DeleteCredentialModal";
import SipConfigurationDetailsModal from "../../../modals/SipConfigurationDetailsModal";
import { getSipSeverUrls } from "../../../helpers/sipDomain";

function SipDomainScreen() {
  const { hasDomains, savingDomain, createDomain } =
    useContext(SipDomainsContext);

  return (
    <View style={styles.sip_domain_container}>
      <SipDomainHeader />
      <SipDomainDetails />
      <NoDomainComponent
        onPress={createDomain}
        loading={savingDomain}
        hasDomains={hasDomains}
      />
      <RouterSwitch>
        <HashRoute path="/" hash="#delete" component={DeleteCredentialModal} />
      </RouterSwitch>
      <RouterSwitch>
        <HashRoute
          path="/"
          hash="#details"
          component={SipConfigurationDetailsModal}
        />
      </RouterSwitch>
    </View>
  );
}

const NoDomainComponent = ({ onPress, loading, hasDomains }) => {
  if (hasDomains) return null;
  return (
    <View style={styles.resubmit_campaign_container}>
      <Heading color={palette.primary} size={2} mt={0} mb={24}>
        {Strings.SIP_GENERAL_HEADING}
      </Heading>
      <Text style={styles.flicent_campaign_warning_message}>
        {Strings.SIP_GENERAL_MESSAGE}
      </Text>
      <SolidButton
        color={palette.success}
        label={Strings.SIP_DOMAIN_ADD_LABEL}
        onPress={onPress}
        loading={loading}
        style={{
          minWidth: 120,
        }}
      />
    </View>
  );
};

const SipDomainHeader = () => {
  return (
    <View style={styles.sip_domain_header_container}>
      <Row leftCenter style={styles.row_gap}>
        <Heading size={3}>{Strings.SIP_GENERAL_HEADING}</Heading>
        <InfoIconButton link={Strings.A2P_10DLC_DOCS_URL} ml={0} mr={4} />
      </Row>
    </View>
  );
};

const SipDomainDetails = () => {
  const { primaryDomain, credentials } = useContext(SipDomainsContext);
  if (!primaryDomain) return null;

  return (
    <View>
      <PrimaryDomain domain={primaryDomain} />
      <CredentialsList domain={primaryDomain} credentials={credentials} />
    </View>
  );
};

const PrimaryDomain = ({ domain }) => {
  const domain_name = domain?.attributes?.domain_name || "";
  const { server1, server2 } = getSipSeverUrls(domain_name);
  return (
    <View style={styles.sip_domain_name_container}>
      <Heading size={5}>{Strings.SIP_DOMAIN_SERVER_1_LABEL}</Heading>
      <Text>{server1}</Text>
      <Heading size={5}>{Strings.SIP_DOMAIN_SERVER_2_LABEL}</Heading>
      <Text>{server2}</Text>
    </View>
  );
};

const CredentialsList = ({ credentials }) => {
  const history = useHistory();
  const { numbers } = useContext(NumbersContext);
  const phoneNumbers = new PhoneNumbers(numbers);
  const credentialsList = new CredentialList(credentials);
  const credentialMap = credentialsList.getCredentialsPhoneLineMap();
  const phoneLinesWithCredentialsMap =
    phoneNumbers.getPhoneLinesWithCredentials(credentialMap);

  const onNumberPress = (number) => () => {
    history.push("/settings/numbers", {
      number,
    });
  };

  const onDeletePress = (credential) => () => {
    history.push({
      hash: "delete",
      state: {
        credential,
      },
    });
  };

  const onDetailsPress = (credential) => () => {
    history.push({
      hash: "details",
      state: {
        credential,
      },
    });
  };

  const renderItem = ({ item }) => {
    const map = phoneLinesWithCredentialsMap[item?.attributes?.username];
    const isSip = map?.phoneLine?.isSipEnabled;
    return (
      <Row
        pl={18}
        pr={16}
        pb={4}
        style={{
          borderBottomColor: palette.light_grey,
          borderBottomWidth: StyleSheet.hairlineWidth,
        }}
      >
        <Col leftCenter>
          <Heading
            size={5}
            mb={0}
            mt={0}
            textStyle={{
              fontSize: 14,
            }}
          >
            {map?.phoneLine?.number && <Text>{map?.phoneLine?.number}</Text>}
          </Heading>
        </Col>
        <Col leftCenter>
          <Text>
            {map?.phoneLine?.friendlyName || item?.attributes?.username}
          </Text>
        </Col>
        <Col leftCenter>
          <Row
            center
            style={{
              justifyContent: "flex-start",
            }}
          >
            <View
              style={{
                paddingHorizontal: 16,
                paddingVertical: 8,
                borderRadius: 16,
                borderWidth: StyleSheet.hairlineWidth,
                backgroundColor: isSip
                  ? palette.light_green
                  : palette.lightest_grey,
                borderColor: isSip ? palette.green : palette.light_grey,
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  fontFamily: "OpenSans_600SemiBold",
                  color: isSip ? palette.green : palette.grey,
                }}
              >
                {map?.phoneLine?.formattedCallRoutingRoute}
              </Text>
            </View>
            <IconButton
              faPro
              faProIcon={ICONS_LIBRARY.SETTINGS}
              iconColor={palette.grey}
              onPress={onNumberPress(map?.phoneLine)}
            />
          </Row>
        </Col>
        <Col right>
          <Row
            center
            style={{
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              faPro
              faProIcon={ICONS_LIBRARY.INFO}
              onPress={onDetailsPress({ ...item, phoneLine: map?.phoneLine })}
              mr={0}
              iconColor={palette.grey}
            />
            <IconButton
              faPro
              faProIcon={ICONS_LIBRARY.TRASH}
              iconColor={palette.red}
              onPress={onDeletePress({ ...item, phoneLine: map?.phoneLine })}
              ml={0}
            />
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <FlatList
      data={credentials}
      renderItem={renderItem}
      ListEmptyComponent={NoCredentialComponent}
      keyExtractor={(item) => item?.id}
      ListHeaderComponent={CredentialListHeader}
    />
  );
};

const NoCredentialComponent = () => {
  return (
    <Row mt={24}>
      <Col center>
        <Heading size={5} color={palette.label_grey}>
          {Strings.SIP_DOMAIN_NO_CREDENTIALS_LABEL}
        </Heading>
        <AddCredentialButton />
      </Col>
    </Row>
  );
};

const CredentialListHeader = () => {
  return (
    <Row
      pl={16}
      pr={16}
      pb={4}
      style={{
        borderBottomColor: palette.light_grey,
        borderBottomWidth: StyleSheet.hairlineWidth,
      }}
    >
      <Col leftCenter>
        <Heading size={5}>
          {Strings.SIP_DOMAIN_CREDENTIAL_USERNAME_LABEL}
        </Heading>
      </Col>
      <Col leftCenter>
        <Heading size={5}>
          {Strings.SIP_DOMAIN_CREDENTIAL_FRIENDLY_NAME_LABEL}
        </Heading>
      </Col>
      <Col leftCenter>
        <Heading size={5}>{Strings.CALL_ROUTING_LABEL}</Heading>
      </Col>
      <Col right>
        <AddCredentialButton />
      </Col>
    </Row>
  );
};

export const AddCredentialButton = ({ phoneLine = "", state = {} }) => {
  const history = useHistory();
  const onAddCredentialPress = () => {
    history.push({
      hash: !!phoneLine
        ? "create-sip-credential-number"
        : "create-sip-credential",
      state: {
        phoneLine,
        ...state,
      },
    });
  };

  return (
    <View style={!!phoneLine && { alignSelf: "flex-start" }}>
      <SolidButton
        label={Strings.SIP_DOMAIN_ADD_CREDENTIAL_LABEL}
        onPress={onAddCredentialPress}
        faIcon={ICONS_LIBRARY.PLUS}
        iconPosition="left"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  sip_domain_container: {
    flex: 1,
  },
  sip_domain_header_container: {
    borderBottomColor: palette.light_grey,
    borderBottomWidth: StyleSheet.hairlineWidth,
    paddingVertical: 10,
    paddingBottom: 9,
    marginBottom: 16,
    paddingHorizontal: 24,
  },
  resubmit_campaign_container: {
    marginTop: 32,
    marginHorizontal: 16,
    padding: 32,
    paddingHorizontal: 32,
    backgroundColor: palette.grey_bg,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 6,
  },
  flicent_campaign_warning_message: {
    marginBottom: 24,
    textAlign: "center",
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 16,
    color: palette.grey,
  },
  sip_domain_name_container: {
    padding: 16,
  },
});

export default SipDomainScreen;
