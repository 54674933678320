import React, { useContext, useEffect, useMemo, useState } from "react";
import { StyleSheet, View, Text } from "react-native";
import ReactAudioPlayer from "react-audio-player";

import Screen from "~/layouts/Screen/";
import Row from "~/layouts/Row";
import Col from "~/layouts/Col";
import palette from "~/styles/palette";
import { BadgeContext } from "~/contexts/BadgeContext";
import {
  VoicemailsContext,
  VoicemailsProvider,
} from "~/contexts/VoicemailsContext";

import VoicemailList from "~/components/VoicemailsList/index.web";
import VoicemailsHeader from "~/components/Headers/VoicemailsHeader/";
import UserContext from "../../contexts/UserContext";
import NumbersContext from "../../contexts/NumbersContext";
import { Link } from "react-router-dom";
import MenuBar from "../../components/MenuBar/index.web";
import { DataField } from "../CallsScreen/index.web";
import Heading from "../../components/Heading/index.web";
import SolidButton from "../../components/Buttons/SolidButton";
import { getSmartTimestamp } from "../../helpers/day";
import { getCommaSeparatedHoursMinutesSeconds } from "../../helpers/text";
import StyledInput from "../../components/StyledInput";
import Engage from "../../services/engage";
import useTwilio from "../../hooks/useTwilio";
import IconButton from "../../components/Buttons/IconButton";
import { faBan, faComments, faPhone } from "@fortawesome/pro-regular-svg-icons";
import { parsePhoneNumber } from "libphonenumber-js";
import { useHistory } from "react-router-dom";
import CustomersContext from "../../contexts/CustomersContext";
import { BlockedBanner } from "../../components/BlockedBanner";
import { Strings } from "../../constants/strings";
import { ICONS_LIBRARY } from "../../constants/icons";
import CopyButton from "../../components/Buttons/CopyButton";

export default function VoicemailsScreen() {
  const { user } = useContext(UserContext);
  const { voicemails } = useContext(BadgeContext);
  const [selectedVoicemail, setSelectedVoicemail] = useState(null);

  const { activeNumber } = useContext(NumbersContext);

  return (
    <Screen safeArea>
      <Row style={[styles.container]}>
        <Col>
          <Row style={[styles.inner]}>
            <Col size={3} style={[styles.listPanel]}>
              <MenuBar />
              {activeNumber && (
                <VoicemailList
                  onVoicemailPress={(item) => setSelectedVoicemail(item)}
                />
              )}
            </Col>
            <Col style={[styles.centerPanel]}>
              <View>
                {user?.first_name ? (
                  <Text style={[styles.greeting]}>Hi, {user.first_name}!</Text>
                ) : (
                  <Text style={[styles.greeting]}>Hi!</Text>
                )}
              </View>
              {activeNumber ? (
                <Text style={[styles.text]}>
                  {" "}
                  You have{" "}
                  {!!voicemails ? (
                    <Text>
                      {voicemails} new voicemail{voicemails > 1 ? "s" : ""}
                    </Text>
                  ) : (
                    <Text>no unread voicemails</Text>
                  )}
                </Text>
              ) : (
                <>
                  <Text style={[styles.text]}>
                    You don't have an Active Number.
                  </Text>
                  <Link
                    to="/settings/number"
                    style={{ textDecoration: "none" }}
                  >
                    <Text style={[styles.link]}>Manage Numbers</Text>
                  </Link>
                </>
              )}
            </Col>
            <Col size={3} style={[styles.rightPanel]}>
              {selectedVoicemail && (
                <ReactAudioPlayer src={selectedVoicemail.url} controls />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Screen>
  );
}

export const VoicemailDetails = ({ setPanelVisible }) => {
  const { selected, setSelected } = useContext(VoicemailsContext);
  const { setCallQueue } = useTwilio();
  const { activeNumber } = useContext(NumbersContext);
  const { user, canSeeCustomerContact, isAdmin } = useContext(UserContext);
  const { blockedContacts } = useContext(CustomersContext);

  const isCustomerSaved = selected?.participant?.remote_id !== null;
  const hasParent = !!selected?.participant?.parent;
  const parentName = selected?.participant?.parent?.name;
  const isBlocked =
    selected?.participant?.is_blocked ||
    blockedContacts?.includes(selected?.participant?.id);
  const history = useHistory();

  const phoneNumber = useMemo(() => {
    if (selected !== null) {
      const { to, from, direction } = selected?.call_data;
      let phone = to;
      let res = "";

      if (direction === "inbound") {
        phone = from;
      }

      return phone;
    }
    return "";
  }, [selected]);

  const callStatus = useMemo(() => {
    if (selected !== null) {
      if (selected?.call_data.missed) {
        return "Missed";
      }

      if (selected?.call_data.direction === "inbound") {
        return "Inbound";
      }

      return "Outbound";
    }

    return "-";
  }, [selected]);

  const phoneLineUsed = useMemo(() => {
    if (selected !== null) {
      const { twilio_number } = selected?.call_data;
      let phone = twilio_number?.phone_number;
      try {
        let formattedPhone = parsePhoneNumber(phone).formatNational();
        phone = formattedPhone;
      } catch {}
      return phone;
    }
    return "";
  }, [selected, user?.account?.country]);

  const onCallPress = () => {
    setCallQueue({ to: phoneNumber, metaData: selected.participant });
  };

  const title = `${
    isCustomerSaved || canSeeCustomerContact
      ? selected.participant?.name
      : "Unknown Customer"
  }${!!hasParent ? `: ${parentName}` : ""}`;

  const onBlockPress = () => {
    history.push(
      { hash: "confirm-delete" },
      {
        message: `This will block ${title}${
          canSeeCustomerContact ? " " + phoneNumber : ""
        }. Do you want to continue?`,
        type: "block-contact",
        id: selected?.participant?.id,
        onSuccessMessage: Strings.BLOCK_NUMBER_SUCCESS,
        onErrorMessage: Strings.BLOCK_NUMBER_ERROR,
        title: Strings.BLOCK_NUMBER_CONFIRM_HEADING,
      },
    );
  };

  const onUnBlockPress = () => {
    history.push(
      { hash: "confirm-delete" },
      {
        message: `This will unblock ${title}${
          canSeeCustomerContact ? " " + phoneNumber : ""
        }. Do you want to continue?`,
        type: "unblock-contact",
        id: selected?.participant?.id,
        onSuccessMessage: Strings.UNBLOCK_NUMBER_SUCCESS,
        onErrorMessage: Strings.UNBLOCK_NUMBER_ERROR,
        title: Strings.UNBLOCK_NUMBER_CONFIRM_HEADING,
      },
    );
  };

  return (
    <>
      <Row
        center
        pl={32}
        pr={16}
        style={{
          justifyContent: "space-between",
          height: 75,
          borderBottomWidth: 1,
          borderBottomColor: palette.light_grey,
        }}
      >
        <Heading size={5} mb={0} mt={0}>
          Voicemail Details
        </Heading>
        <SolidButton
          label="Back"
          color={palette.white}
          onPress={() => {
            setPanelVisible(false);
          }}
          style={{
            borderWidth: 0,
          }}
        />
      </Row>
      <View
        style={{
          paddingHorizontal: 12,
          paddingVertical: 6,
        }}
      >
        <BlockedBanner
          onUnBlockPress={onUnBlockPress}
          isBlocked={isBlocked}
          isAdmin={isAdmin}
        />
        <DataField
          label={<Text style={[styles.text_label]}>Name:</Text>}
          value={
            <Text style={[styles.texting]}>
              {isCustomerSaved || canSeeCustomerContact
                ? selected.participant?.name
                : "Unknown Customer"}
              {!!hasParent && `: ${parentName}`}
            </Text>
          }
        />
        <DataField
          label={<Text style={[styles.text_label]}>Number:</Text>}
          value={
            <Row leftCenter>
              {canSeeCustomerContact && (
                <Text style={[styles.texting]}>{phoneNumber}</Text>
              )}
              <View
                style={{
                  marginLeft: 8,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Row
                  center
                  style={{
                    gap: 8,
                  }}
                  mt={4}
                >
                  <IconButton
                    faPro
                    faProIcon={faPhone}
                    iconColor={palette.success}
                    onPress={onCallPress}
                    iconSize={14}
                    width={18}
                    height={18}
                    ml={0}
                    mr={0}
                    disabled={!activeNumber}
                  />
                  <IconButton
                    faPro
                    faProIcon={faComments}
                    iconColor={palette.primary}
                    disabled={!activeNumber}
                    onPress={() => {
                      setSelected();
                      setPanelVisible(false);
                    }}
                    iconSize={14}
                    width={18}
                    height={18}
                    ml={0}
                    mr={0}
                  />
                  {!isBlocked && (
                    <IconButton
                      faPro
                      faProIcon={faBan}
                      onPress={onBlockPress}
                      iconColor={palette.blocked}
                      iconSize={14}
                      width={18}
                      height={18}
                      ml={0}
                      mr={0}
                    />
                  )}
                </Row>
              </View>
            </Row>
          }
        />
        <DataField
          label={<Text style={[styles.text_label]}>Call Info:</Text>}
          value={
            <Text style={[styles.texting]}>
              {callStatus} Call on {getSmartTimestamp(selected?.created_at)}
            </Text>
          }
        />
        <DataField
          label={<Text style={[styles.text_label]}>{`Phone Line Used`}:</Text>}
          value={
            <Text style={[styles.texting]}>
              {selected?.call_data?.twilio_number?.friendly_name || "-"}
              {" - "}
              {phoneLineUsed}
            </Text>
          }
        />

        <DataField
          label={<Text style={[styles.text_label]}>Lead Source:</Text>}
          value={
            <Text style={[styles.texting]}>
              {selected?.call_data?.twilio_number?.lead_source_name ||
                "Not Assigned"}
            </Text>
          }
        />
        <DataField
          label={<Text style={[styles.text_label]}>Voicemail Recording:</Text>}
          value={
            <Row center style={styles.data_field_row} mb={8}>
              <ReactAudioPlayer
                src={selected?.url}
                controls
                controlsList="noplaybackrate"
              />
              <CopyButton text={selected?.url} icon={ICONS_LIBRARY.LINK} />
            </Row>
          }
        />
        <DataField
          label={<Text style={[styles.text_label]}>Duration:</Text>}
          value={
            <Text style={[styles.texting]}>
              {getCommaSeparatedHoursMinutesSeconds(
                selected?.duration,
                "seconds",
                true,
              )}
            </Text>
          }
        />
        <CallNotes />
      </View>
    </>
  );
};

const CallNotes = () => {
  const { selected, setSelected } = useContext(VoicemailsContext);
  const [callNote, setCallNote] = useState(selected?.call_data?.note || "");
  const [saving, setSaving] = useState(false);

  const saveNote = async () => {
    setSaving(true);
    const res = await Engage.updateCallNote({
      id: selected?.call_data?.id,
      note: callNote,
    });
    if (!res?.error) {
      setSelected((e) => ({
        ...e,
        call_data: { ...e?.call_data, note: res?.response?.note },
      }));
    }
    setSaving(false);
  };

  useEffect(() => {
    setCallNote(selected?.call_data?.note || "");
  }, [selected]);

  return (
    <View>
      <DataField
        label={<Text style={[styles.text_label]}>Call Notes:</Text>}
        value={
          <View>
            <StyledInput
              multiline
              containerStyle={{
                width: "90%",
                borderWidth: StyleSheet.hairlineWidth,
                borderColor: palette.light_grey,
              }}
              numberOfLines={5}
              value={callNote}
              onChangeText={setCallNote}
              nativeID="voicemail_note"
            />
            {callNote !== selected?.call_data?.note && (
              <Row mt={8}>
                <SolidButton
                  label="Save Note"
                  loading={saving}
                  onPress={saveNote}
                  style={{ minWidth: 100 }}
                  disabled={!callNote || !callNote?.trim()}
                />
              </Row>
            )}
          </View>
        }
      />
    </View>
  );
};

const styles = StyleSheet.create({
  greeting: {
    textAlign: "center",
    fontSize: 30,
  },
  text: {
    textAlign: "center",
    fontSize: 18,
    color: palette.grey,
  },
  listPanel: {
    borderRightWidth: 1,
    borderRightColor: palette.light_grey,
    height: "100%",
  },
  rightPanel: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  centerPanel: {
    borderRightWidth: 1,
    borderRightColor: palette.light_grey,
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    minWidth: 380,
    height: "100%",
  },
  container: {
    flex: 1,
  },
  inner: {
    flex: 1,
  },
  link: {
    color: palette.primary_light,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 18,
    textAlign: "center",
  },
  texting: {
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 16,
  },
  text_label: {
    color: palette.label_grey,
    fontSize: 16,
  },
  data_field_row: { justifyContent: "flex-start", gap: 8 },
});
