import React, {
  createContext,
  useCallback,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import FieldPulse from "~/services/fieldpulse";
import Engage from "~/services/engage";
import useDraft from "~/hooks/useDraft";
import { filterCustomerWithRelations } from "../helpers/customers";
import COUNTRIES from "../constants/countries";
import UserContext from "./UserContext";
import { parsePhoneNumber } from "libphonenumber-js";
import ConversationContext from "./ConversationContext";
import { ACCOUNT_TYPES } from "../constants/accountTypes";
import EmailContext from "./EmailContext";
import { EMAIL_FILTER } from "../constants/conversations";
import { OPTIONS } from "../constants/customer";
import { safeParseNumber } from "../helpers/phoneNumbers";

const ContactContext = createContext({
  loading: false,
  saving: false,
  error: false,
  contact: null,
});

const initState = {
  account_type: ACCOUNT_TYPES[0].value,
  address_1: "",
  address_2: "",
  state: "",
  city: "",
  zip_code: "",
  billing_address_1: "",
  billing_address_2: "",
  billing_state: "",
  billing_city: "",
  billing_zip_code: "",
  first_name: "",
  last_name: "",
  company_name: "",
  display_name: "",
  phone: "",
  phone_e164: "",
  email: "",
  alt_email: "",
  alt_phone: "",
  notes: "",
  job_notes: "",
  lead_source_id: "",
  lead_source: "",
  main_location: null,
  status: OPTIONS[2].value,
};
export function ContactProvider({
  value = {},
  children,
  customer_id,
  isAdditionalContact = false,
}) {
  const {
    user: {
      account: { country },
    },
    company,
  } = useContext(UserContext);

  const { quickbooks_enabled, xero_enabled } = company;
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [contact, setContact] = useState({ ...initState, ...value });
  const [contactDeleted, setContactDeleted] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [isContact, setIsContact] = useState(isAdditionalContact);
  const { getEmailsByAddress, selectedMailBox, selectedDirection } =
    useContext(EmailContext);
  const {
    filter,
    conversation,
    setCustomer,
    loading: loadingConversation,
    newConversation,
  } = useContext(ConversationContext);

  const phoneNumber = useMemo(() => {
    let phoneNum = value?.is_child
      ? value?.parent?.phone_e164
      : value?.phone_e164;
    if (phoneNum) {
      try {
        const parsedNumber = parsePhoneNumber(phoneNum, "US");
        return {
          countryCode: `+${parsedNumber.countryCallingCode}`,
          phone: parsedNumber.nationalNumber,
        };
      } catch (e) {
        console.warn(e);
      }
      return {
        countryCode: `+${COUNTRIES[country].phoneCode}`,
        phone: value?.is_child ? value?.parent?.phone : value?.phone,
      };
    }
  }, [value]);

  const { draft, dirty, changed, updateDraft, resetDraft } = useDraft({
    ...contact,
  });

  // Validates customer data before create and updates
  const validate = useCallback(() => {
    if (!draft.first_name) {
      setError("First Name is required.");
      return false;
    }
    if (!draft.phone) {
      setError("Phone Number is required.");
      return false;
    }
    return true;
  }, [draft, setError]);

  const createAlternateContact = async (customer) => {
    if (!customer || !customer?.alt_phone) {
      return;
    }

    try {
      const parsedNumber = safeParseNumber(customer.alt_phone, "US");
      const parsedPrimaryNumber = safeParseNumber(
        customer.phone_e164 || customer.phone,
        "US",
      );

      if (parsedPrimaryNumber.number) {
        await Engage.createParticipant({
          first_name:
            (customer?.account_type === ACCOUNT_TYPES[1].value
              ? customer.company_name
              : customer.first_name) || customer?.display_name,
          last_name:
            customer?.account_type === ACCOUNT_TYPES[1].value
              ? ""
              : customer.last_name,
          phone_number: customer.alt_phone,
          phone_e164: parsedNumber?.number,
          remote_id: customer?.id,
          is_alt_phone: true,
        });
      }
    } catch (e) {
      console.error("createAlternateContact - error:", e);
    }
  };

  const getContact = useCallback(
    async (customer_id) => {
      setContactDeleted(false);
      setLoading(true);
      resetDraft();
      let res = {};
      if (customer_id === null) {
        setContact({ ...initState, ...value });
      } else {
        try {
          res = await FieldPulse.getCustomer(
            customer_id ? customer_id : contact?.id,
            "invoices,jobs,files",
          );

          if (!res.error) {
            const customer = filterCustomerWithRelations(res.response);
            setContact(customer);
            setIsContact(false);
            createAlternateContact(customer);
          } else {
            setContactDeleted(true);
            setError(res.message);
          }
        } catch {
          setContact({
            ...initState,
            ...value,
            id: customer_id ? customer_id : contact?.id,
          });
        }
      }
      setLoading(false);

      return res;
    },
    [setError, setContact, setSaving, setContactDeleted, value],
  );

  useEffect(() => {
    if (customer_id !== contact?.id) {
      if (customer_id === null) {
        setContact({ ...initState, ...value });

        resetDraft();
      } else {
        if (customer_id !== value?.remote_id) {
          if (JSON.stringify(value) === JSON.stringify({ id: undefined })) {
            setContact(initState);
            resetDraft();
          } else {
            let data = value;
            if (value?.is_child) {
              const phone =
                value?.parent?.phone_number || value?.parent?.phone_e164;
              data = { ...value?.parent, phone } || {};
            }
            setContact({ ...initState, ...data });
          }
        }
      }
    }
    setIsContact(isAdditionalContact);
  }, [value?.remote_id, customer_id, value?.id, isAdditionalContact]);

  // Creates customer record in FieldPulse application
  const createContact = useCallback(async () => {
    if (!validate() || saving) return { error: true };
    setSaving(true);
    let body = draft;

    if (body?.countryCode) {
      delete body.countryCode;
    }

    if (body?.last_name === null) {
      body.last_name = "";
    }

    if (body.display_name === null) {
      body.display_name = "";
    }

    if (!body?.main_location) {
      body.locations = [
        {
          id: null,
          object_id: null,
          object_type: "customer",
          title: "Main Location",
          address_1: body?.address_1,
          address_2: body?.address_2,
          city: body?.city,
          state: body?.state,
          zip_code: body?.zip_code,
          notes: "",
          is_main_location: true,
          order_index: 0,
          deleted_at: "",
        },
      ];
    }

    if (draft?.billing_address_1 && !draft?.has_different_billing_address) {
      body.has_different_billing_address = true;
    }

    const customerRes = await FieldPulse.createCustomer(body);
    if (!customerRes.error) {
      const customer = customerRes.response;
      // Create or Update Participant record in Engage
      await Engage.createParticipant({
        first_name:
          (customer?.account_type === ACCOUNT_TYPES[1].value
            ? customer.company_name
            : customer.first_name) || customer?.display_name,
        last_name:
          customer?.account_type === ACCOUNT_TYPES[1].value
            ? ""
            : customer.last_name,
        phone_number: customer.phone,
        phone_e164: customer.phone_e164,
        remote_id: customer.id,
        email: customer.email,
      });
      // End
      setContact(filterCustomerWithRelations(customer));
    } else {
      setError(customerRes.message);
    }
    setSaving(false);
    return customerRes;
  }, [
    draft,
    saving,
    validate,
    setError,
    setContact,
    setSaving,
    quickbooks_enabled,
    xero_enabled,
  ]);

  const updateContact = useCallback(
    (data) => {
      updateDraft(data);
    },
    [contact, updateDraft],
  );

  useEffect(() => {
    const getEmails = async () => {
      if (filter?.interaction_type === EMAIL_FILTER)
        await getEmailsByAddress(contact?.email);
    };
    getEmails();
  }, [
    getEmailsByAddress,
    contact?.email,
    selectedDirection,
    selectedMailBox,
    filter?.interaction_type,
  ]);

  // Updates customer record in FieldPulse application
  const saveContact = useCallback(
    async (engageParticipantId, remote_id) => {
      if (saving) return;
      setSaving(true);
      let body = { ...changed, display_name: draft?.display_name };
      if (changed?.assigned_to === "Unassigned") {
        body.assigned_to = null;
      }

      const address_fields = [
        "address_1",
        "address_2",
        "state",
        "city",
        "zip_code",
      ];

      const hasAddress = Object.keys(changed).some((key) =>
        address_fields.includes(key),
      );

      if (!draft?.main_location && hasAddress) {
        body.locations = [
          {
            id: null,
            object_id: remote_id ? remote_id : draft.id,
            object_type: "customer",
            title: "Main Location",
            address_1: draft?.address_1,
            address_2: draft?.address_2,
            city: draft?.city,
            state: draft?.state,
            zip_code: draft?.zip_code,
            notes: "",
            is_main_location: true,
            order_index: 0,
            deleted_at: "",
          },
        ];
      } else if (draft?.main_location && hasAddress) {
        try {
          await FieldPulse.updateLocation({
            ...draft?.main_location,
            address_1: draft?.address_1,
            address_2: draft?.address_2,
            city: draft?.city,
            state: draft?.state,
            zip_code: draft?.zip_code,
          });
        } catch (e) {
          console.error(e);
        }
      }

      if (draft?.billing_address_1 && !draft?.has_different_billing_address) {
        body.has_different_billing_address = true;
      }

      if (body?.last_name === null) {
        body.last_name = "";
      }

      if (body.display_name === null) {
        body.display_name = "";
      }

      const res = await FieldPulse.updateCustomer({
        id: remote_id ? remote_id : draft.id,
        ...body,
      });

      if (!res.error) {
        const customer = res.response;

        try {
          // Update Participant record in Engage
          await Engage.updateParticipant(engageParticipantId, {
            first_name:
              (customer?.account_type === ACCOUNT_TYPES[1].value
                ? customer.company_name
                : customer.first_name) || customer?.display_name,
            last_name:
              customer?.account_type === ACCOUNT_TYPES[1].value
                ? ""
                : customer.last_name,
            phone_number: customer.phone,
            phone_e164: customer.phone_e164,
            remote_id: customer.id,
            email: customer.email,
          });
        } catch (err) {
          console.log("[ContactContext:saveContact]", err);
        }

        setContact(res.response);
      } else {
        setError(res.message);
      }
      setSaving(false);
      return res;
    },
    [draft, saving, setSaving, setError, setContact],
  );

  // Updates customer record in FieldPulse application
  const deleteContact = useCallback(async () => {
    if (saving) return;
    setSaving(true);
    const res = await FieldPulse.deleteCustomer({ id: contact.id });
    if (res.error) {
      setError(message);
    }
    setSaving(false);
    return res;
  }, [contact, saving, setSaving, setError]);

  const resetBillAddress = () => {
    updateDraft({
      billing_address_1: null,
      billing_address_2: null,
      billing_state: null,
      billing_city: null,
      billing_zip_code: null,
    });
  };

  const resetContact = () => {
    updateDraft(initState);
  };

  useEffect(() => {
    if (customer_id) {
      getContact(customer_id);
    }
  }, [customer_id]);

  useEffect(() => {
    if (!loading && !loadingConversation && customer_id === contact?.id) {
      setCustomer(contact);
    }
  }, [
    contact?.id,
    customer_id,
    loading,
    loadingConversation,
    conversation?.id,
  ]);

  return (
    <ContactContext.Provider
      value={{
        contact: {
          ...contact,
          ...draft,
          ...phoneNumber,
        },
        changed,
        dirty,
        loading,
        saving,
        error,
        getContact,
        setContact,
        createContact,
        updateContact,
        saveContact,
        deleteContact,
        resetBillAddress,
        hasErrors,
        setHasErrors,
        resetContact,
        contactDeleted,
        isContact,
        setIsContact,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
}

export default ContactContext;
