export const ACCOUNT_TYPES = [
  {
    label: "Residential",
    value: "individual",
  },
  {
    label: "Commercial",
    value: "company",
  },
];
