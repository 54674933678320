import palette from "../styles/palette";

export const INVOICE_STATUS_ESTIMATE = 1;
export const INVOICE_STATUS_DRAFT = 2;
export const INVOICE_STATUS_INVOICED = 3;
export const INVOICE_STATUS_PAID = 4;
export const INVOICE_STATUS_PARTIALLY_PAID = 5;
export const INVOICE_STATUS_VOID = 6;

export const INVOICE_STATUS = [
  {
    value: INVOICE_STATUS_ESTIMATE,
    label: "Estimate",
    color: palette.yellow,
  },
  {
    value: INVOICE_STATUS_DRAFT,
    label: "Invoice Draft",
    color: "#F2BD22",
  },
  {
    value: INVOICE_STATUS_INVOICED,
    label: "Invoiced",
    color: "#58D0FF",
  },
  {
    value: INVOICE_STATUS_PAID,
    label: "Paid",
    color: "#72C501",
  },
  {
    value: INVOICE_STATUS_PARTIALLY_PAID,
    label: "Partially Paid",
    color: "#bff097",
    textColor: "#86cd30",
  },
  {
    value: INVOICE_STATUS_VOID,
    label: "Void",
    color: "#EE5555",
  },
];

export const INVOICE_HEADER_FILTERS = [
  {
    label: "All Invoices",
    value: "all",
  },
  {
    label: "My Invoices",
    value: "my",
  },
];

export const INVOICE_FILTERS = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Draft",
    value: "draft",
  },
  {
    label: "Invoiced",
    value: "invoiced",
  },
  {
    label: "Overdue",
    value: "past_due",
  },
  {
    label: "Partially Paid",
    value: "partially_paid",
  },
  {
    label: "Paid",
    value: "paid",
  },
  {
    label: "Void",
    value: "void",
  },
  {
    label: "Templates",
    value: "templates",
  },
];

export const INVOICE_LIST_VISIBILITY_HIDE = 0;
export const INVOICE_LIST_VISIBILITY_DISPLAY_ALL = 1;
export const INVOICE_LIST_VISIBILITY_DISPLAY_BY_USER = 2;
