import React from "react";
import { TouchableOpacity, Text, View } from "react-native";

import commonStyles from "../../styles/common";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import palette from "../../styles/palette";
import { ICONS_LIBRARY } from "../../constants/icons";

export default function CheckBox({
  checked,
  label,
  onChange,
  light = false,
  labelStyle = {},
  color = palette.label_grey,
}) {
  const checkedIcon = light
    ? ICONS_LIBRARY.SQUARE_CHECK_LIGHT
    : ICONS_LIBRARY.SQUARE_CHECK;
  const uncheckedIcon = light
    ? ICONS_LIBRARY.SQUARE_LIGHT
    : ICONS_LIBRARY.SQUARE;
  return (
    <TouchableOpacity
      style={[commonStyles.row, commonStyles.alignCenter]}
      onPress={onChange}
    >
      <View
        style={[
          commonStyles.row,
          commonStyles.alignCenter,
          { cursor: "pointer" },
        ]}
      >
        <FontAwesomeIcon
          icon={checked ? checkedIcon : uncheckedIcon}
          color={color}
        />

        {label && (
          <Text style={[commonStyles.ml4, { color }, labelStyle]}>{label}</Text>
        )}
      </View>
    </TouchableOpacity>
  );
}
