import React from "react";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import Icon from "react-native-vector-icons/Feather";
import palette from "~/styles/palette";
import Row from "../../../layouts/Row";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

export default function SolidButton({
  label,
  loading,
  color,
  labelColor,
  labelStyle,
  style,
  round = false,
  width,
  onPress,
  disabled = false,
  icon,
  mr = 0,
  ml = 0,
  mt = 0,
  lessPadding = false,
  faIcon,
  iconPosition = "left",
  subLabel,
  subLabelStyle,
  subLabelColor = palette.grey,
}) {
  return (
    <TouchableOpacity
      activeOpacity={disabled ? 1 : 0.8}
      onPress={disabled || loading ? null : onPress}
      style={styles.solid_button__touchable}
    >
      <View
        style={[
          styles.solid_button__container,
          color && { backgroundColor: color },
          round && { borderRadius: 40 },
          width && { width },
          disabled && styles.solid_button__container_disabled,
          { marginRight: mr, marginLeft: ml, marginTop: mt },
          color === palette.white && {
            borderColor: palette.light_grey,
            borderWidth: 1,
          },
          lessPadding && {
            paddingHorizontal: 4,
            paddingVertical: 2,
          },
          style,
        ]}
      >
        <Row center>
          {icon && !loading && iconPosition === "left" && (
            <Icon
              name={icon}
              size={18}
              color={
                labelColor
                  ? labelColor
                  : color === palette.white
                  ? palette.primary
                  : palette.white
              }
              style={{ marginRight: 8 }}
            />
          )}
          {faIcon && !loading && iconPosition === "left" && (
            <FontAwesomeIcon
              icon={faIcon}
              size={16}
              color={
                labelColor
                  ? labelColor
                  : color === palette.white
                  ? palette.primary
                  : palette.white
              }
              style={{ marginRight: 8 }}
            />
          )}
          {loading ? (
            <ActivityIndicator
              size="small"
              color={
                labelColor
                  ? labelColor
                  : color === palette.white
                  ? palette.primary
                  : palette.white
              }
            />
          ) : (
            <View style={styles.solid_button__label__container}>
              <Text
                numberOfLines={1}
                style={[
                  styles.solid_button__label,
                  color === palette.white && { color: palette.primary },
                  labelColor && { color: labelColor },
                  labelStyle,
                ]}
              >
                {label}
              </Text>
              {!!subLabel && (
                <Text
                  numberOfLines={1}
                  style={[
                    styles.solid_button__label,
                    color === palette.white && { color: palette.primary },
                    subLabelColor && { color: subLabelColor },
                    subLabelStyle,
                  ]}
                >
                  {subLabel}
                </Text>
              )}
            </View>
          )}
          {icon && !loading && iconPosition === "right" && (
            <Icon
              name={icon}
              size={18}
              color={
                labelColor
                  ? labelColor
                  : color === palette.white
                  ? palette.primary
                  : palette.white
              }
              style={{ marginLeft: 8 }}
            />
          )}
          {faIcon && !loading && iconPosition === "right" && (
            <FontAwesomeIcon
              icon={faIcon}
              size={16}
              color={
                labelColor
                  ? labelColor
                  : color === palette.white
                  ? palette.primary
                  : palette.white
              }
              style={{ marginLeft: 8 }}
            />
          )}
        </Row>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  solid_button__container: {
    borderRadius: 4,
    backgroundColor: palette.primary,
    paddingHorizontal: 16,
    paddingVertical: 8,
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    cursor: "pointer",
  },
  solid_button__container_disabled: {
    opacity: 0.5,
    cursor: "not-allowed",
  },
  solid_button__label: {
    fontSize: 15,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    color: "white",
    textAlign: "center",
  },
  solid_button__label__container: { alignItems: "center" },
  solid_button__touchable: { cursor: "auto" },
});
