import {
  DEFAULT_NETWORK_TEST_REPORT,
  NETWORK_TEST_FIELD_STATUS,
  NETWORK_TEST_FIELDS,
  NETWORK_TEST_THRESHOLD_MAP,
} from "../constants/networkTest";
import Engage from "../services/engage";
import { Model } from "./base";

export default class NetworkTest extends Model {
  get defaults() {
    return DEFAULT_NETWORK_TEST_REPORT;
  }

  extractValuableData() {
    const {
      callSid,
      edge,
      stats,
      totals,
      networkTiming,
      callQuality,
      isTurnRequired,
      selectedIceCandidatePairStats,
    } = this ?? {};

    const { mos, rtt, jitter } = stats || {};

    const mosAverage = mos?.average;
    const mosMax = mos?.max;
    const rttAverage = rtt?.average;
    const jitterAverage = jitter?.average;
    const jitterMax = jitter?.max;
    const bytesReceived = totals?.bytesReceived;
    const bytesSent = totals?.bytesSent;
    const packetsReceived = totals?.packetsReceived;
    const packetsSent = totals?.packetsSent;
    const packetsLostFraction = totals?.packetsLostFraction;
    const signalingDuration = networkTiming?.signaling?.duration;
    const iceDuration = networkTiming?.ice?.duration;
    const dtlsDuration = networkTiming?.dtls?.duration;
    const protocol =
      selectedIceCandidatePairStats?.localCandidate?.protocol?.toUpperCase();
    const networkType =
      selectedIceCandidatePairStats?.localCandidate?.networkType?.toUpperCase();

    return {
      callSid,
      edge,
      mosAverage,
      mosMax,
      rttAverage,
      jitterAverage,
      jitterMax,
      bytesReceived,
      bytesSent,
      packetsReceived,
      packetsSent,
      packetsLostFraction,
      signalingDuration,
      iceDuration,
      dtlsDuration,
      callQuality,
      isTurnRequired,
      protocol,
      networkType,
    };
  }

  getWarnings() {
    return this?.warnings?.map((warning) => ({
      name: warning?.name,
      message: warning?.description,
    }));
  }

  static getNetworkTestStatus(key, value) {
    const thresholds = NETWORK_TEST_THRESHOLD_MAP[key];
    if (thresholds) {
      for (let i = 0; i < thresholds.length; i++) {
        const check =
          key === NETWORK_TEST_FIELDS.MOS_AVERAGE ||
          key === NETWORK_TEST_FIELDS.MOS_MAX
            ? value >= thresholds[i]
            : value <= thresholds[i];

        if (check) {
          return i === 0
            ? NETWORK_TEST_FIELD_STATUS.EXCELLENT
            : i === 1
            ? NETWORK_TEST_FIELD_STATUS.GOOD
            : i === 2
            ? NETWORK_TEST_FIELD_STATUS.FAIR
            : NETWORK_TEST_FIELD_STATUS.POOR;
        }
      }
      return NETWORK_TEST_FIELD_STATUS.BAD;
    }
    return null;
  }

  static async saveNetworkTest(report, pushToken) {
    try {
      const networkTest = new NetworkTest(report);
      const data = networkTest.extractValuableData();
      await Engage.saveNetworkTest({
        ...data,
        device_push_token: pushToken,
      });
    } catch {}
  }
}
