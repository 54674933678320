import { useCallback, useContext, useEffect, useMemo } from "react";
import useTwilio from "../../hooks/useTwilio";
import { FlatList, ScrollView } from "react-native";
import IncomingCallModal from "../IncomingCallModal/index.web";
import ConversationContext from "../../contexts/ConversationContext";
import FieldPulse from "../../services/fieldpulse";
import Engage from "../../services/engage";
import { MAIN_WEBAPP_URLS } from "../../constants/app/index.web";
import { WebAppPostMessage } from "../../models/WebAppPostMessage";
import UserContext from "../../contexts/UserContext";
import { Contact } from "../../models/Contact";

export default function IncomingCallsPanel() {
  const {
    callInvites,
    acceptCall,
    endCall,
    rejectCallInvite,
    isMuted,
    toggleMute,
    activeCall,
    startCall,
  } = useTwilio();

  const { setParticipantFromCustomer, clearConversation, setParticipant } =
    useContext(ConversationContext);
  const { setLogoutError } = useContext(UserContext);

  const sortedCallInvites = useMemo(() => {
    if (Object.values(callInvites).length < 2) {
      return Object.values(callInvites);
    }
    return Object.values(callInvites).sort((a, b) => {
      return a?.timestamp - b?.timestamp;
    });
  }, [JSON.stringify(callInvites)]);

  const openConversation = async (id, isContact, property) => {
    try {
      clearConversation();

      const customer = isContact
        ? await Contact.getContact(id)
        : await FieldPulse.getCustomer(id);

      const isPrimaryPhone = property === "phone";

      if (!customer || customer.error) {
        console.error("openConversation error", customer?.error);
        return;
      }

      const customerId = customer?.response?.[isContact ? "customer_id" : "id"];
      const customerPhone = customer?.response?.[property];

      if (customerId && customerPhone) {
        const doesParticipantExists = await Engage.getParticipantByPhone({
          phone: customerPhone,
        });

        if (doesParticipantExists && doesParticipantExists.response?.id) {
          if (doesParticipantExists?.conversation === null) {
            await setParticipantFromCustomer(
              customer?.response,
              customerPhone,
              !isPrimaryPhone,
            );
          } else {
            await setParticipant(doesParticipantExists?.response);
          }
          return;
        }

        const parent = isContact
          ? await Contact.getContactParent(customerId)
          : null;

        if (isContact && parent?.error) {
          console.error("openConversation error", parent?.error);
          return;
        }

        if (isContact && parent?.response) {
          const parentParticipantRes = await Engage.getParticipantByPhone({
            phone: parent?.response?.phone,
          });

          if (
            !parentParticipantRes ||
            !parentParticipantRes.response ||
            parentParticipantRes.error
          ) {
            console.error(
              "openConversation error",
              parentParticipantRes?.error,
            );
            return;
          }

          const contact = Contact.createContactFromCustomer(
            customer?.response,
            parentParticipantRes?.response?.id,
          );
          const participant = await Contact.createContactInEngage(contact);

          if (participant && participant.response) {
            await setParticipant(participant?.response);
          } else {
            console.error("openConversation error", participant?.error);
          }
        } else {
          await setParticipantFromCustomer(
            customer?.response,
            customerPhone,
            !isPrimaryPhone,
          );
        }
      } else {
        console.error(
          "openConversation error",
          "No customer id or phone number",
          customerPhone,
        );
      }
    } catch (e) {
      console.error("openConversation error", e);
    }
  };

  const onLogoutUser = () => {
    setLogoutError(true);
  };

  const handlePostMessage = useCallback(
    async (e) => {
      if (MAIN_WEBAPP_URLS.includes(e?.origin)) {
        const payload = e?.data;
        if (!payload || !payload?.type) return;
        const webAppPostMessageEvent = new WebAppPostMessage(
          payload.type,
          payload,
        );
        if (!webAppPostMessageEvent.isMainAppAuthEvent)
          webAppPostMessageEvent.handleEvent(
            e,
            startCall,
            acceptCall,
            endCall,
            rejectCallInvite,
            toggleMute,
            openConversation,
            () => {}, // handled in useMainAppAuthShare hook
            onLogoutUser,
          );
      }
    },
    [
      startCall,
      acceptCall,
      endCall,
      rejectCallInvite,
      toggleMute,
      openConversation,
      onLogoutUser,
    ],
  );

  useEffect(() => {
    window.addEventListener("message", handlePostMessage);

    return () => window.removeEventListener("message", handlePostMessage);
  }, [
    JSON.stringify(callInvites),
    JSON.stringify(activeCall),
    isMuted,
    handlePostMessage,
  ]);

  if (sortedCallInvites?.length === 0) return null;

  return (
    <ScrollView
      style={{
        position: "absolute",
        right: 10,
        zIndex: 1000,
        top: 70,
        width: "25%",
        maxWidth: 300,
      }}
    >
      <FlatList
        data={sortedCallInvites}
        keyExtractor={(item) => item?.call?.parameters?.CallSid}
        renderItem={(props) => (
          <IncomingCallModal
            callItem={props?.item?.call}
            index={props?.index}
          />
        )}
      />
    </ScrollView>
  );
}
