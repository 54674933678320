import { Model } from "./base";

const FIELDS = [
  "_id",
  "audio",
  "author._id",
  "author.id",
  "author.initials",
  "author.name",
  "author.remote_id",
  "author.type",
  "created_at",
  "createdAt",
  "direction",
  "id",
  "image",
  "images",
  "interactable_type",
  "meta.call_recording",
  "meta.voicemail",
  "missed",
  "note",
  "pending",
  "quickReplies",
  "received",
  "sent",
  "status",
  "system",
  "text",
  "thumbnail",
  "video",
];

export default class GiftedChatMessage extends Model {
  constructor(attributes) {
    super(attributes, FIELDS);
    this.createdAt = attributes.created_at;
  }
}
