import React from "react";
import { View } from "react-native";

import {
  ColCls,
  Col1,
  Col2,
  Col3,
  Col3_5,
  Col4,
  Col5,
  Col6,
  Col7,
  Col8,
  Col9,
  Col10,
  Col11,
  Col12,
  Layouts,
} from "./style.js";

const classMap = {
  1: Col1,
  2: Col2,
  3: Col3,
  3.5: Col3_5,
  4: Col4,
  5: Col5,
  6: Col6,
  7: Col7,
  8: Col8,
  9: Col9,
  10: Col10,
  11: Col11,
  12: Col12,
};

const Col = ({
  children,
  style,
  grow = 1,
  size, // Column with class for flex grid. Acceptable options: <number> 1...12
  padding, // Style shortcut for universal padding
  margin, // Style shortcut for universal margin
  width, // Style shortcut for universal width
  maxWidth,
  minWidth,
  center, // Centers children in parent flex direction
  centered, // Centers children vertically and horizontally
  left, // Aligns left all children in parent
  right, // Aligns right all children in parent
  leftCenter, // Aligns left and centers vertically all children in parent
  rightCenter, // Aligns right and centers vertically all children in parent
  showBox,
  ...rest
}) => {
  let sizeCls = [];

  const { mb, mt, ml, mr, pb, pr, pl, pt } = rest;

  const additionalStyles = {
    padding: padding || null,
    margin: margin || null,
    paddingBottom: pb,
    paddingTop: pt,
    paddingLeft: pl,
    paddingRight: pr,
    marginBottom: mb,
    marginTop: mt,
    marginLeft: ml,
    marginRight: mr,
  };

  if (size && classMap[`${size}`]) sizeCls.push([classMap[`${size}`]]);

  return (
    <View
      style={[
        ColCls,
        ...sizeCls,
        width ? { width, flex: 0 } : {},
        maxWidth ? { maxWidth } : {},
        minWidth ? { minWidth } : {},
        center ? Layouts.alignCenter : {},
        centered ? { ...Layouts.alignCenter, ...Layouts.verticalCenter } : {},
        left ? Layouts.alignLeft : {},
        right ? Layouts.alignRight : {},
        leftCenter && Layouts.alignLeft,
        leftCenter && Layouts.verticalCenter,
        rightCenter && Layouts.alignRight,
        rightCenter && Layouts.verticalCenter,
        grow && { flexGrow: grow },
        showBox
          ? { backgroundColor: typeof showBox === "string" ? showBox : "red" }
          : {},
        additionalStyles,
        style,
      ]}
      {...rest}
    >
      {children}
    </View>
  );
};

export default Col;
