import React, { useContext, useState } from "react";
import {
  FlatList,
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
} from "react-native";

import Row from "~/layouts/Row";
import Col from "~/layouts/Col";
import AnimatedLogo from "~/components/Logo/AnimatedLogo";
import Heading from "~/components/Heading/";
import IconButton from "~/components/Buttons/IconButton";

import UserContext from "~/contexts/UserContext";
import NumbersContext from "~/contexts/NumbersContext";
import palette from "~/styles/palette";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { ScrollView } from "react-native-web";
import PhoneNumbers from "../../models/PhoneNumbers";
import SearchBar from "../Bars/SearchBar/index.web";
import { Strings } from "../../constants/strings";
import commonStyles from "../../styles/common";

export default function NumbersList({ onNumberPress }) {
  const { numbers, loading, getNumbers } = useContext(NumbersContext);
  const [search, setSearch] = useState("");

  const phoneNumbers = new PhoneNumbers(numbers);
  const numbersFiltered = phoneNumbers.search(search || "");
  const sortedPhoneNumbers = phoneNumbers.orderAlphabetically(
    numbersFiltered || [],
  );

  const renderSearchNumber = () => {
    if (numbers && numbers.length > 0) {
      return <SearchNumber search={search} setSearch={setSearch} />;
    }
    return null;
  };

  return (
    <View style={[styles.numbers_list__container]}>
      {renderSearchNumber()}
      <FlatList
        style={commonStyles.flex}
        refreshing={loading}
        onRefresh={getNumbers}
        ListEmptyComponent={NoNumbersComponent}
        data={sortedPhoneNumbers}
        renderItem={renderNumberItem(onNumberPress)}
        keyExtractor={(item) => `${item.id}`}
      />
    </View>
  );
}

const SearchNumber = ({ search, setSearch }) => {
  return (
    <View style={styles.number_list__search_container}>
      <SearchBar
        searchTerm={search}
        setSearchTerm={setSearch}
        placeholder={Strings.SEARCH_NUMBER_PLACE_HOLDER}
        inputStyle={styles.number_list__search_input}
        iconStyle={styles.number_list__search_icon}
        style={styles.number_list__search_bar}
      />
    </View>
  );
};

const NumberItem = ({ item, onPress }) => {
  const { user } = useContext(UserContext);
  const onNumberPress = () => {
    if (onPress) {
      onPress(item);
    }
  };

  const isMain = user.assigned_number?.id === item.id;

  return (
    <TouchableOpacity onPress={onNumberPress}>
      <View style={[styles.numbers_list__item_container]}>
        <Row pl={32} pr={16} fill>
          <Col leftCenter grow={2}>
            <Row leftCenter style={{ alignItems: "center" }}>
              <View>
                <Text
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={[styles.numbers_list__item_text]}
                >
                  {item.friendly_name}
                </Text>

                <Text
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={[styles.numbers_list__item_friendly_name_text]}
                >
                  {item.phone_number}
                </Text>
              </View>
            </Row>
          </Col>

          <Col style={{ height: "100%" }}>
            <Row style={{ height: "100%" }} rightCenter>
              {isMain && (
                <IconButton
                  faPro
                  faProIcon={faCheck}
                  height={25}
                  width={25}
                  iconSize={16}
                  color={palette.success}
                />
              )}
            </Row>
          </Col>
        </Row>
      </View>
    </TouchableOpacity>
  );
};

const renderNumberItem =
  (onNumberPress) =>
  ({ item }) => {
    return <NumberItem item={item} onPress={onNumberPress} />;
  };

const NoNumbersComponent = () => {
  return (
    <View style={[styles.no_conversations_component]}>
      <Heading size={4} color={palette.grey}>
        No numbers found
      </Heading>
    </View>
  );
};

const NumbersLoadingIndicator = () => {
  return (
    <View style={[styles.numbers_list__loading_indicator_container]}>
      <View dataSet={{ cls: "logo-loader" }}>
        <AnimatedLogo height={60} width={60} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  numbers_list__container: {
    flex: 1,
    borderColor: palette.light_grey,
  },
  numbers_list__loading_indicator_container: {
    flex: 1,
    backgroundColor: "#fbfbfb",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  numbers_list__item_control_bar_container: {
    maxWidth: "100%",
  },
  numbers_list__item_text: {
    fontSize: 16,
    fontFamily: "OpenSans_600SemiBold",
  },
  numbers_list__item_friendly_name_text: {
    paddingTop: 8,
  },
  numbers_list__item_label_text: {
    // fontFamily: "acumin-pro, san-serif",
    fontSize: 18,
    color: palette.grey,
  },
  numbers_list__item_subtext: {
    // fontFamily: "acumin-pro, san-serif",
    fontWeight: "600",
    fontSize: 16,
    color: palette.grey,
  },
  numbers_list__item_label_chevron: {
    padding: 5,
  },
  numbers_list__item_sort_bar_container: {
    backgroundColor: palette.white,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: palette.light_grey,
    height: 75,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.12,
    shadowRadius: 2.62,
    elevation: 4,
    zIndex: 10,
  },
  numbers_list__item_control_bar_pagination_controls: {
    borderRadius: 4,
  },
  numbers_list__item_control_bar_pagination_text: {
    fontSize: 18,
  },
  numbers_list__item_container: {
    backgroundColor: palette.white,
    borderBottomColor: palette.light_grey,
    borderBottomWidth: 1,
    borderColor: palette.light_grey,
    paddingVertical: 5,
  },
  numbers_list__item_main_badge_container: {
    backgroundColor: palette.lighter_grey,
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 4,
    marginLeft: 16,
  },
  no_conversations_component: {
    flex: 1,
    paddingTop: 128,
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
  },
  number_list__search_container: {
    borderBottomWidth: 1,
    borderBottomColor: palette.light_grey,
    backgroundColor: palette.lighter_grey,
  },
  number_list__search_icon: {
    top: 18,
  },
  number_list__search_input: { borderRadius: 8 },
  number_list__search_bar: {
    paddingBottom: 10,
    paddingTop: 10,
  },
});
