export const RING_DURATION_KEY = "ring_duration";
export const RING_DURATION_DEFAULT = 30;
export const CALL_ROUTING_RING_DURATION_KEY = "sequential_ring_duration";

export const RING_DURATION_OPTIONS = [
  { value: 5, label: "5 Seconds" },
  { value: 10, label: "10 Seconds" },
  { value: 15, label: "15 Seconds" },
  { value: 20, label: "20 Seconds" },
  { value: 30, label: "30 Seconds" },
  { value: 40, label: "40 Seconds" },
  { value: 45, label: "45 Seconds" },
  { value: 50, label: "50 Seconds" },
  { value: 60, label: "60 Seconds" },
];
