import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { StyleSheet, View, Text, ScrollView, Dimensions } from "react-native";

import Screen from "~/layouts/Screen/index.web";
import Row from "~/layouts/Row";
import Col from "~/layouts/Col";

import CallLogList from "~/components/CallLogList/index.web";
import palette from "~/styles/palette";
import UserContext from "~/contexts/UserContext";
import NumbersContext from "~/contexts/NumbersContext";
import { CallsContext } from "../../contexts/CallsContext";
import MenuBar from "../../components/MenuBar/index.web";
import ParticipantDetails from "../../components/Participant/ParticipantDetails/index.web";
import ConversationContext from "../../contexts/ConversationContext";
import {
  Switch,
  Route,
  Redirect,
  matchPath,
  useHistory,
} from "react-router-dom";
import ConversationList from "../../components/ConversationList/index.web";
import VoicemailList from "../../components/VoicemailsList/index.web";
import CustomersList from "../../components/CustomersList/index.web";
import ComposeScreen from "../ComposeScreen/index.web";
import ConversationScreen from "../ConversationScreen/index.web";
import { CallLogDetails } from "../CallsScreen/index.web";
import { VoicemailsContext } from "../../contexts/VoicemailsContext";
import { VoicemailDetails } from "../VoicemailsScreen/index.web";
import ThreadsContext from "../../contexts/ThreadsContext";
import { pages, pagesObject } from "../../constants/pages";
import LoadingScreen from "./LoadingScreen/index.web";
import CommanModal from "../../modals/CommanModal/index.web";
import platform from "platform";
import canAutoPlay from "can-autoplay";
import { Sound, Device } from "@twilio/voice-sdk/";

export default function UserDashboardScreen() {
  const history = useHistory();
  const [panelVisible, setPanelVisible] = useState(false);
  const {
    cacheLoading: loadingUser,
    user,
    fpUser,
    isUserOutsideWorkingHours,
    isWorkingHoursAlertVisible,
    setIsWorkingHoursAlertVisible,
  } = useContext(UserContext);
  const { loading: loadingNumbers, initialLoading } =
    useContext(NumbersContext);
  const modalDataListRef = useRef([]);
  const [modalDataList, setModalDataList] = useState([]);
  const [modalData, setModalData] = useState(null);

  const match = useMemo(() => {
    let res = "";
    pages.forEach((to) => {
      let isMatch = matchPath(history?.location.pathname, {
        path: to,
        exact: false,
        strict: false,
      });
      if (isMatch) {
        if (
          to?.slice(1) === pagesObject.CUSTOMERS &&
          !fpUser?.can_view_customer_list
        ) {
          return;
        }
        res = to;
        return;
      }
    });

    if (!res) return history.push("/messages");
    return res.slice(1);
  }, [history.location.pathname, fpUser?.can_view_customer_list]);

  useEffect(() => {
    if (
      !modalDataListRef.current.length &&
      !initialLoading &&
      !loadingUser &&
      !loadingNumbers &&
      user
    ) {
      checkMicrophonePermission().then(() =>
        checkIncomingCallRingingSound().then(() =>
          checkAssignedNumberAndWorkingHoursAndFinalize(),
        ),
      );
    }
  }, [initialLoading, loadingUser, loadingNumbers, user]);

  useEffect(() => {
    if (modalDataList.length > 0) {
      setModalData(modalDataList[0]);
    } else {
      setModalData(null);
    }
  }, [modalDataList]);

  const checkMicrophonePermission = async () => {
    let isPermitted = false;

    try {
      if (platform.name === "Firefox") {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        stream.getTracks().forEach((track) => track.stop());
        isPermitted = true;
      } else {
        const permissionStatus = await navigator.permissions.query({
          name: "microphone",
        });
        isPermitted = permissionStatus.state === "granted";
      }
    } catch (e) {}

    if (!isPermitted) {
      modalDataListRef.current.push("microphone");
    }
  };

  const checkIncomingCallRingingSound = async () => {
    if (platform.name === "Safari") {
      const res = await canAutoPlay.audio({ timeout: 1000 });
      if (!res.result) {
        modalDataListRef.current.push("incomingCallSound");
      }
    }
  };

  const checkAssignedNumberAndWorkingHoursAndFinalize = () => {
    if (!user.assigned_number) {
      modalDataListRef.current.push("assignNumber");
    }
    if (isUserOutsideWorkingHours && isWorkingHoursAlertVisible) {
      modalDataListRef.current.push("workingHours");
    }

    // Finalize
    if (modalDataListRef.current.length > 0) {
      setModalDataList(modalDataListRef.current);
    }
  };

  const handleEnableIncomingCallSound = () => {
    // In Safari, the audio auto-play is disabled by default.
    // See browser menu: Safari > Settings for <the website name> > Auto-Play > Stop Media with Sound
    // We can only play certain audio after the user interacts with the page.
    // For this, we will show a modal dialog.
    // When user clicks on the Allow button, we create this `Sound` object.
    // Internally, this `Sound` object will be played automatically with muted sound,
    // thus we don't need to invoke the `play()` function here.
    new Sound(
      "incoming",
      "https://sdk.twilio.com/js/client/sounds/releases/1.0.0/incoming.mp3?cache=2.11.1",
      {
        audioContext: Device.audioContext,
        shouldLoop: false,
      },
    );

    const newModalDataList = modalDataList.filter(
      (e) => e !== "incomingCallSound",
    );
    setModalDataList(newModalDataList);
  };

  const handleModalClose = (modalData) => {
    const newModalDataList = modalDataList.filter((e) => e !== modalData);
    setModalDataList(newModalDataList);
  };

  const alertModal = useMemo(() => {
    switch (modalData) {
      case "microphone": {
        return (
          <CommanModal
            isVisisble={modalData}
            message="Microphone permission is not granted. Enable microphone access for Engage in your browser for voice functionality."
            closeable
            onClose={() => handleModalClose(modalData)}
          />
        );
      }

      case "incomingCallSound": {
        return (
          <CommanModal
            isVisisble={modalData}
            message="Incoming call is currently muted. Click here to enable it."
            buttonTitle="Allow"
            buttonAction={handleEnableIncomingCallSound}
          />
        );
      }

      case "assignNumber": {
        return (
          <CommanModal
            isVisisble={modalData}
            message="You currently have no assigned phone lines. Click here to assign a phone line to your accounts."
            buttonAction={() => {
              history.push("settings");
              handleModalClose(modalData);
            }}
            buttonTitle="Go To Settings"
            closeable
            onClose={() => handleModalClose(modalData)}
          />
        );
      }

      case "workingHours": {
        return (
          <CommanModal
            isVisisble={modalData}
            message="You are currently using Engage outside your set working hours. Please check your working hours and timezone settings."
            buttonAction={() => {
              history.push("settings/working-hours");
              handleModalClose(modalData);
            }}
            buttonTitle="Go To Settings"
            closeable
            onClose={() => {
              setIsWorkingHoursAlertVisible(false);
              handleModalClose(modalData);
            }}
          />
        );
      }

      default:
        return null;
    }
  }, [modalData]);

  return (
    <Screen>
      <View style={[styles.container]}>
        <LoadingScreen
          loading={loadingUser || (loadingNumbers && initialLoading)}
        />
        {alertModal}
        <Col>
          <Row style={[styles.inner]}>
            <ListPanel match={match} />
            <CenterPanel
              match={match}
              panelVisible={panelVisible}
              setPanelVisible={setPanelVisible}
            />
            <RightPanel
              match={match}
              panelVisible={panelVisible}
              setPanelVisible={setPanelVisible}
            />
            <NoConversationBackground />
            <Switch>
              <Route path="/messages/:id" component={ConversationFallBack} />
            </Switch>
          </Row>
        </Col>
      </View>
    </Screen>
  );
}

const ListPanel = ({ match }) => {
  const { fpUser, canSeeCustomerContact } = useContext(UserContext);

  const renderList = useCallback(() => {
    if (match === pagesObject.CUSTOMERS && fpUser?.can_view_customer_list)
      return <CustomersList showContact={canSeeCustomerContact} />;

    switch (match) {
      case pagesObject.MESSAGES:
        return <ConversationList />;
      case pagesObject.CALLS:
        return <CallLogList />;
      case pagesObject.VOICEMAILS:
        return <VoicemailList />;
      default:
        return null;
    }
  }, [match, fpUser?.can_view_customer_list]);

  return (
    <Col size={3} style={[styles.listPanel]}>
      <MenuBar />
      {renderList()}
    </Col>
  );
};

const CenterPanel = ({ match, panelVisible, setPanelVisible }) => {
  const { conversation, newConversation } = useContext(ConversationContext);
  const { selected: selectedCall, setSelected: setSelectedCall } =
    useContext(CallsContext);
  const { selected: selectedVoicemail, setSelected: setSelectedVoicemail } =
    useContext(VoicemailsContext);

  useEffect(() => {
    if ((selectedCall || selectedVoicemail) && !panelVisible)
      setPanelVisible(true);
  }, [selectedCall, selectedVoicemail]);

  useEffect(() => {
    if (newConversation) {
      setSelectedCall(null);
      setSelectedVoicemail(null);
    }
  }, [newConversation]);

  return (
    <Col style={[styles.centerPanel]}>
      {conversation?.id ? <ConversationScreen /> : <ComposeScreen />}
      {panelVisible &&
        ((selectedCall && match === pagesObject.CALLS) ||
          (selectedVoicemail && match === pagesObject.VOICEMAILS)) && (
          <View
            style={{
              position: "absolute",
              zIndex: 50,
              top: 0,
              left: 0,
              backgroundColor: palette.white,
              width: "100%",
              height: "100%",
            }}
          >
            <Col>
              {selectedCall && match === pagesObject.CALLS && (
                <CallLogDetails setPanelVisible={setPanelVisible} />
              )}
              {selectedVoicemail && match === pagesObject.VOICEMAILS && (
                <VoicemailDetails setPanelVisible={setPanelVisible} />
              )}
            </Col>
          </View>
        )}
    </Col>
  );
};

const RightPanel = ({ panelVisible, setPanelVisible }) => {
  const { getConversation, newConversation } = useContext(ConversationContext);
  const { refreshThreads } = useContext(ThreadsContext);

  if (newConversation) return null;

  return (
    <Col size={3.5} style={[styles.rightPanel]}>
      <ParticipantDetails
        panelVisible={panelVisible}
        setPanelVisible={setPanelVisible}
        onUpdate={async () => {
          await getConversation();
          await refreshThreads();
        }}
      />
    </Col>
  );
};

const NoConversationBackground = () => {
  const { newConversation, conversation, loading, hasCustomer } =
    useContext(ConversationContext);

  if (newConversation || conversation?.id || loading) return null;

  if (!conversation?.id && hasCustomer)
    return (
      <Col
        style={{
          position: "absolute",
          height: "100%",
          width: "50%",
          top: 0,
          left: "25%",
          zIndex: 100,
          backgroundColor: palette.white,
          borderRightWidth: StyleSheet.hairlineWidth,
          borderColor: palette.light_grey,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            color: palette.label_grey,
          }}
        >
          No conversation Found
        </Text>
      </Col>
    );

  return (
    <Col
      style={{
        position: "absolute",
        height: "100%",
        width: "75%",
        top: 0,
        right: 0,
        zIndex: 100,
        backgroundColor: palette.white,
      }}
    >
      <View
        style={{
          position: "absolute",
          right: 0,
          bottom: 0,
        }}
      >
        <svg
          width="766.202914px"
          height="880.029669px"
          viewBox="0 0 766.202914 880.029669"
          version="1.1"
        >
          <defs>
            <rect
              id="path-1"
              x="0"
              y="0"
              width="1014.72636"
              height="1014.72636"
            ></rect>
          </defs>
          <g
            id="Engage----ACTIVE"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Engage-Re-Open"
              transform="translate(-1025.609536, -239.782781)"
            >
              <g id="Group" transform="translate(777.086093, 105.086093)">
                <g id="Mask"></g>
                <g
                  opacity="0.025"
                  mask="url(#mask-2)"
                  fill="#000000"
                  fillRule="nonzero"
                  id="Path"
                >
                  <g transform="translate(248.523444, 134.696689)">
                    <path d="M805.620132,416.313642 C781.026049,388.204503 755.324327,360.733951 728.514967,333.901987 C704.423841,309.810861 679.637616,286.567417 652.732185,262.696689 L636.473642,248.116556 C606.058808,222.177483 574.643709,197.136954 542.736954,173.384901 L532.361325,165.959205 C451.566125,107.051921 365.172636,56.2345596 274.428609,14.2410596 L243.522119,0 L195.933245,179.250861 L217.345695,189.423046 C356.280795,255.796556 484.975894,347.685298 599.836821,462.546225 C678.261319,540.616101 747.321471,627.55861 805.620132,721.614834 L805.620132,416.313642 Z"></path>
                    <path d="M340.819073,721.580927 C399.77375,780.309162 451.560926,845.817095 495.097219,916.734305 L693.963444,916.734305 L700.354967,915.03894 L683.89298,883.369536 C629.234437,778.256954 557.113642,680.502252 469.514172,592.919735 C381.914702,505.337219 284.176954,433.165563 179.064371,378.523974 L147.394967,362.061987 L99.9247682,540.651656 L119.099338,551.552848 C200.299591,598.024608 274.875625,655.214013 340.819073,721.580927"></path>
                    <path d="M246.709404,916.734305 C231.439823,898.390464 215.373422,880.775629 198.510199,863.889801 C162.040368,827.430993 122.100547,794.617199 79.2582781,765.914702 L45.9613245,743.671523 L0,916.734305 L246.709404,916.734305 Z"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </View>
    </Col>
  );
};

const ConversationFallBack = ({ match }) => {
  const { id } = match?.params || {};
  const { conversation, setConversationById } = useContext(ConversationContext);

  useEffect(() => {
    if (id && !conversation?.id) {
      setConversationById(id, true);
    }
  }, [id]);

  return null;
};

const styles = StyleSheet.create({
  greeting: {
    textAlign: "center",
    fontSize: 30,
  },
  text: {
    textAlign: "center",
    fontSize: 18,
    color: palette.grey,
  },
  listPanel: {
    borderRightWidth: 1,
    borderRightColor: palette.light_grey,
    height: "100%",
  },
  rightPanel: {},
  centerPanel: {
    borderRightWidth: 1,
    borderRightColor: palette.light_grey,
  },
  container: {
    flex: 1,
  },
  inner: {
    flex: 1,
  },
  link: {
    color: palette.primary_light,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 18,
  },
  texting: {
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 16,
  },
  text_label: {
    color: palette.label_grey,
    fontSize: 16,
  },
});
