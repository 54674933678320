export const getLabelForStatus = (status, type, taskCategory) => {
  if (type === "task" && taskCategory?.color) {
    return {
      value: "",
      label: { default: "-", AU: "-", NZ: "-" },
      color: taskCategory?.color,
      colorLight: taskCategory?.color,
    };
  }

  return {
    label: {
      default: status?.name ?? "",
      US: status?.name ?? "",
      AU: status?.name ?? "",
      NZ: status?.name ?? "",
    },
    color: status?.color ?? "white",
    colorLight: status?.color ? `${status.color}1A` : "white", // Use 10% opacity
    secondaryColorLight: status?.color ? `${status.color}2A` : "white", // Use 16.4% opacity
    statusType: status?.type ?? null,
  };
};

export const getLocationAddress = (address) => {
  const addressItems = [
    address.addressLine,
    address.city,
    address.state,
    address.postalCode,
  ].filter((e) => e != null && e !== "");

  return addressItems.join(", ");
};

export const getActualDuration = (duration) => {
  if (!isNaN(duration)) {
    const minutes = Math.floor((duration / 60) % 60);
    const hours = Math.floor(duration / (60 * 60));

    let actualDuration =
      hours > 0 ? hours + (hours !== 1 ? " hours " : " hour ") : "";
    actualDuration += minutes + (minutes !== 1 ? " minutes." : " minute.");

    return actualDuration;
  }

  return "0 minutes.";
};
