import React, {
  useCallback,
  useState,
  useContext,
  useEffect,
  useRef,
  useMemo,
} from "react";
import {
  Image,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import Row from "~/layouts/Row";
import Col from "~/layouts/Col";
import AdjustableScreen from "~/layouts/AdjustableScreen/index.web";
import palette from "~/styles/palette";
import Logo from "~/components/Logo";
import SolidButton from "~/components/Buttons/SolidButton/index";
import UserContext from "~/contexts/UserContext";
import { USER_LOGOUT_DUE_TO_EMAIL_MISMATCH_ERROR } from "../../constants/users";
import useToggle from "../../hooks/useToggle";
import * as Localization from "expo-localization";
import commonStyles from "../../styles/common";
import { GOOGLE_AUTH_CLIENT_ID } from "../../constants/app/index.web";
import { useHistory } from "react-router-dom";
import { loadLastMFAAsync } from "../../helpers/cache.native";
import FieldPulse from "../../services/fieldpulse";
import Engage from "../../services/engage";

export default function LoginScreen({ history }) {
  const { user, loggedIn } = useContext(UserContext);

  useEffect(() => {
    if (loggedIn) {
      history.push("/messages");
    }
  }, [user]);

  return (
    <AdjustableScreen>
      <Row flex>
        <Col center pl={16} pr={16} pt={16}>
          <View style={{ height: 600 }}>
            <View style={[styles.login_screen__logo_container]}>
              <Logo height={75} width={75} />
            </View>
            <View style={{ flexGrow: 1 }}>
              <Login />
            </View>
          </View>
        </Col>
        <Col style={[styles.right_panel]}></Col>
      </Row>
    </AdjustableScreen>
  );
}

const Login = () => {
  const {
    updateUser,
    setWebappToken,
    logoutError,
    setLogoutError,
    setMfaTempToken,
    setQRManualCode,
    setQRCode,
    setFpUser,
  } = useContext(UserContext);
  const history = useHistory();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const passwordInputRef = useRef();
  const [lastTwoFactorAuth, setLastTwoFactorAuth] = useState(null);
  const [googleSignInAvailable, setGoogleSignInAvailable] = useState(false);

  const onLogin = useCallback(
    (googleIdToken) => async () => {
      setLoading(true);
      setError(null);
      setLogoutError(false);

      let platform;
      if (googleIdToken) {
        platform = "engage_web";
      }
      const res = await Engage.login({
        email,
        password,
        google_sso_token: googleIdToken,
        platform,
        timezone: Localization.timezone,
        last_mfa: lastTwoFactorAuth,
      });

      if (res.error) {
        if (res.error_code === 15 || res.error_code === 16) {
          setMfaTempToken(res?.mfa_response?.token);

          if (res.error_code === 15) {
            // User needs to setup 2FA
            setQRManualCode(res?.mfa_response?.qrManualCode);
            setQRCode(res?.mfa_response?.qrCode);
            // navigate to MFA setup
            history.push("/mfa-setup");
          } else {
            // navigate to MFA verify
            history.push("/mfa-verify");
          }
        } else if (!res.error_code) {
          setError(
            res.response || res.message || res.details?.[0] || "Login failed",
          );
          setPassword("");
        }
      } else {
        FieldPulse.token(res?.apiToken);
        Engage.token(res?.token);
        updateUser(res.response);
        setWebappToken(res?.apiToken);
        if (res?.fpRes?.response) {
          setFpUser(res?.fpRes?.response);
        }
      }
      setLoading(false);
    },
    [
      email,
      password,
      updateUser,
      setError,
      setPassword,
      setQRManualCode,
      setMfaTempToken,
      lastTwoFactorAuth,
    ],
  );

  const errorMessage = useMemo(() => {
    if (!!error) return error;
    if (logoutError) return USER_LOGOUT_DUE_TO_EMAIL_MISMATCH_ERROR;
    return null;
  }, [error, logoutError]);

  useEffect(() => {
    if (google?.accounts?.id) {
      setGoogleSignInAvailable(true);
    }
  }, [google?.accounts]);

  const loadLastMFA = async () => {
    const lastMFA = await loadLastMFAAsync();
    setLastTwoFactorAuth(lastMFA);
  };

  useEffect(() => {
    loadLastMFA();
  }, []);

  return (
    <View style={[styles.login_screen__login_container]}>
      <View style={[styles.login_screen__title_container]}>
        <Text style={[styles.login_screen__title]}>
          Sign in to your account
        </Text>
      </View>
      <View>
        <Text style={[styles.login_screen__error_message]}>{errorMessage}</Text>
      </View>
      <View style={[styles.login_screen__input_container]}>
        <View style={[styles.login_screen__input_label_container]}>
          <Text style={[styles.login_screen__input_label]}>Email</Text>
        </View>
        <TextInput
          textContentType="emailAddress"
          value={email}
          style={[styles.login_screen__input]}
          onChangeText={setEmail}
          onKeyPress={(e) =>
            e.nativeEvent.key === "Enter"
              ? passwordInputRef?.current?.focus()
              : null
          }
        />
      </View>
      <View style={[styles.login_screen__input_container]}>
        <View style={[styles.login_screen__input_label_container]}>
          <Text style={[styles.login_screen__input_label]}>Password</Text>
        </View>
        <TextInput
          name="password"
          value={password}
          secureTextEntry
          style={[styles.login_screen__input]}
          onChangeText={setPassword}
          onKeyPress={(e) =>
            e.nativeEvent.key === "Enter" ? onLogin()() : null
          }
          ref={passwordInputRef}
        />
      </View>

      <View style={[styles.login_screen__input_container]}>
        <SolidButton
          textContentType="password"
          label="Login"
          loading={loading}
          color={palette.primary}
          labelStyle={{ fontSize: 18 }}
          style={{ borderRadius: 3 }}
          onPress={onLogin()}
        />
      </View>
      <LoginSeparator visible={googleSignInAvailable} />
      <GoogleSignIn
        visible={googleSignInAvailable}
        onLogin={onLogin}
        disabled={loading}
      />
    </View>
  );
};

const LoginSeparator = ({ visible }) => {
  if (!visible) return null;
  return (
    <Row style={commonStyles.gap} pl={8} pr={8} mt={8} mb={8} center>
      <Col center size={6}>
        <View style={styles.login_screen__divider} />
      </Col>
      <Col center size={1}>
        <Text style={styles.login_screen__divider_label}>or</Text>
      </Col>
      <Col center size={6}>
        <View style={styles.login_screen__divider} />
      </Col>
    </Row>
  );
};

const GoogleSignIn = ({ visible, onLogin, disabled }) => {
  const [hovered, setHovered] = useToggle(false);

  const onGoogleSignInPress = useCallback(() => {
    if (google?.accounts?.id) {
      const googleApi = google;
      googleApi.accounts.id.initialize({
        auto_select: false,
        cancel_on_tap_outside: true,
        ux_mode: "popup",
        client_id: GOOGLE_AUTH_CLIENT_ID,
        callback: async (data) => {
          if (data.credential) await onLogin(data.credential)();
        },
      });
      const container = document.createElement("div");
      container.style.display = "none";
      if (container) {
        googleApi.accounts.id.renderButton(container, {
          type: "icon",
        });
        const googleLoginWrapperButton =
          container.querySelector("div[role=button]");
        googleLoginWrapperButton.click();
      }
    }
  }, [google.accounts, onLogin]);

  if (!visible) return null;

  return (
    <View
      style={[
        styles.login_screen__google_btn_container,
        { opacity: disabled ? 0.8 : 1 },
      ]}
      onMouseEnter={setHovered}
      onMouseLeave={setHovered}
    >
      <TouchableOpacity onPress={onGoogleSignInPress} disabled={disabled}>
        <View
          style={[
            styles.login_screen__google_btn,
            {
              boxShadow: hovered && !disabled ? palette.box_shadow : "none",
              cursor: disabled ? "not-allowed" : "pointer",
            },
          ]}
        >
          <Image
            source={require("~/assets/google-icon.png")}
            style={{ width: 24, height: 24 }}
          />
          <Text style={styles.login__screen_sign_in_with_google_label}>
            Sign in with Google
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  right_panel: {
    backgroundColor: palette.primary,
  },
  login_screen__logo_container: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  login_screen__logo: {
    height: 45,
    width: 45,
  },
  login_screen__login_container: {
    width: 450,
    marginTop: -50,
  },
  login_screen__title_container: {
    marginBottom: 24,
    textAlign: "center",
  },
  login_screen__title: {
    fontSize: 32,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    textAlign: "center",
  },
  login_screen__input_label_container: {
    padding: 8,
  },
  login_screen__input_label: {
    fontSize: 16,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
  },
  login_screen__input: {
    backgroundColor: palette.lightest_grey,
    borderColor: palette.light_grey,
    borderWidth: 1,
    paddingVertical: 12,
    paddingHorizontal: 12,
    fontSize: 16,
    fontWeight: "500",
    fontFamily: "OpenSans_600SemiBold",
    borderRadius: 4,
  },
  login_screen__input_container: {
    padding: 8,
  },
  login_screen__error_message: {
    color: palette.danger,
    textAlign: "center",
  },
  login_screen__google_btn: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: 50,
    whiteSpace: "nowrap",
    outline: "none",
    borderRadius: 3,
    border: "1px solid lightgrey",
    backgroundColor: "white",
    flexDirection: "row",
    gap: 10,
    transition: "box-shadow 0.3s",
  },
  login_screen__sign_in_with_google_label: {
    fontSize: 17,
    lineHeight: 24,
    color: palette.dark_blueish_grey,
    fontFamily: "OpenSans_700Bold",
  },
  login_screen__google_btn_container: {
    padding: 8,
  },
  login_screen__divider: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: palette.light_grey,
    width: "100%",
  },
  login_screen__divider_label: {
    marginBottom: 2,
    fontFamily: "OpenSans_600SemiBold",
    color: palette.primary,
  },
});
