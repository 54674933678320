import { faCheck, faLink } from "@fortawesome/pro-regular-svg-icons";
import IconButton from "../IconButton";
import { useEffect, useMemo, useState } from "react";
import palette from "../../../styles/palette";
import Clipboard from "@react-native-clipboard/clipboard";

const CopyButton = ({ text, icon = faLink, ...iconProps }) => {
  const [copied, setCopied] = useState(false);

  const onPressHandler = async () => {
    Clipboard.setString(text);
    setCopied(() => true);
  };

  const copyParams = useMemo(() => {
    if (copied) {
      return {
        round: true,
        color: palette.green,
      };
    }
    return {
      iconColor: palette.grey,
    };
  }, [copied]);

  useEffect(() => {
    let t;
    if (copied) {
      t = setTimeout(() => {
        setCopied(() => false);
      }, 300);
    }

    return () => clearTimeout(t);
  }, [copied]);

  return (
    <IconButton
      faPro
      faProIcon={copied ? faCheck : icon}
      onPress={onPressHandler}
      iconSize={18}
      width={24}
      height={24}
      {...copyParams}
      {...iconProps}
    />
  );
};

export default CopyButton;
