// in App.js
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlay,
  faPause,
  faGrid,
  faPlus,
  faStar,
  faTriangleExclamation,
  faGear,
  faVolume,
  faRightToBracket,
  faCircle,
  faShare,
  faFlag as faFlagSolid,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faEdit,
  faCheck,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faChevronLeft,
  faXmark,
  faCircleInfo,
  faHashtag,
  faArrowLeft,
  faArrowRight,
  faLoader,
  faComments,
  faChevronCircleLeft,
  faChevronCircleRight,
  faSquare,
  faSquareCheck,
  faBuilding,
  faUser,
  faSearch,
  faPhone,
  faTrash,
  faVoicemail,
  faMicrophone,
  faClock,
  faAddressBook,
  faPlay as faPlayRegular,
  faGear as faGearRegular,
  faImage,
  faPaperPlane,
  faAsterisk,
  faComment,
  faPaste,
  faMicrophoneSlash,
  faEnvelope,
  faPen,
  faPhoneMissed,
  faPhoneIncoming,
  faPhoneOutgoing,
  faVolume as faVolumeRegular,
  faArrowUpRightFromSquare,
  faFlag as faFlagRegular,
  faBan,
  faPause as faPauseRegular,
  faTimer,
  faIdCard,
  faMessages as faMessagesRegular,
  faArrowDownToLine,
  faFile,
  faHeadphones,
  faLink,
  faEye,
  faEyeSlash,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faAddressCard as faAddressCardLight,
  faFilterList as faFilterListLight,
  faImage as faImageLight,
  faNoteSticky as faNoteStickyLight,
  faPhone as faPhoneLight,
  faTextSize as faTextSizeLight,
  faIdCard as faIdCardLight,
  faEnvelope as faEnvelopeLight,
  faBuilding as faBuildingLight,
  faLoader as faLoaderLight,
  faUserPlus as faUserPlusLight,
  faLayerGroup as faLayerGroupLight,
  faLocationDot as faLocationDotLight,
  faSquare as faSquareLight,
  faSquareCheck as faSquareCheckLight,
  faPlus as faPlusLight,
  faCopy,
} from "@fortawesome/pro-light-svg-icons";
import { faPaperPlane as faPaperPlaneDuotone } from "@fortawesome/pro-duotone-svg-icons";

export default function useFontAwesomeLibrary() {
  library.add(
    faPlay,
    faPause,
    faGrid,
    faPlus,
    faEdit,
    faCheck,
    faChevronDown,
    faChevronRight,
    faChevronUp,
    faChevronLeft,
    faXmark,
    faCircleInfo,
    faHashtag,
    faArrowLeft,
    faArrowRight,
    faLoader,
    faComments,
    faChevronCircleLeft,
    faChevronCircleRight,
    faStar,
    faSquare,
    faSquareCheck,
    faBuilding,
    faBuildingLight,
    faUser,
    faSearch,
    faPhone,
    faTrash,
    faTriangleExclamation,
    faVoicemail,
    faMicrophone,
    faClock,
    faAddressBook,
    faGear,
    faPlayRegular,
    faGearRegular,
    faImage,
    faPaperPlane,
    faAsterisk,
    faComment,
    faPaste,
    faMicrophoneSlash,
    faVolume,
    faRightToBracket,
    faEnvelope,
    faCircle,
    faPen,
    faAddressCardLight,
    faFilterListLight,
    faImageLight,
    faNoteStickyLight,
    faPhoneLight,
    faTextSizeLight,
    faPaperPlaneDuotone,
    faPhoneMissed,
    faPhoneIncoming,
    faPhoneOutgoing,
    faVolumeRegular,
    faArrowUpRightFromSquare,
    faShare,
    faFlagRegular,
    faFlagSolid,
    faBan,
    faPauseRegular,
    faTimer,
    faIdCardLight,
    faIdCard,
    faEnvelopeLight,
    faLoaderLight,
    faUserPlusLight,
    faLayerGroupLight,
    faLocationDotLight,
    faSquareLight,
    faSquareCheckLight,
    faPlusLight,
    faMessagesRegular,
    faArrowDownToLine,
    faFile,
    faHeadphones,
    faLink,
    faEye,
    faEyeSlash,
    faCopy,
  );
}
