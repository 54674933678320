import { Strings } from "./strings";

export const PHONE_NUMBER = {
  id: "",
  assignees: [],
  caller_id: null,
  caller_id_status: null,
  flow_enabled: false,
  friendly_name: "",
  forward_to: null,
  forwarding_enabled: false,
  incoming_calls_enabled: false,
  iso_country: "US",
  lead_source_id: null,
  lead_source_name: null,
  phone_number: "",
  managers: [],
  national_number: "",
  notifications_enabled: true,
  record_call: false,
  status: "in-use",
  transfer_enabled: false,
  transfer_calls: [],
  twilio_flow_id: null,
  voicemail_greeting: null,
  voicemail_greeting_type: null,
  voicemail_greeting_url: null,
  call_routing_type: 0,
  allow_calls_to_managers: false,
  play_recording_message: false,
};

export const ALLOW_CALLS_TO_MANAGERS = "allow_calls_to_managers";
export const ASSIGNEES = "assignees";
export const CALLER_ID = "caller_id";
export const CALLER_ID_STATUS = "caller_id_status";
export const FRIENDLY_NAME = "friendly_name";
export const FORWARD_TO = "forward_to";
export const FORWARDING_ENABLED = "forwarding_enabled";
export const ID = "id";
export const INCOMING_CALLS_ENABLED = "incoming_calls_enabled";
export const ISO_COUNTRY = "iso_country";
export const LEAD_SOURCE_ID = "lead_source_id";
export const LEAD_SOURCE_NAME = "lead_source_name";
export const MANAGERS = "managers";
export const NATIONAL_NUMBER = "national_number";
export const NOTIFICATIONS_ENABLED = "notifications_enabled";
export const PHONE_NUMBER_KEY = "phone_number";
export const PHONE_TREE_ENABLED = "flow_enabled";
export const RECORD_CALL = "record_call";
export const STATUS = "status";
export const TRANSFER_CALLS = "transfer_calls";
export const TRANSFER_ENABLED = "transfer_enabled";
export const TWILIO_FLOW_ID = "twilio_flow_id";
export const VOICEMAIL_GREETING = "voicemail_greeting";
export const VOICEMAIL_GREETING_TYPE = "voicemail_greeting_type";
export const VOICEMAIL_GREETING_URL = "voicemail_greeting_url";
export const ALTERNATIVE_NUMBER = "alternative_number";
export const CALL_ROUTING_TYPE = "call_routing_type";
export const PLAY_RECORDING_MESSAGE = "play_recording_message";
export const SIP_ENABLED = "sip_enabled";

export const PHONE_NUMBER_KEYS = {
  ALLOW_CALLS_TO_MANAGERS,
  ASSIGNEES,
  CALLER_ID,
  CALLER_ID_STATUS,
  FRIENDLY_NAME,
  FORWARD_TO,
  FORWARDING_ENABLED,
  ID,
  INCOMING_CALLS_ENABLED,
  ISO_COUNTRY,
  LEAD_SOURCE_ID,
  LEAD_SOURCE_NAME,
  MANAGERS,
  NATIONAL_NUMBER,
  NOTIFICATIONS_ENABLED,
  PHONE_NUMBER_KEY,
  PHONE_TREE_ENABLED,
  RECORD_CALL,
  STATUS,
  TRANSFER_CALLS,
  TRANSFER_ENABLED,
  TWILIO_FLOW_ID,
  VOICEMAIL_GREETING,
  VOICEMAIL_GREETING_TYPE,
  VOICEMAIL_GREETING_URL,
  CALL_ROUTING_TYPE,
  PLAY_RECORDING_MESSAGE,
  SIP_ENABLED,
};

export const SEQUENTIAL_CALL_OPTION_VALUE = TRANSFER_ENABLED;

export const CALL_ROUTING_OPTIONS = [
  { label: "None", value: null },
  {
    label: Strings.CALL_FORWARDING_LABEL,
    value: FORWARDING_ENABLED,
  },
  {
    label: Strings.PHONE_TREE_LABEL,
    value: PHONE_TREE_ENABLED,
  },
  {
    label: Strings.SEQUENTIAL_CALL_LABEL,
    value: TRANSFER_ENABLED,
  },
  {
    label: Strings.SIP_LABEL,
    value: SIP_ENABLED,
  },
];

export const CALL_ROUTING_TYPE_ENUM = {
  NONE: 0,
  FORWARDING: 1,
  PHONE_TREE: 2,
  TRANSFER: 3,
};

export const MAX_SEQ_CALL = 3;
