import { useContext, useEffect } from "react";
import { Text, View } from "react-native-web";
import IconButton from "../../components/Buttons/IconButton";
import Col from "../../layouts/Col";
import Row from "../../layouts/Row";
import palette from "../../styles/palette";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { StyleSheet } from "react-native";
import SipDomainsContext from "../../contexts/SipDomainsContext";
import Heading from "../../components/Heading/index.web";
import { Strings } from "../../constants/strings";
import { getSipSeverUrls } from "../../helpers/sipDomain";
import SolidButton from "../../components/Buttons/SolidButton";
import { Copyable } from "../../components/Copyable";

const SipConfigurationDetailsModal = ({ location, history }) => {
  const { credential } = location.state || {};
  const title = Strings.SIP_DOMAIN_SUMMARY_HEADING;
  const username =
    credential?.phoneLine?.number || credential?.phoneLine?.phone_number;
  const friendlyName =
    credential?.phoneLine?.friendlyName || credential?.phoneLine?.friendly_name;

  const { primaryDomain } = useContext(SipDomainsContext);

  if (!credential) return null;

  useEffect(() => {
    if (!credential) {
      history.goBack();
    }
  }, [credential]);

  return (
    <View style={[styles.modal_overlay]}>
      <View style={styles.modal_container}>
        <ModalHeader
          onClose={() => {
            history.goBack();
          }}
          title={title}
        />
        <View style={styles.modal__body}>
          <PrimaryDomain domain={primaryDomain} />
          <Heading size={5}>
            {Strings.SIP_DOMAIN_CREDENTIAL_USERNAME_V2_LABEL}
          </Heading>
          <Copyable text={username}>
            <Text style={styles.body__text}>{username}</Text>
          </Copyable>
          <Heading size={5}>
            {Strings.SIP_DOMAIN_CREDENTIAL_DISPLAY_NAME_LABEL}
          </Heading>
          <Copyable text={friendlyName}>
            <Text style={styles.body__text}>{friendlyName}</Text>
          </Copyable>
          <Heading size={5}>
            {Strings.SIP_DOMAIN_CREDENTIAL_LEGAL_NAME_LABEL}
          </Heading>
          <Copyable text={username}>
            <Text style={styles.body__text}>{username}</Text>
          </Copyable>
        </View>
        <Row right pr={16}>
          <SolidButton
            label={Strings.GENERAL_CLOSE_LABEL}
            onPress={() => history.goBack()}
          />
        </Row>
      </View>
    </View>
  );
};

const PrimaryDomain = ({ domain }) => {
  const domain_name = domain?.attributes?.domain_name || "";
  const { server1, server2 } = getSipSeverUrls(domain_name);
  return (
    <View style={styles.sip_domain_name_container}>
      <Heading size={5}>{Strings.SIP_DOMAIN_SERVER_1_LABEL}</Heading>
      <Copyable text={server1}>
        <Text style={styles.body__text}>{server1}</Text>
      </Copyable>
      <Heading size={5}>{Strings.SIP_DOMAIN_SERVER_2_LABEL}</Heading>
      <Copyable text={server2}>
        <Text style={styles.body__text}>{server2}</Text>
      </Copyable>
    </View>
  );
};

const ModalHeader = ({ onClose, title }) => {
  return (
    <View style={[styles.modal__header]}>
      <Row pl={16} pr={8}>
        <Col leftCenter>
          <Text style={[styles.modal__header_title_text]}>{title}</Text>
        </Col>
        <Col size={1} rightCenter>
          <IconButton
            onPress={onClose}
            faPro
            faProIcon={faXmark}
            iconSize={28}
          />
        </Col>
      </Row>
    </View>
  );
};

const styles = StyleSheet.create({
  modal__header: {
    height: 60,
    borderBottomColor: palette.light_grey,
    borderBottomWidth: 1,
    justifyContent: "center",
  },
  modal__body: {
    paddingHorizontal: 16,
    flex: 1,
  },
  modal__footer: {
    paddingHorizontal: 16,
    marginBottom: 8,
  },
  modal__header_title_text: {
    fontSize: 16,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
  },
  modal_overlay: {
    backgroundColor: "rgba(45, 62, 80, 0.79)",
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
    transitionProperty: "none",
    transitionDelay: "0ms",
    transitionDuration: "100ms",
  },
  modal_container: {
    paddingBottom: 8,
    position: "absolute",
    minWidth: "450px",
    maxWidth: "600px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: palette.white,
    zIndex: 1040,
    shadowColor: "#000",
    shadowOffset: {
      width: 5,
      height: 0,
    },
    shadowOpacity: 0.12,
    shadowRadius: 9,
    elevation: 4,
    borderRadius: 3,
  },
  body__message: {
    color: palette.black,
    fontSize: 16,
    marginVertical: 10,
  },
  body_error_message: {
    color: palette.danger,
    fontSize: 16,
    marginVertical: 10,
  },
  body__text: {
    color: palette.grey,
    fontSize: 16,
  },
});

export default SipConfigurationDetailsModal;
