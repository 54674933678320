import { safeParseNumber } from "../../helpers/phoneNumbers";
import IconButton from "../Buttons/IconButton";
import palette from "../../styles/palette";
import Row from "../../layouts/Row";
import commonStyles from "../../styles/common";
import { ICONS_LIBRARY } from "../../constants/icons";

export default function CommunicationsButtonsGroup({
  phone = "",
  email = "",
  onPhonePress,
  onMessagePress,
  onEmailPress,
}) {
  const isPhoneValid = safeParseNumber(phone)?.isValid();
  const isEmailValid =
    email && email.length > 0 && !/^\S+@\S+\.\S+$/.test(email) ? false : true;

  const handlePhonePress = () => {
    if (isPhoneValid) {
      onPhonePress(phone);
    }
  };

  const handleEmailPress = () => {
    if (isEmailValid) {
      onEmailPress(email);
    }
  };

  const handleMessagePress = () => {
    if (isPhoneValid) {
      onMessagePress(phone);
    }
  };

  return (
    <Row
      style={[
        commonStyles.row_center,
        {
          justifyContent: "space-evenly",
          borderWidth: 1,
          borderColor: palette.light_grey,
          borderRadius: 4,
        },
      ]}
      gap={2}
    >
      {onPhonePress && (
        <IconButton
          faPro
          faProIcon={ICONS_LIBRARY.PHONE}
          iconColor={palette.primary}
          round={false}
          mb={0}
          mr={0}
          ml={0}
          mt={0}
          height={26}
          width={26}
          iconSize={16}
          borderRadius={4}
          disabled={!isPhoneValid}
          onPress={handlePhonePress}
        />
      )}
      {onMessagePress && (
        <IconButton
          faPro
          faProIcon={ICONS_LIBRARY.MESSAGES}
          iconColor={palette.primary}
          round={false}
          mb={0}
          mr={0}
          mt={0}
          ml={0}
          height={26}
          width={26}
          iconSize={18}
          borderRadius={4}
          disabled={!isPhoneValid}
          onPress={handleMessagePress}
        />
      )}
      {onEmailPress && (
        <IconButton
          faPro
          faProIcon={ICONS_LIBRARY.SEND}
          iconColor={palette.primary}
          mb={0}
          mr={0}
          ml={0}
          mt={0}
          iconSize={16}
          width={26}
          height={26}
          borderRadius={4}
          disabled={!email || !isEmailValid}
          onPress={handleEmailPress}
        />
      )}
    </Row>
  );
}
