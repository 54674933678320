import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import ContactsContext from "../../../../contexts/ContactsContext";
import Contacts from "../../../../models/Contacts";
import StyledSelect from "../../../StyledSelect";
import { View, StyleSheet, Text } from "react-native";
import commonStyles from "../../../../styles/common";
import { Field } from "../../../Customer/CustomerForm";
import AdditionalContactContext from "../../../../contexts/AdditionalContactContext";
import StyledInput from "../../../StyledInput";
import PhoneInput from "../../../PhoneInput";
import Heading from "../../../Heading/index.web";
import ConversationContext from "../../../../contexts/ConversationContext";
import Engage from "../../../../services/engage";
import RecipientSearchInput, {
  RecipientTag,
} from "../../../RecipientSearchInput/index.web";
import {
  checkContactFormValid,
  getCustomerName,
} from "../../../../helpers/customers";
import SolidButton from "../../../Buttons/SolidButton";
import ThreadsContext from "../../../../contexts/ThreadsContext";
import { CallsContext } from "../../../../contexts/CallsContext";
import { VoicemailsContext } from "../../../../contexts/VoicemailsContext";
import {
  fieldRequired,
  minLen,
  validEmail,
  validPhone,
} from "../../../../helpers/text";
import palette from "../../../../styles/palette";
import { ICONS_LIBRARY } from "../../../../constants/icons";
import Row from "../../../../layouts/Row";
import Col from "../../../../layouts/Col";
import CommunicationsButtonsGroup from "../../../CommunicationButtonsGroup";
import useTwilio from "../../../../hooks/useTwilio";

const validators = {
  first_name: (val) => fieldRequired("First Name", val),
  phone: (val) =>
    fieldRequired("Phone", val) ||
    minLen("Phone", val, 10) ||
    validPhone("Phone", val),
  email: (val) => validEmail("Email", val),
};

const validationErrorReducer = (state, action) => {
  if (action) {
    return {
      ...state,
      ...action,
    };
  }

  return state;
};

export function ContactSelect({ parent, participant, parentContact }) {
  const remoteId = useRef(null);
  const { loading, contacts, getContacts } = useContext(ContactsContext);
  const {
    setConversation,
    setParticipant,
    getRelatedConversations,
    setTempHeader,
    conversation,
    setConversationById,
  } = useContext(ConversationContext);

  const {
    loading: loadingContact,
    saveAdditionalContact,
    saving,
    contact,
    dirty,
  } = useContext(AdditionalContactContext);
  const { updateThread } = useContext(ThreadsContext);
  const { setSelected: setSelectedCall } = useContext(CallsContext);
  const { setSelected: setSelectedVoicemail } = useContext(VoicemailsContext);
  const [contactDisplayList, setContactDisplayList] = useState([]);

  const isChild = !!participant?.parent;
  const isParentSelected = !isChild;

  const backgroundColorVisible = useMemo(() => {
    return contactDisplayList?.length > 1 || isChild;
  }, [contactDisplayList, isChild]);

  const onValueChange = async (value) => {
    if (!value) {
      return;
    }

    const phone = value;
    if (phone) {
      const res = await Engage.getParticipantByPhone({
        phone,
      });

      if (res.conversation) {
        setConversation({ id: res.conversation.id });
        setConversation(res.conversation);
      } else if (res.response) {
        const _participant = res.response;
        setParticipant(_participant);
      }

      if (res.response) {
        getRelatedConversations(res?.response?.phone_number);
      }
    }
  };

  useEffect(() => {
    if (
      (participant?.is_child &&
        participant?.parent?.remote_id &&
        remoteId.current !== participant?.parent?.remote_id) ||
      (!participant?.is_child &&
        participant?.remote_id &&
        remoteId.current !== participant?.remote_id)
    ) {
      remoteId.current =
        participant?.parent?.remote_id || participant?.remote_id;
      getContacts(
        participant?.parent?.remote_id || participant?.remote_id,
        participant?.parent?.id || participant?.id,
      );
    }
  }, [participant?.parent?.remote_id]);

  useEffect(() => {
    const parentPrimaryLine = {
      label: getCustomerName(parentContact),
      value: parentContact?.phone_e164 || parentContact?.phone,
      phone: parentContact?.phone_e164 || parentContact?.phone,
      subLabel: "Customer",
    };
    const displayList = [];
    displayList.push(parentPrimaryLine);
    if (parentContact?.alt_phone) {
      const parentAltLine = {
        label: getCustomerName(parentContact),
        phone: parentContact?.alt_phone,
        value: parentContact?.alt_phone,
        subLabel: "Customer Alternate Phone",
      };
      displayList.push(parentAltLine);
    }

    if (!loading && contacts?.length) {
      const listSet = {};
      contacts.forEach((contact) => {
        listSet[contact?.phone_e164 || contact?.phone] = contact;
      });
      const contactList = new Contacts(Object.values(listSet));
      displayList.push(...contactList.getContactsForDropdown());
    }

    if (!!participant?.remote_id) {
      const isParticipantPresent = displayList.find(
        (contact) =>
          contact.value === participant?.phone_e164 ||
          contact.value === participant?.phone_number,
      );
      if (!isParticipantPresent) {
        displayList.push({
          label: participant?.name,
          value: participant?.phone_e164 || participant?.phone_number,
          phone: participant?.phone_e164 || participant?.phone_number,
        });
      }
    }
    const listSet = {};
    displayList.forEach((contact) => {
      listSet[contact?.value] = contact;
    });

    setContactDisplayList(Object.values(listSet));
  }, [
    participant,
    loading,
    contacts?.length,
    parent,
    isChild,
    parentContact,
    isParentSelected,
  ]);

  const onSearch = useCallback(
    async (search) => {
      if (!search) {
        return contactDisplayList;
      } else {
        try {
          const res = await getContacts(
            participant?.parent?.remote_id || participant?.remote_id,
            participant?.parent?.id || participant?.id,
            search,
            false,
          );
          const listSet = {};
          res.forEach((contact) => {
            listSet[contact?.phone_e164 || contact?.phone] = contact;
          });
          const contactList = new Contacts(Object.values(listSet));
          const displayList = contactList.getContactsForDropdown();
          return displayList;
        } catch (error) {
          return [];
        }
      }
    },
    [
      participant?.parent?.remote_id,
      participant?.parent?.id,
      contactDisplayList,
      getContacts,
    ],
  );

  const onSave = useCallback(async () => {
    const participant = await saveAdditionalContact();

    if (participant) {
      const title = participant?.name || participant?.phone_number;
      setTempHeader(title);
      setConversationById(conversation?.id, true);
      updateThread({ ...conversation, participant });
      setSelectedCall(null);
      setSelectedVoicemail(null);
    }
  }, [saveAdditionalContact, conversation?.participant?.id, conversation?.id]);

  const isContactFormValid = useMemo(() => {
    return checkContactFormValid(contact);
  }, [contact]);

  if (contactDisplayList?.length < 2 && isParentSelected) {
    return null;
  }

  return (
    <View style={[styles.contact_select__container]}>
      <>
        <View
          style={[
            commonStyles.row_normal,
            styles.contact_select__container__header,
          ]}
        >
          {backgroundColorVisible && (
            <Heading size={4} mt={0}>
              Contact
            </Heading>
          )}
          {isChild && (
            <View
              style={{
                paddingVertical: 8,
                paddingRight: 8,
              }}
            >
              <SolidButton
                label="Update/Save"
                onPress={onSave}
                loading={saving}
                disabled={!isContactFormValid || !dirty}
              />
            </View>
          )}
        </View>
      </>
      {contactDisplayList?.length > 1 && (
        <View>
          <StyledSelect
            options={contactDisplayList}
            selectedValue={participant?.phone_e164 || participant?.phone_number}
            onValueChange={onValueChange}
            disabled={loadingContact}
            onSearch={onSearch}
            searchable
          />
        </View>
      )}
      {!isParentSelected && (
        <ContactForm hasContacts={contacts?.length} hasParent={isChild} />
      )}
    </View>
  );
}

export const ContactForm = ({ isContact, hasContacts, hasParent }) => {
  const { setCallQueue } = useTwilio();
  const { conversation, getConversationByParticipant } =
    useContext(ConversationContext);
  const { contact, updateContact, parentCustomer, handleParentChange, error } =
    useContext(AdditionalContactContext);
  const { loading } = useContext(ContactsContext);
  const [validationErrors, setValidationError] = useReducer(
    validationErrorReducer,
    {},
  );

  const backgroundColorVisible = !!hasContacts || hasParent;

  const onChange = (attribute) => (val) => {
    updateContact({ [attribute]: val });
    if (validators[attribute]) {
      const hasError = validators[attribute](val);
      setValidationError(
        hasError ? { [attribute]: hasError } : { [attribute]: undefined },
      );
    }
  };

  const onPhonePress = (phoneNumber) => {
    setCallQueue({
      to: phoneNumber,
      metaData: conversation?.participant || contact,
    });
  };

  const onMessagePress = async (phone_number) => {
    await getConversationByParticipant({
      phone_number,
    });
  };

  const onEmailPress = (email) => {
    try {
      window.open(`mailto:${email}`);
    } finally {
    }
  };

  return (
    <>
      <View style={[backgroundColorVisible && styles.contact_form_container]}>
        {error && (
          <View style={[styles.error_message_container]}>
            <Text style={[styles.error_message_text]}>{error}</Text>
          </View>
        )}
        {validationErrors.email && (
          <View style={[styles.error_message_container]}>
            <Text style={[styles.error_message_text]}>
              {validationErrors.email}
            </Text>
          </View>
        )}
        {validationErrors.first_name && (
          <View style={[styles.error_message_container]}>
            <Text style={[styles.error_message_text]}>
              {validationErrors.first_name}
            </Text>
          </View>
        )}
        {!!contact?.phone && validationErrors.phone && (
          <View style={[styles.error_message_container]}>
            <Text style={[styles.error_message_text]}>
              {validationErrors.phone}
            </Text>
          </View>
        )}
        <Field
          label="Name"
          horizontal
          icon={ICONS_LIBRARY.ID_CARD_LIGHT}
          fieldLabelStyle={[styles.contact_form_field]}
        >
          <Row gap={4}>
            <Col>
              <Field label="First Name" required>
                <StyledInput
                  onChangeText={onChange("first_name")}
                  defaultValue={contact?.first_name || ""}
                  lessPadding
                  bold
                  autoComplete="none"
                  nativeID="first_name"
                  onHoverColor={
                    backgroundColorVisible
                      ? palette.bg_light_grey
                      : palette.lightest_grey
                  }
                />
              </Field>
            </Col>
            <Col>
              <Field label="Last Name">
                <StyledInput
                  onChangeText={onChange("last_name")}
                  defaultValue={contact?.last_name || ""}
                  lessPadding
                  bold
                  autoComplete="none"
                  nativeID="last_name"
                  onHoverColor={
                    backgroundColorVisible
                      ? palette.bg_light_grey
                      : palette.lightest_grey
                  }
                />
              </Field>
            </Col>
          </Row>
        </Field>
        <Field
          label="Phone"
          required
          icon={ICONS_LIBRARY.PHONE_LIGHT}
          horizontal
          fieldLabelStyle={[styles.contact_form_field]}
        >
          <PhoneInput
            onChange={onChange("phone")}
            defaultValue={contact.phone}
            lessPadding
            bold
            nativeID="phone"
            onHoverColor={
              backgroundColorVisible
                ? palette.bg_light_grey
                : palette.lightest_grey
            }
            onPhonePress={onPhonePress}
            onMessagePress={onMessagePress}
          />
        </Field>
        <Field
          label="Email"
          icon={ICONS_LIBRARY.MAIL_LIGHT}
          horizontal
          fieldLabelStyle={[styles.contact_form_field]}
          style={[backgroundColorVisible && styles.contact_form__email_field]}
        >
          <Row>
            <Col>
              <StyledInput
                onChangeText={onChange("email")}
                defaultValue={contact?.email || ""}
                lessPadding
                bold
                nativeID="email"
                onHoverColor={
                  backgroundColorVisible
                    ? palette.bg_light_grey
                    : palette.lightest_grey
                }
              />
            </Col>
            <Col maxWidth={28}>
              <CommunicationsButtonsGroup
                onEmailPress={onEmailPress}
                email={contact?.email}
              />
            </Col>
          </Row>
        </Field>
      </View>
      {!backgroundColorVisible && !loading && (
        <Field
          label="Customer"
          required
          icon={ICONS_LIBRARY.USER_PLUS_LIGHT}
          horizontal
          fieldLabelStyle={[styles.contact_form_field]}
        >
          {isContact &&
            (!parentCustomer ? (
              <RecipientSearchInput
                onPress={(data) => {
                  handleParentChange(data);
                }}
                showAddMessage={false}
                inputProps={{
                  lessPadding: true,
                  onHoverColor: backgroundColorVisible
                    ? palette.bg_light_grey
                    : palette.lightest_grey,
                }}
              />
            ) : (
              <RecipientTag
                recipient={{ name: getCustomerName(parentCustomer) }}
                onClear={() => {
                  handleParentChange(null);
                }}
              />
            ))}
        </Field>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  error_message_container: {
    backgroundColor: "#f8d7da",
    padding: 12,
    borderRadius: 4,
    marginBottom: 8,
  },
  error_message_text: {
    color: palette.danger,
    textAlign: "center",
    fontFamily: "acumin-pro, san-serif",
    fontWeight: "600",
  },
  contact_form_field: {
    paddingTop: 0,
  },
  contact_form__email_field: {
    marginBottom: 8,
  },
  contact_select__container: {
    marginBottom: 16,
  },
  contact_form_container: {
    backgroundColor: "#F5F5F7",
    borderRadius: 4,
    marginTop: 8,
    borderWidth: 1,
    borderColor: "#EDEDED",
  },
  contact_select__container__header: {
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 8,
  },
});
