import Engage from "../services/engage";
import { List } from "./base";
import Credential from "./Credential";

class CredentialList extends List {
  getCredentialsPhoneLineMap() {
    return (
      this.models?.reduce((acc, data) => {
        const { username } = data?.attributes || {};
        if (username) acc[username] = data;
        return acc;
      }, {}) || {}
    );
  }

  static async getCredentialLists(domainId) {
    console.log("Getting Credential List", domainId);
    try {
      return await Engage.getCredentialLists(domainId);
    } catch (e) {
      console.error(e);
      return { response: [] };
    }
  }

  static async getCredentials(domainId, credentialListId) {
    console.log("Getting Credential", domainId, credentialListId);
    try {
      return await Engage.getCredentials(domainId, credentialListId);
    } catch {
      return { response: [] };
    }
  }

  static async createCredentialList(domainId, friendlyName) {
    try {
      return await Engage.createCredentialList(domainId, {
        friendly_name: friendlyName,
      });
    } catch {
      return { response: {} };
    }
  }

  doesPhoneLineHaveCredentials(phoneLine) {
    const credentialMap = this.getCredentialsPhoneLineMap();
    return !!credentialMap[phoneLine?.number];
  }

  get model() {
    return Credential;
  }
}

export default CredentialList;
