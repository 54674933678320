import React, {
  useEffect,
  useMemo,
  useRef,
  useCallback,
  useContext,
  useState,
} from "react";

import {
  Text,
  View,
  StyleSheet,
  TouchableWithoutFeedback,
  TextInput,
  ScrollView,
  Pressable,
  TouchableOpacity,
  Alert,
} from "react-native";
import { GiftedChat } from "react-native-gifted-chat";
import InputToolBar from "./InputToolBar";
import Bubble from "./Bubble";
import CallBubble from "./CallBubble";
import SystemMessage, { SystemText } from "./SystemMessage";
import palette from "~/styles/palette";
import { useHistory } from "react-router-dom";
import ConversationContext from "../../contexts/ConversationContext";
import AppContext from "../../contexts/AppContext";
import IconButton from "~/components/Buttons/IconButton";
import RecipientSearchInput from "../RecipientSearchInput/index.web";
import Icon from "react-native-vector-icons/Feather";
import Row from "../../layouts/Row";
import Col from "../../layouts/Col";
import UserContext from "../../contexts/UserContext";
import Avatar from "../Avatar/index";
import TwilioContext from "../../contexts/TwilioContext";
import { MediaContext } from "../../contexts/MediaContext";
import ReactAudioPlayer from "react-audio-player";
import dayjs from "~/helpers/day";
import useMedia from "../../hooks/useMedia";
import { ActivityIndicator } from "react-native-web";
import ThreadsContext from "../../contexts/ThreadsContext";
import SolidButton from "../Buttons/SolidButton/index";
import StyledInput from "../StyledInput";
import TeamContext from "../../contexts/TeamContext";
import { isSameDay, isSameUser } from "./helpers/utils";
import { faImages, faPaperPlane } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  faBan,
  faChevronLeft,
  faPenToSquare,
  faPhoneArrowDownLeft,
  faPhoneArrowUpRight,
  faPhoneMissed,
  faVolumeUp,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import StyledPicker from "../StyledPicker";
import {
  ALL_INTERACTIONS_FILTER,
  CALLS_INTERACTIONS_FILTER,
  EMAIL_FILTER,
  INTERACTIONS_FILTERS,
  INTERNAL_NOTE_INTERACTIONS_FILTER,
  RECORDING_INTERACTIONS_FILTER,
  TEXT_MESSAGE_INTERACTIONS_FILTER,
  VOICEMAIL_INTERACTIONS_FILTER,
} from "../../constants/conversations";
import EmailContext from "../../contexts/EmailContext";
import EmailView from "./EmailView";
import { toTitleCase } from "../../helpers/text";
import EmailList from "./EmailList";
import {
  MAX_COMPOSER_HEIGHT,
  MIN_COMPOSER_HEIGHT,
} from "../../constants/composer";
import NumbersContext from "../../contexts/NumbersContext";
import { Strings } from "../../constants/strings";
import useTwilio from "../../hooks/useTwilio";
import Engage from "../../services/engage";
import CustomersContext from "../../contexts/CustomersContext";
import { universalModalOpenEvent } from "../../modals/UniversalModal/index.web";

function Chat({ setMediaUploadVisible }) {
  const {
    conversation,
    sendMessage,
    getOlderInteractions,
    loading,
    loadingConversation,
    activeNote,
    setActiveNote,
    updatedNotes,
    clearConversation,
    activeTab,
    sendNote,
    filter,
    setFilter,
    loadingOlderInteractions,
    setConversation,
    setParticipant,
    setNewConversation,
    deleteMessage,
    retryMessage,
  } = useContext(ConversationContext);
  const { user, isCampaignVerified } = useContext(UserContext);
  const { blockedContacts } = useContext(CustomersContext);
  const { members } = useContext(TeamContext);
  const { assets } = useMedia();
  const { setCallQueue } = useTwilio();
  const hasOptedOut = !!conversation?.sms_opt_out;
  const history = useHistory();

  const {
    getConversationDraft,
    setConversationDraft,
    isConversationDraftsReady,
  } = useContext(ThreadsContext);
  const [initText, setInitText] = useState("");
  const [initialSend, setInitialSend] = useState(false);
  useEffect(() => {
    if (isConversationDraftsReady) {
      const cachedMesssage = getConversationDraft(conversation.id);
      setInitText(cachedMesssage);
    }
  }, [isConversationDraftsReady]);
  const { selectedEmail, setSelectedEmail } = useContext(EmailContext);
  const isBlocked =
    conversation?.participant?.is_blocked ||
    blockedContacts?.includes(conversation?.participant?.id);

  const canRetry =
    activeTab === 1 ||
    (isCampaignVerified &&
      conversation?.participant?.valid &&
      conversation?.twilio_number?.id &&
      !isBlocked &&
      !hasOptedOut);

  let inputRef = useRef();

  let giftedRef = useRef();
  let initialScroll = useRef(false);

  useEffect(() => {
    if (!initialScroll.current) {
      if (giftedRef.current) {
        giftedRef.current.scrollToBottom();
        initialScroll.current = true;
      }
    }
  }, [
    conversation?.interactions?.length,
    filter?.interaction_type,
    giftedRef.current,
    initialScroll.current,
  ]);

  const onSend = useCallback(
    async (messages = []) => {
      if ((messages && messages.length) || (assets && assets.length)) {
        const msg = messages[0];

        if (activeTab === 1 && msg.text) {
          sendNote({ ...msg, body: msg.text, interactable_type: "TwilioNote" });
          if (giftedRef.current) giftedRef.current.scrollToBottom();
        } else {
          if (msg.text || (assets.length && assets[0])) {
            sendMessage({ ...msg, body: msg.text });
            if (giftedRef.current) giftedRef.current.scrollToBottom();
          }
        }
      }
    },
    [sendMessage, assets, activeTab, setFilter],
  );
  // End Handlers for GiftedChat component

  const onEnterPress = useCallback(
    (e) => {
      if (
        !e.nativeEvent.shiftKey &&
        e.nativeEvent.key === "Enter" &&
        inputRef.current.value &&
        inputRef.current.value.trim() &&
        (activeTab === 1 || (isCampaignVerified && !isBlocked && !hasOptedOut))
      ) {
        e.preventDefault();
        e.stopPropagation();
        onSend([
          {
            conversation_id: conversation.id,
            text: inputRef.current.value,
            user: {
              ...user,
              _id: conversation?.twilio_number?.id,
            },
            created_at: new Date(),
          },
        ]);
        return true;
      }
    },
    [
      user,
      onSend,
      conversation,
      isCampaignVerified,
      activeTab,
      isBlocked,
      hasOptedOut,
    ],
  );

  const onCallPress = (phone) => {
    setCallQueue({ to: phone });
  };

  const onMessagePress = async (phone, save = false) => {
    if (phone) {
      if (!save) clearConversation();
      const res = await Engage.getParticipantByPhone({
        phone,
      });

      if (res.conversation) {
        setConversation({ id: res.conversation.id });
        setConversation(res.conversation);
      } else if (res.response) {
        const participant = res.response;
        if (save && !participant?.remote_id) {
          history.push({ hash: "new-customer" }, { participant });
          return;
        }
        setNewConversation(true);
        setParticipant(res.response);
      }
    }
  };

  const onPhonePress = (phone, communicationType) => {
    switch (communicationType) {
      case Strings.COMMUNICATIONS.CALL:
        onCallPress(phone);
        break;
      case Strings.COMMUNICATIONS.MESSAGE:
        onMessagePress(phone);
        break;
      case Strings.COMMUNICATIONS.SAVE:
        console.log("save", phone);
        onMessagePress(phone, true);
        break;
      default:
        break;
    }
  };

  const onRetryMessage = async (messageId, message) => {
    await retryMessage(messageId, message);
  };

  const onDeleteMessage = async (messageId, _id, message) => {
    universalModalOpenEvent({
      message: Strings.MESSAGE_DELETE_CONFIRM_MESSAGE,
      actionButton: {
        onPress: async () => {
          await deleteMessage(messageId, _id, message);
        },
        label: Strings.GENERAL_YES_LABEL,
      },
    });
  };

  const renderBubbleFunc = (props) => {
    return renderBubble({
      ...props,
      onPhonePress,
      onRetryMessage,
      onDeleteMessage,
      canRetry,
    });
  };

  const interactions = useMemo(() => {
    let innerInteractions = [];
    switch (filter?.interaction_type) {
      case TEXT_MESSAGE_INTERACTIONS_FILTER:
        innerInteractions = conversation?.interactions?.filter(
          (interaction) => interaction?.interactable_type === "TwilioMessage",
        );
        break;
      case CALLS_INTERACTIONS_FILTER:
        innerInteractions = conversation?.interactions?.filter(
          (interaction) => interaction?.interactable_type === "TwilioCall",
        );
        break;
      case VOICEMAIL_INTERACTIONS_FILTER:
        innerInteractions = conversation?.interactions?.filter(
          (interaction) =>
            interaction?.interactable_type === "TwilioCall" &&
            interaction?.missed &&
            interaction?.meta?.voicemail,
        );
        break;
      case RECORDING_INTERACTIONS_FILTER:
        innerInteractions = conversation?.interactions?.filter(
          (interaction) =>
            interaction?.interactable_type === "TwilioCall" &&
            interaction?.meta?.call_recording,
        );
        break;
      case INTERNAL_NOTE_INTERACTIONS_FILTER:
        innerInteractions = conversation?.interactions?.filter(
          (interaction) => interaction?.interactable_type === "TwilioNote",
        );
        break;
      default:
        innerInteractions = conversation?.interactions;
        break;
    }

    if (filter?.user !== ALL_INTERACTIONS_FILTER) {
      innerInteractions = innerInteractions?.filter((i) => {
        const teamMember = members
          ?.filter((m) => m?.is_active)
          ?.find((m) => m.id === parseInt(filter?.user));

        if (!teamMember) return false;

        if (i?.interactable_type === "TwilioCall") {
          return (
            i?.called_by === `${teamMember.first_name} ${teamMember.last_name}`
          );
        }

        return (
          i?.author?.type !== "user" ||
          i?.author?.remote_id === parseInt(filter?.user)
        );
      });
    }

    return innerInteractions?.map((i) => {
      const { author, ...interaction } = i;
      return {
        user: author,
        ...interaction,
      };
    });
  }, [
    conversation?.interactions,
    filter?.interaction_type,
    filter?.user,
    members?.length,
  ]);

  const onMediaPress = () => {
    if (setMediaUploadVisible) {
      setMediaUploadVisible(true);
    }
  };

  return (
    <>
      {loading || loadingConversation || !isConversationDraftsReady ? (
        <View style={{ flex: 1, justifyContent: "center" }}>
          <ActivityIndicator color={palette.primary} size="large" />
        </View>
      ) : (
        <>
          {!!selectedEmail ? (
            <EmailView />
          ) : (
            <>
              {filter?.interaction_type === EMAIL_FILTER ? (
                <EmailList />
              ) : (
                <GiftedChat
                  initialText={initText}
                  ref={(ref) => (giftedRef.current = ref)}
                  key={assets.length}
                  minInputToolbarHeight={assets.length > 0 ? 350 : 220}
                  minComposerHeight={MIN_COMPOSER_HEIGHT}
                  maxComposerHeight={MAX_COMPOSER_HEIGHT}
                  onLoadEarlier={getOlderInteractions}
                  loadEarlier={
                    conversation?.id &&
                    conversation.page < conversation.total_pages
                  }
                  isLoadingEarlier={loadingOlderInteractions}
                  listViewProps={{
                    showsVerticalScrollIndicator: false,
                  }}
                  scrollToBottom
                  textInputStyle={styles.conversation_thread__text_input}
                  messages={interactions}
                  onSend={onSend}
                  textInputProps={{
                    onKeyPress: onEnterPress,
                    innerRef: inputRef,
                  }}
                  inverted={false}
                  renderInputToolbar={(props) => (
                    <RenderInputToolbar {...props} />
                  )}
                  renderBubble={renderBubbleFunc}
                  // renderAccessory={renderAccessory}
                  renderSend={(props) => (
                    <RenderSend
                      {...props}
                      onMediaPress={onMediaPress}
                      hasOptedOut={hasOptedOut}
                    />
                  )}
                  renderSystemMessage={(props) => (
                    <RenderSystemMessage
                      setActiveNote={setActiveNote}
                      props={props}
                    />
                  )}
                  renderAvatar={(props) => <RenderAvatar {...props} />}
                  showUserAvatar
                  showAvatarForEveryMessage
                  user={{ ...user, _id: conversation?.twilio_number?.id }}
                  onInputTextChanged={(text) => {
                    if (!initialSend) setInitialSend(true);
                    else setConversationDraft(conversation.id)(text);
                  }}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default function ConversationThread() {
  const {
    conversation,
    loading,
    setActiveNote,
    activeNote,
    tempHeader,
    selectedAudio,
    setSelectedAudio,
  } = useContext(ConversationContext);

  const { mediaUploadVisible, setMediaUploadVisible } =
    useContext(MediaContext);

  return (
    <>
      {conversation?.id ? (
        <ConversationBar />
      ) : tempHeader ? (
        <TemporaryBar title={tempHeader} />
      ) : (
        <NewConversationBar />
      )}
      <View style={{ width: "100%", flex: 1, zIndex: -1 }}>
        {loading ? (
          <View
            style={{
              flex: 1,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator size="large" />
          </View>
        ) : (
          <>
            <Chat
              mediaUploadVisible={mediaUploadVisible}
              setMediaUploadVisible={setMediaUploadVisible}
            />
            {activeNote && (
              <NotesModal {...activeNote} setActiveNote={setActiveNote} />
            )}
          </>
        )}
      </View>
    </>
  );
}

const NotesModal = ({ setActiveNote, ...props }) => {
  const [editable, setEditable] = useState(true);
  const [height, setHeight] = useState(150);
  const [note, setNote] = useState(props.note || "");
  const { updateCallNote, saving } = useContext(ConversationContext);

  const editing = useMemo(() => {
    return !props.note || editable;
  }, [props]);

  const onSave = async () => {
    if (!editing) {
      setEditable(true);
    } else {
      await updateCallNote(props.id, note);
      setActiveNote(null);
    }
  };

  return (
    <View
      style={{
        position: "absolute",
        right: 4,
        top: "20%",
        borderWidth: 2,
        padding: 6,
        zIndex: 1000,
        backgroundColor: palette.white,
        width: "40%",
        borderColor: "#b2b2b2",
      }}
    >
      <Col rightCenter margin={5}>
        <IconButton
          onPress={() => {
            setActiveNote(null);
          }}
          faProIcon={faXmark}
          faPro
          width={30}
          height={30}
          color={palette.primary}
          iconColor={palette.white}
          iconSize={20}
        />
      </Col>
      {editing ? (
        <StyledInput
          value={note}
          multiline
          autoFocus
          style={{
            flex: 1,
            fontSize: 14,
          }}
          containerStyle={{ height, marginBottom: 5 }}
          onChangeText={setNote}
          nativeID="conversation_note"
        />
      ) : (
        <ScrollView
          style={{
            marginBottom: 5,
            height,
            padding: 12,
            borderColor: palette.light_grey,
            borderWidth: StyleSheet.hairlineWidth,
            borderRadius: 2,
          }}
          showsVerticalScrollIndicator={true}
        >
          <Text
            style={{
              fontSize: 14,
              fontWeight: "400",
              fontFamily: "acumin-pro, sans-serif",
            }}
            onLayout={(e) => {
              setHeight(
                Math.min(e.nativeEvent.layout.height > 200 ? 200 : 150),
              );
            }}
          >
            {props.note}
          </Text>
        </ScrollView>
      )}
      <Row rightCenter>
        <SolidButton
          label={editing ? "Save" : "Edit"}
          onPress={onSave}
          loading={saving}
        />
      </Row>
    </View>
  );
};

const NewConversationBar = () => {
  return (
    <View style={[styles.new_conversation_bar__container]}>
      <Row>
        <Col maxWidth={50} leftCenter pl={16}>
          <Text style={[styles.new_conversation_bar__input_label]}>To</Text>
        </Col>
        <Col mr={8}>
          <RecipientSearchInput />
        </Col>
      </Row>
    </View>
  );
};

const ConversationBar = () => {
  const history = useHistory();
  const { conversation, loading, filter, setFilter } =
    useContext(ConversationContext);
  const { compactMode } = useContext(AppContext);
  const {
    selectedMailBox,
    mailBoxes,
    setSelectedMailBox,
    loadingMailBoxes,
    doesAccountExist,
    selectedDirection,
    setSelectedDirection,
    emailClientError,
    loadingEmails,
    isGoogleAccount,
  } = useContext(EmailContext);
  const { members } = useContext(TeamContext);
  const { canSeeCustomerContact } = useContext(UserContext);
  const { blockedContacts } = useContext(CustomersContext);
  const { selectedAudio, setSelectedAudio } = useContext(ConversationContext);
  const isCustomerSaved = conversation?.participant?.remote_id !== null;
  const title =
    isCustomerSaved || canSeeCustomerContact
      ? conversation?.participant?.name ||
        conversation?.participant?.phone_number
      : "Unknown Customer";
  const isBlocked =
    conversation?.participant?.is_blocked ||
    blockedContacts?.includes(conversation?.participant?.id);
  const subtitle = conversation?.participant?.parent?.name;

  const teamMembers = useMemo(() => {
    return [
      { name: "All Users", value: ALL_INTERACTIONS_FILTER },
      ...members
        ?.filter((user) => user?.is_active)
        ?.map((teamMember) => {
          return {
            name: `${teamMember.first_name} ${teamMember.last_name}`,
            value: teamMember.id,
          };
        }),
    ];
  }, [members]);

  const emailDirections = useMemo(() => {
    let options = [
      { name: "Inbound", value: "from" },
      { name: "Outbound", value: "to" },
    ];

    if (isGoogleAccount) {
      options.push({ name: "Both Inbound and Outbound", value: "both" });
    }

    return options;
  }, [isGoogleAccount]);

  const onBlockContact = () => {
    history.push(
      { hash: "confirm-delete" },
      {
        message: `This will block ${title}${
          canSeeCustomerContact
            ? " " + conversation?.participant?.phone_number
            : ""
        }. Do you want to continue?`,
        type: "block-contact",
        id: conversation?.participant?.id,
        onSuccessMessage: Strings.BLOCK_NUMBER_SUCCESS,
        onErrorMessage: Strings.BLOCK_NUMBER_ERROR,
        title: Strings.BLOCK_NUMBER_CONFIRM_HEADING,
      },
    );
  };

  return (
    <View
      style={[
        styles.conversation_bar__container,
        { minHeight: loading ? 75 : 100 },
      ]}
    >
      <Row
        pl={15}
        pr={15}
        style={{
          height: 75,
          position: "relative",
          borderBottomWidth: loading ? 0 : StyleSheet.hairlineWidth,
          borderBottomColor: palette.light_grey,
        }}
      >
        {compactMode && (
          <Col left maxWidth={75}>
            <View
              style={[styles.conversation_bar__back_btn_text_container]}
              onClick={history.goBack}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                color={palette.success}
                size={18}
                style={[styles.conversation_bar__back_btn_icon]}
              />
              <Text style={[styles.conversation_bar__back_btn_text]}>Back</Text>
            </View>
          </Col>
        )}
        <Col leftCenter pl={16}>
          <Text style={[styles.conversation_bar__title_text]}>{title}</Text>
          {subtitle && (
            <Text style={[styles.conversation_bar__title_subtext]}>
              {subtitle}
            </Text>
          )}
        </Col>
        {selectedAudio && (
          <View style={styles.conversation_bar__audio_player_container}>
            <View style={styles.conversation_bar__audio_player_inner_container}>
              <ReactAudioPlayer
                src={selectedAudio?.url}
                controls
                controlsList="nodownload noplaybackrate"
              />
              <IconButton
                faPro
                faProIcon={faXmark}
                onPress={() => {
                  setSelectedAudio(null);
                }}
                width={24}
                height={24}
                iconColor={palette.red}
                iconSize={20}
                ml={0}
                mr={0}
              />
            </View>
          </View>
        )}
        {!loading && (
          <View
            style={[styles.conversation_bar__back_btn_text_container]}
            onClick={history.goBack}
          >
            {!isBlocked && (
              <IconButton
                faPro
                faProIcon={faBan}
                onPress={onBlockContact}
                iconColor={palette.blocked}
                iconSize={24}
                width={26}
                height={26}
              />
            )}
            <IconButton
              faPro
              faProIcon={faTrash}
              onPress={() => {
                history.push(
                  { hash: "confirm-delete" },
                  {
                    message: `This will delete your conversation with ${title}. Do you want to continue?`,
                    type: "one-conversation",
                    id: conversation?.id,
                  },
                );
              }}
              iconColor={palette.danger}
              border
              borderColor={palette.danger}
              iconSize={16}
              width={26}
              height={26}
            />
          </View>
        )}
      </Row>
      {!loading && (
        <Row rightCenter mt={4} mb={4} mr={4}>
          {doesAccountExist && filter?.interaction_type === EMAIL_FILTER && (
            <View style={{ marginRight: 4 }}>
              <StyledPicker
                selectedValue={selectedDirection}
                onValueChange={setSelectedDirection}
                options={emailDirections}
                lessPadding
                disabled={loadingEmails}
              />
            </View>
          )}
          {filter?.interaction_type === EMAIL_FILTER &&
            !loadingMailBoxes &&
            mailBoxes.length > 0 &&
            doesAccountExist && (
              <View style={{ marginRight: 4 }}>
                <StyledPicker
                  selectedValue={selectedMailBox}
                  onValueChange={setSelectedMailBox}
                  options={mailBoxes?.map((m) => ({
                    name: toTitleCase(m?.name?.toLowerCase()),
                    value: m?.path,
                  }))}
                  lessPadding
                />
              </View>
            )}
          <View style={{ marginRight: 4 }}>
            <StyledPicker
              selectedValue={
                filter?.interaction_type || ALL_INTERACTIONS_FILTER
              }
              onValueChange={(value) =>
                setFilter((f) => ({ ...f, interaction_type: value }))
              }
              options={Object.keys(INTERACTIONS_FILTERS)
                ?.filter((filter) =>
                  emailClientError && filter === EMAIL_FILTER ? false : true,
                )
                .map((key) => ({
                  name: INTERACTIONS_FILTERS[key],
                  value: key,
                }))}
              lessPadding
            />
          </View>
          {filter?.interaction_type !== EMAIL_FILTER && (
            <View style={{ marginRight: 4 }}>
              <StyledPicker
                selectedValue={filter?.user || ALL_INTERACTIONS_FILTER}
                onValueChange={(value) =>
                  setFilter((f) => ({ ...f, user: value }))
                }
                options={teamMembers}
                lessPadding
              />
            </View>
          )}
        </Row>
      )}
    </View>
  );
};

const TemporaryBar = ({ title }) => {
  return (
    <View style={[styles.conversation_bar__container, { minHeight: 75 }]}>
      <Row pl={15} pr={15} style={{ height: "100%", position: "relative" }}>
        <Col leftCenter pl={16}>
          <Text style={[styles.conversation_bar__title_text]}>
            {typeof title === "string" ? title : title?.main}
          </Text>
          {!!title?.sub && (
            <Text style={[styles.conversation_bar__title_subtext]}>
              {title?.sub}
            </Text>
          )}
        </Col>
      </Row>
    </View>
  );
};

const RenderAvatar = (props) => {
  const { user } = props.currentMessage;
  const { user: nextMessageUser } = props.nextMessage;
  const { members, loading } = useContext(TeamContext);

  const shouldSkipAvatar = useMemo(() => {
    if (user?.id !== nextMessageUser?.id) return false;
    let currentCreatedAt = dayjs(props?.currentMessage?.created_at);
    return currentCreatedAt?.isSame(props?.nextMessage?.created_at, "day");
  }, [props?.currentMessage, props?.nextMessage]);

  const userData = useMemo(() => {
    if (loading) return user;
    return members.find((member) => member.id === user.remote_id);
  }, [user, members, loading]);

  const marginBottom = useMemo(() => {
    let { currentMessage, nextMessage } = props;
    if (
      currentMessage &&
      nextMessage &&
      !isSameUser(currentMessage, nextMessage) &&
      isSameDay(currentMessage, nextMessage) &&
      currentMessage?.user?.type === nextMessage?.user?.type
    ) {
      return 8;
    }
    return 0;
  }, [props]);

  if (shouldSkipAvatar)
    return (
      <View
        style={{
          width: 30,
          height: 30,
          marginLeft: user?.type === "participant" ? 8 : 0,
          marginRight: user?.type === "user" ? 8 : 0,
          marginBottom,
        }}
      />
    );

  if (loading || members?.length === 0 || !userData?.avatar)
    return (
      <Avatar
        size={30}
        initials={user?.initials}
        ml={user?.type === "participant" ? 8 : 0}
        mr={user?.type === "user" ? 8 : 0}
        style={{ marginBottom }}
      />
    );

  return (
    <Avatar
      size={30}
      source={userData?.avatar?.url}
      ml={user?.type === "participant" ? 8 : 0}
      mr={user?.type === "user" ? 8 : 0}
      style={{ marginBottom }}
    />
  );
};

const renderAccessory = (props) => {
  const { onSend, onAttach, text } = props;
  return (
    <Row
      center
      pt={5}
      pb={5}
      style={{
        height: 50,
        borderBottomColor: palette.light_grey,
        borderBottomWidth: 1,
        display: "flex",
      }}
    >
      <Row pl={16} pr={16} pt={8} pb={8}>
        <MediaButton onAttach={onAttach} />
      </Row>
    </Row>
  );
};

const SendIcon = ({ onSendHandler, text, isMessagingEnabled }) => {
  const { assets } = useMedia();

  return (
    <IconButton
      onPress={onSendHandler}
      faPro
      faProIcon={faPaperPlane}
      iconSize={30}
      height={40}
      ml={0}
      mr={0}
      secondaryColor={palette.success}
      disabled={
        !isMessagingEnabled || text ? text.trim().length < 1 : assets.length < 1
      }
      sec
      iconColor={
        text
          ? text.trim().length > 0
            ? palette.light_green
            : palette.light_grey
          : assets.length > 0
          ? palette.light_green
          : palette.light_grey
      }
    />
  );
};

const MediaIcon = ({ onMediaPress }) => {
  return (
    <IconButton
      onPress={onMediaPress}
      faPro
      faProIcon={faImages}
      iconSize={30}
      height={40}
      ml={0}
      mr={0}
      iconColor={palette.light_grey}
      secondaryColor={palette.grey}
    />
  );
};

const RenderSend = (props) => {
  const { onSend, onMediaPress, text, hasOptedOut } = props;
  const { activeTab, conversation } = useContext(ConversationContext);
  const onSendHandler = () => {
    onSend({ text: text.trim() }, true);
  };
  const { isCampaignVerified } = useContext(UserContext);
  const { blockedContacts } = useContext(CustomersContext);

  const isBlocked =
    conversation?.participant?.is_blocked ||
    blockedContacts?.includes(conversation?.participant?.id);
  return (
    <View
      style={{
        // height: 120,
        top: 0,
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <SendIcon
        onSendHandler={onSendHandler}
        text={text}
        isMessagingEnabled={
          activeTab === 1 || isCampaignVerified || isBlocked || hasOptedOut
        }
      />

      {activeTab !== 1 && isCampaignVerified && !isBlocked && !hasOptedOut && (
        <MediaIcon onMediaPress={onMediaPress} />
      )}

      {/* <IconButton
        onPress={onSendHandler}
        iconName="paperclip"
        iconSize={20}
        ml={0}
        iconColor={palette.black}
      /> */}
    </View>
  );
};

const RenderInputToolbar = (props) => {
  const {
    activeTab,
    setActiveTab,
    conversation,
    phoneLine,
    setPhoneLineAndGetConversation,
    relatedConversations,
    newConversation,
  } = useContext(ConversationContext);
  const { managedNumbers } = useContext(NumbersContext);
  const { isCampaignVerified, isAdmin, canSeeCustomerContact } =
    useContext(UserContext);
  const { blockedContacts } = useContext(CustomersContext);
  const history = useHistory();
  const { participant, twilio_number } = conversation || {};
  const isBlocked =
    participant?.is_blocked || blockedContacts?.includes(participant?.id);
  const hasOptedOut = !!conversation?.sms_opt_out;

  const isCustomerSaved = participant?.remote_id !== null;
  const title =
    isCustomerSaved || canSeeCustomerContact
      ? participant?.name || participant?.phone_number
      : "Unknown Customer";

  const phoneLines = useMemo(() => {
    return managedNumbers.map((number) => {
      const relatedConversation = relatedConversations.current.find(
        (conversation) => conversation.twilio_number_id === number.id,
      );
      const isConversationRead = relatedConversation?.read;
      const isCurrent = conversation?.twilio_number?.id === number.id;
      return {
        label: `${number.friendly_name}`,
        subLabel: number.national_number || number.phone_number,
        value: number.phone_number,
        unread: relatedConversation && !isConversationRead && !isCurrent,
        show_unread: true,
      };
    });
  }, [
    managedNumbers,
    relatedConversations?.current,
    conversation?.twilio_number?.id,
    conversation?.id,
  ]);

  const hasUnreadConversations = useMemo(() => {
    return (
      relatedConversations.current.filter(
        (c) => c.id !== conversation?.id && !c.read,
      ).length > 0
    );
  }, [
    managedNumbers,
    relatedConversations?.current,
    conversation?.twilio_number?.id,
    conversation?.id,
  ]);

  const canSend =
    activeTab === 1 ||
    (isCampaignVerified &&
      participant?.valid &&
      twilio_number?.id &&
      !isBlocked &&
      !hasOptedOut);

  const onUnBlockPress = () => {
    history.push(
      { hash: "confirm-delete" },
      {
        message: `This will unblock ${title}${
          canSeeCustomerContact ? " " + participant?.phone_number : ""
        }. Do you want to continue?`,
        type: "unblock-contact",
        id: participant?.id,
        onSuccessMessage: Strings.UNBLOCK_NUMBER_SUCCESS,
        onErrorMessage: Strings.UNBLOCK_NUMBER_ERROR,
        title: Strings.UNBLOCK_NUMBER_CONFIRM_HEADING,
      },
    );
  };

  return (
    <InputToolBar
      {...props}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      isMessagingEnabled={canSend}
      isAdmin={isAdmin}
      isCampaignVerified={isCampaignVerified}
      isBlocked={isBlocked}
      hasOptedOut={hasOptedOut}
      onUnBlockPress={onUnBlockPress}
      isPhoneLineVisible={
        isCampaignVerified &&
        !isBlocked &&
        activeTab === 0 &&
        managedNumbers.length > 1
      }
      customerName={title}
      textInputAutoFocus={!!conversation?.id && !newConversation}
      hasUnreadConversations={hasUnreadConversations}
      phoneLines={phoneLines}
      selectedPhoneLine={phoneLine}
      onPhoneLineChange={setPhoneLineAndGetConversation}
      containerStyle={{
        borderTopWidth: 1,
        borderColor: palette.light_grey,
        // maxHeight: 200,
      }}
    />
  );
};

const VoicemailBubble = ({ voicemail }) => {
  const { setSelectedAudio, selectedAudio } = useContext(ConversationContext);
  return (
    <View
      style={{
        width: "100%",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "row",
        marginTop: 8,
        zIndex: 100,
      }}
    >
      <TouchableOpacity onPress={() => setSelectedAudio(voicemail)}>
        <Text>
          <FontAwesomeIcon
            color={
              selectedAudio == voicemail ? palette?.green : palette.track_grey
            }
            size={14}
            style={{ marginHorizontal: 8 }}
            icon={faVolumeUp}
          />
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const NotesBubble = ({ note, onPress }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <View
      style={{
        maxWidth: "60%",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginTop: 8,
      }}
    >
      <Text
        style={{
          backgroundColor: "transparent",
          color: "#b2b2b2",
          fontSize: 14,
          fontWeight: "500",
        }}
        onPress={() => {
          if (!expanded) setExpanded((state) => !state);
        }}
        numberOfLines={expanded ? 0 : 2}
      >
        {note}{" "}
        <Text
          style={{
            color: palette.primary_light,
          }}
          onPress={() => {
            onPress();
          }}
        >
          Edit{" "}
          <FontAwesomeIcon
            icon={faPenToSquare}
            size={12}
            color={palette.primary_light}
          />
        </Text>
      </Text>
    </View>
  );
};

const RenderSystemMessage = ({ setActiveNote, props }) => {
  const [hovered, setHovered] = useState(false);
  const ref = useRef(null);
  const { updatedNotes } = useContext(ConversationContext);
  const { selectedAudio, setSelectedAudio } = useContext(ConversationContext);
  switch (props.currentMessage.interactable_type) {
    case "TwilioCall": {
      let note = false;
      const isMissedCall = props?.currentMessage?.missed;
      const direction = props?.currentMessage?.direction?.includes("outbound")
        ? "outbound"
        : "inbound";

      if (updatedNotes[props.currentMessage.id]) {
        note = updatedNotes[props.currentMessage.id];
      } else note = props.currentMessage.note;
      const timestampProps = {
        ...props,
        currentMessage: {
          ...props.currentMessage,
          text: dayjs(props.currentMessage.created_at).format(" - h:mm A"),
        },
      };

      const handleHeaderPress = () => {
        if (props.currentMessage?.meta?.voicemail) {
          setSelectedAudio(props.currentMessage?.meta?.voicemail);
        }
        if (props.currentMessage?.meta?.call_recording) {
          setSelectedAudio(props.currentMessage?.meta?.call_recording);
        }
      };

      const renderAudioIcon = useMemo(() => {
        switch (true) {
          case props.currentMessage?.meta?.voicemail != null: {
            return (
              <FontAwesomeIcon
                color={
                  selectedAudio == props.currentMessage?.meta?.voicemail
                    ? palette?.green
                    : palette.track_grey
                }
                size={14}
                style={styles.render_system_message__audio_icon}
                icon={faVolumeUp}
              />
            );
          }
          case props.currentMessage?.meta?.call_recording != null: {
            return (
              <FontAwesomeIcon
                color={
                  selectedAudio == props.currentMessage?.meta?.call_recording
                    ? palette?.green
                    : palette.track_grey
                }
                size={14}
                style={styles.render_system_message__audio_icon}
                icon={faVolumeUp}
              />
            );
          }
          default: {
            return null;
          }
        }
      }, [selectedAudio]);

      return (
        <SystemMessage {...props}>
          <Pressable
            style={{ width: "100%" }}
            onHoverIn={() => {
              setHovered(true);
            }}
            onHoverOut={() => {
              setHovered(false);
            }}
            onPress={handleHeaderPress}
          >
            <View
              style={{
                width: "100%",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                flexDirection: "row",
                marginTop: 8,
              }}
            >
              <View
                style={{
                  maxHeight: 32,
                  justifyContent: "flex-end",
                  alignContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              ></View>

              <View
                style={{
                  minWidth: "60%",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                  ref={ref}
                >
                  <FontAwesomeIcon
                    color={isMissedCall ? palette.red : palette.track_grey}
                    size={14}
                    style={{ marginRight: 8 }}
                    icon={
                      isMissedCall
                        ? faPhoneMissed
                        : props?.currentMessage?.direction?.indexOf(
                            "outbound",
                          ) > -1
                        ? faPhoneArrowUpRight
                        : faPhoneArrowDownLeft
                    }
                  />
                  <SystemText
                    {...{
                      ...props,
                      currentMessage: {
                        ...props?.currentMessage,
                        text: isMissedCall
                          ? "Missed Call"
                          : direction === "inbound"
                          ? "Inbound Call"
                          : "Outbound Call",
                      },
                      textStyle: {
                        ...props?.textStyle,
                        color: isMissedCall ? palette.red : palette.track_grey,
                      },
                    }}
                  />

                  {renderAudioIcon}

                  <SystemText
                    {...timestampProps}
                    textStyle={{
                      ...timestampProps?.textStyle,
                      color: isMissedCall ? palette.red : palette.track_grey,
                    }}
                  />
                </View>
                {!props.currentMessage?.note && !note && hovered && (
                  <View
                    style={{
                      position: "absolute",
                      left: "25%",
                      transform: `translateX(${
                        ref?.current?.clientWidth / 2
                      }px)`,
                    }}
                  >
                    <Text
                      style={{
                        marginLeft: 8,
                        color: palette.primary_light,
                      }}
                      onPress={() => setActiveNote(props.currentMessage)}
                    >
                      Add Note
                    </Text>
                  </View>
                )}
              </View>

              <View
                style={{
                  maxHeight: 32,
                  justifyContent: "flex-end",
                  alignContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              ></View>
            </View>
          </Pressable>

          {note && note.length > 0 && (
            <NotesBubble
              onPress={() =>
                setActiveNote({
                  ...props.currentMessage,
                  note,
                })
              }
              note={note}
            />
          )}
        </SystemMessage>
      );
    }

    default: {
      return (
        <SystemMessage {...props}>
          <SystemText {...props} />
        </SystemMessage>
      );
    }
  }
};

const renderBubble = (props) => {
  switch (props.currentMessage.interactable_type) {
    case "TwilioCall": {
      return <CallBubble {...props} />;
    }

    default: {
      return <Bubble {...props} />;
    }
  }
};

const styles = StyleSheet.create({
  conversation_thread__text_input: {
    borderTopWidth: 0,
    backgroundColor: palette.white,
    borderRadius: 0,
  },
  conversation_bar__container: {
    minHeight: 100,
    borderTopWidth: 0,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: palette.light_grey,
    // backgroundColor: palette.lightest_grey
  },
  conversation_bar__back_btn_icon: {},
  conversation_bar__action_btn_icon: {
    marginRight: 10,
  },
  conversation_bar__back_btn_text_container: {
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    height: "100%",
    flexDirection: "row",
    position: "absolute",
    right: 24,
  },
  conversation_bar__back_btn_text: {
    fontSize: 18,
    color: palette.success,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
  },
  conversation_bar__title_text: {
    fontSize: 16,
    color: palette.black,
    fontWeight: "700",
    fontFamily: "OpenSans_700Bold",
    textAlign: "center",
  },
  conversation_bar__title_subtext: {
    color: palette.black,
    textAlign: "center",
  },
  new_conversation_bar__container: {
    height: 75,
    borderTopWidth: 0,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: palette.light_grey,
    justifyContent: "center",
  },
  new_conversation_bar__input_label: {
    fontSize: 16,
    fontWeight: "500",
    fontFamily: "OpenSans_600SemiBold",
    color: palette.grey,
  },
  new_conversation_bar__input: {
    border: "none",
  },
  conversation_bar__audio_player_container: {
    flexDirection: "row",
    alignItems: "center",
    columnGap: 8,
    flex: 1,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: [{ translateX: "-50%" }, { translateY: "-50%" }],
  },
  conversation_bar__audio_player_inner_container: {
    paddingRight: 20,
    backgroundColor: palette.lightest_grey,
    borderRadius: 30,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  render_system_message__audio_icon: {
    marginHorizontal: 8,
  },
});
