import { useContext, useMemo, useState } from "react";
import { Text, View } from "react-native-web";
import IconButton from "../../components/Buttons/IconButton";
import SolidButton from "../../components/Buttons/SolidButton";
import Col from "../../layouts/Col";
import Row from "../../layouts/Row";
import palette from "../../styles/palette";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { StyleSheet } from "react-native";
import SipDomainsContext from "../../contexts/SipDomainsContext";
import Heading from "../../components/Heading/index.web";
import { Strings } from "../../constants/strings";

const DeleteCredentialModal = ({ location, history }) => {
  const { credential } = location.state || {};

  const {
    savingCredential,
    deleteCredential,
    primaryDomain,
    primaryCredentialList,
  } = useContext(SipDomainsContext);
  const [error, setError] = useState();

  const buttons = useMemo(
    () => [
      {
        label: "Yes",
        color: palette.danger,
        style: {
          marginRight: 8,
        },
        loading: savingCredential,
        onPress: async () => {
          setError(null);
          const res = await deleteCredential(credential);
          if (!res || res?.error) {
            setError("Error deleting credential");
          } else {
            history.goBack();
          }
        },
      },
      {
        label: "Cancel",
        marginRight: 8,
        onPress: () => {
          history.goBack();
        },
        disabled: savingCredential,
      },
    ],
    [savingCredential, primaryDomain, primaryCredentialList, deleteCredential],
  );

  if (!credential) return null;

  const title = Strings.SIP_DOMAIN_DELETION_HEADING;

  return (
    <View style={[styles.modal_overlay]}>
      <View style={styles.modal_container}>
        <ModalHeader
          onClose={() => {
            history.goBack();
          }}
          title={title}
        />
        <View style={styles.modal__body}>
          <Text style={styles.body__message}>
            {Strings.SIP_DOMAIN_DELETION_PART1}{" "}
            <Heading size={5} mb={0}>
              {credential?.phoneLine?.friendlyName}
            </Heading>{" "}
            {Strings.SIP_DOMAIN_DELETION_PART2}
          </Text>
          <View>
            <Heading size={5}>{credential?.phoneLine?.friendlyName}</Heading>
            <Text style={styles.body__number_text}>
              {credential?.phoneLine?.number}
            </Text>
          </View>
          {!!error && <Text style={styles.body_error_message}>{error}</Text>}
          <Row>
            {buttons.map((button, index) => (
              <SolidButton key={index} {...button} />
            ))}
          </Row>
        </View>
      </View>
    </View>
  );
};

const ModalHeader = ({ onClose, title }) => {
  return (
    <View style={[styles.modal__header]}>
      <Row pl={32} pr={8}>
        <Col leftCenter>
          <Text style={[styles.modal__header_title_text]}>{title}</Text>
        </Col>
        <Col rightCenter>
          <IconButton
            onPress={onClose}
            faPro
            faProIcon={faXmark}
            iconSize={28}
          />
        </Col>
      </Row>
    </View>
  );
};

const styles = StyleSheet.create({
  modal__header: {
    height: 60,
    borderBottomColor: palette.light_grey,
    borderBottomWidth: 1,
    justifyContent: "center",
  },
  modal__body: {
    paddingHorizontal: 16,
    flex: 1,
  },
  modal__footer: {
    paddingHorizontal: 16,
    marginBottom: 8,
  },
  modal__header_title_text: {
    fontSize: 16,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
  },
  modal_overlay: {
    backgroundColor: "rgba(45, 62, 80, 0.79)",
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
    transitionProperty: "none",
    transitionDelay: "0ms",
    transitionDuration: "100ms",
  },
  modal_container: {
    paddingBottom: 8,
    position: "absolute",
    minWidth: "300px",
    maxWidth: "600px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: palette.white,
    zIndex: 1040,
    shadowColor: "#000",
    shadowOffset: {
      width: 5,
      height: 0,
    },
    shadowOpacity: 0.12,
    shadowRadius: 9,
    elevation: 4,
    borderRadius: 3,
  },
  body__message: {
    color: palette.black,
    fontSize: 16,
    fontFamily: "acumin-pro, san-serif",
    marginVertical: 10,
  },
  body_error_message: {
    color: palette.danger,
    fontSize: 16,
    fontFamily: "acumin-pro, san-serif",
    marginVertical: 10,
  },
  body__number_text: {
    color: palette.grey,
    fontSize: 16,
    fontFamily: "acumin-pro, san-serif",
    marginBottom: 8,
  },
});

export default DeleteCredentialModal;
