import palette from "../styles/palette";
import { Strings } from "./strings";

export const MOS_AVERAGE = "mosAverage";
export const MOS_MAX = "mosMax";
export const RTT_AVERAGE = "rttAverage";
export const JITTER_AVERAGE = "jitterAverage";
export const JITTER_MAX = "jitterMax";
export const BYTES_RECEIVED = "bytesReceived";
export const BYTES_SENT = "bytesSent";
export const PACKETS_RECEIVED = "packetsReceived";
export const PACKETS_SENT = "packetsSent";
export const PACKETS_LOST_FRACTION = "packetsLostFraction";
export const SIGNALING_DURATION = "signalingDuration";
export const ICE_DURATION = "iceDuration";
export const DTLS_DURATION = "dtlsDuration";
export const CALL_QUALITY = "callQuality";
export const IS_TURN_REQUIRED = "isTurnRequired";
export const PROTOCOL = "protocol";
export const NETWORK_TYPE = "networkType";
export const CALL_SID = "callSid";
export const EDGE = "edge";

export const NETWORK_TEST_FIELDS = {
  MOS_AVERAGE,
  MOS_MAX,
  RTT_AVERAGE,
  JITTER_AVERAGE,
  JITTER_MAX,
  BYTES_RECEIVED,
  BYTES_SENT,
  PACKETS_RECEIVED,
  PACKETS_SENT,
  PACKETS_LOST_FRACTION,
  SIGNALING_DURATION,
  ICE_DURATION,
  DTLS_DURATION,
  CALL_QUALITY,
  IS_TURN_REQUIRED,
  PROTOCOL,
  NETWORK_TYPE,
  CALL_SID,
  EDGE,
};

export const NETWORK_TEST_FIELD_STATUS = {
  EXCELLENT: {
    label: Strings.GENERAL_EXCELLENT_LABEL,
    color: palette.success_700,
    bgColor: palette.success_50,
  },
  GOOD: {
    label: Strings.GENERAL_GOOD_LABEL,
    color: palette.teal_dark,
    bgColor: palette.teal_light,
  },
  FAIR: {
    label: Strings.GENERAL_FAIR_LABEL,
    color: palette.darker_blue,
    bgColor: palette.blue_bg_light,
  },
  POOR: {
    label: Strings.GENERAL_POOR_LABEL,
    color: palette.brown_dark,
    bgColor: palette.lighter_yellow,
  },
  BAD: {
    label: Strings.GENERAL_BAD_LABEL,
    color: palette.darker_red,
    bgColor: palette.light_red,
  },
};

export const NETWORK_TEST_PROGRESS = {
  start: 0,
  completed: 1,
  connected: 0.2,
  token: 0.05,
  sample: 0.05,
  maxSample: 0.9,
  error: 1,
};

export const NETWORK_TEST_UNIT_TYPES = {
  NUMBER: "number",
  DURATION: "duration",
  BOOLEAN: "boolean",
  TITLE_CASE_STRING: "titleCaseString",
  UPPER_CASE_STRING: "upperCaseString",
  PERCENTAGE: "percentage",
};

export const NETWORK_TEST_COL_1_FIELDS = [
  MOS_AVERAGE,
  JITTER_AVERAGE,
  RTT_AVERAGE,
  IS_TURN_REQUIRED,
  EDGE,
  NETWORK_TYPE,
];

export const NETWORK_TEST_COL_2_FIELDS = [
  MOS_MAX,
  JITTER_MAX,
  PACKETS_LOST_FRACTION,
  SIGNALING_DURATION,
  ICE_DURATION,
  PROTOCOL,
];

export const NETWORK_TEST_FIELDS_WITH_STATUS = [
  MOS_AVERAGE,
  MOS_MAX,
  RTT_AVERAGE,
  JITTER_AVERAGE,
  JITTER_MAX,
  PACKETS_LOST_FRACTION,
  SIGNALING_DURATION,
  ICE_DURATION,
];

export const NETWORK_TEST_THRESHOLD_MAP = {
  [MOS_AVERAGE]: [4, 3, 2, 1],
  [MOS_MAX]: [4, 3, 2, 1],
  [JITTER_MAX]: [10, 30, 50],
  [JITTER_AVERAGE]: [10, 30, 50],
  [RTT_AVERAGE]: [50, 150, 250, 400],
  [PACKETS_LOST_FRACTION]: [0.001, 0.05, 0.1],
};

export const NETWORK_TEST_INFO = {
  [MOS_AVERAGE]: {
    label: "MOS Average",
    type: NETWORK_TEST_UNIT_TYPES.NUMBER,
    helpText:
      "The MOS (Mean Opinion Score) is a measure of the quality of the call. It ranges from 1 (worst) to 5 (best).",
  },
  [MOS_MAX]: {
    label: "MOS Max",
    type: NETWORK_TEST_UNIT_TYPES.NUMBER,
    helpText:
      "The maximum MOS (Mean Opinion Score) for the call. It ranges from 1 (worst) to 5 (best).",
  },
  [RTT_AVERAGE]: {
    label: "RTT Average",
    type: NETWORK_TEST_UNIT_TYPES.DURATION,
    helpText: "The average round-trip time (RTT) for the call.",
  },
  [JITTER_AVERAGE]: {
    label: "Jitter Average",
    type: NETWORK_TEST_UNIT_TYPES.DURATION,
    helpText:
      "The average jitter (variance in the arrival times of packets) for the call.",
  },
  [JITTER_MAX]: {
    label: "Jitter Max",
    type: NETWORK_TEST_UNIT_TYPES.DURATION,
    helpText:
      "The maximum jitter (variance in the arrival times of packets) for the call.",
  },
  [BYTES_RECEIVED]: {
    label: "Bytes Received",
    type: NETWORK_TEST_UNIT_TYPES.NUMBER,
    helpText: "The number of bytes received during the call.",
  },
  [BYTES_SENT]: {
    label: "Bytes Sent",
    type: NETWORK_TEST_UNIT_TYPES.NUMBER,
    helpText: "The number of bytes sent during the call.",
  },
  [PACKETS_RECEIVED]: {
    label: "Packets Received",
    type: NETWORK_TEST_UNIT_TYPES.NUMBER,
    helpText: "The number of packets received during the call.",
  },
  [PACKETS_SENT]: {
    label: "Packets Sent",
    type: NETWORK_TEST_UNIT_TYPES.NUMBER,
    helpText: "The number of packets sent during the call.",
  },
  [PACKETS_LOST_FRACTION]: {
    label: "Packets Lost Fraction",
    type: NETWORK_TEST_UNIT_TYPES.PERCENTAGE,
    helpText:
      "The percentage of packets that were lost during the call. This is a measure of the call quality.",
  },
  [SIGNALING_DURATION]: {
    label: "Signaling Duration",
    type: NETWORK_TEST_UNIT_TYPES.DURATION,
    helpText: "The time it took to establish the call.",
  },
  [ICE_DURATION]: {
    label: "ICE Duration",
    type: NETWORK_TEST_UNIT_TYPES.DURATION,
    helpText:
      "The time it took for the Interactive Connectivity Establishment (ICE) protocol to complete.",
  },
  [DTLS_DURATION]: {
    label: "DTLS Duration",
    type: NETWORK_TEST_UNIT_TYPES.DURATION,
    helpText:
      "The time it took for the DTLS (Datagram Transport Layer Security) handshake to complete.",
  },
  [CALL_QUALITY]: {
    label: "Call Quality",
    type: NETWORK_TEST_UNIT_TYPES.TITLE_CASE_STRING,
    helpText: "The quality of the call.",
  },
  [IS_TURN_REQUIRED]: {
    label: "Turn Used?",
    type: NETWORK_TEST_UNIT_TYPES.BOOLEAN,
    helpText:
      "Indicates whether the call required the use of a TURN server to establish the call.",
  },
  [PROTOCOL]: {
    label: "Protocol",
    type: NETWORK_TEST_UNIT_TYPES.UPPER_CASE_STRING,
    helpText: "The protocol used for the call.",
  },
  [NETWORK_TYPE]: {
    label: "Network Type",
    type: NETWORK_TEST_UNIT_TYPES.UPPER_CASE_STRING,
    helpText: "The type of network used for the call.",
  },
  [CALL_SID]: {
    label: "Call SID",
    type: NETWORK_TEST_UNIT_TYPES.TITLE_CASE_STRING,
    helpText: "The unique identifier for the call.",
  },
  [EDGE]: {
    label: "Edge",
    type: NETWORK_TEST_UNIT_TYPES.TITLE_CASE_STRING,
    helpText:
      "The edge used for the call. This is the location where the call was processed.",
  },
};

export const DEFAULT_NETWORK_TEST_REPORT = {
  edge: "",
  callSid: "",
  iceCandidateStats: [],
  networkTiming: {
    signaling: {
      start: 0,
      end: 0,
      duration: 0,
    },
    dtls: {
      start: 0,
      end: 0,
      duration: 0,
    },
    ice: {
      start: 0,
      end: 0,
      duration: 0,
    },
    peerConnection: {
      start: 0,
      end: 0,
      duration: 0,
    },
  },
  samples: [],
  selectedEdge: "",
  stats: {
    jitter: {
      average: 0,
      max: 0,
      min: 0,
    },
    mos: {
      average: 0,
      max: 0,
      min: 0,
    },
    rtt: {
      average: 0,
      max: 0,
      min: 0,
    },
  },
  testTiming: {
    start: 0,
    end: 0,
    duration: 0,
  },
  totals: {
    bytesReceived: 0,
    bytesSent: 0,
    packetsLost: 0,
    packetsLostFraction: 0,
    packetsReceived: 0,
    packetsSent: 0,
  },
  warnings: [],
  selectedIceCandidatePairStats: {
    localCandidate: {},
    remoteCandidate: {},
  },
  isTurnRequired: false,
  callQuality: "",
};
